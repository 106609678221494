import { ActivateState } from './activateTypes';
import {  ActivateAction, ActionTypes } from './activateActions';

const initialState: ActivateState = {
  info: null,
  loading: false,
  error: null,
  redirectPath: null,
  searcherror: null,
};

const activateReducer = (state = initialState, action: ActivateAction):ActivateState => {
  switch (action.type) {

    // search
    case ActionTypes.ACTIVATE_SEARCH_REQUEST:
      return { ...state, loading: true, error: null };
    case ActionTypes.ACTIVATE_SEARCH_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case ActionTypes.ACTIVATE_SEARCH_FAILURE:
      return { ...state, loading: false, searcherror: action.payload };

      // update
    case ActionTypes.ACTIVATE_UPDATE_REQUEST:
      return { ...state, loading: true, error: null };
    case ActionTypes.ACTIVATE_UPDATE_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case ActionTypes.ACTIVATE_UPDATE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionTypes.ACTIVATE_UPDATE_REDIRECT:
      return {...state, redirectPath: action.payload };
    default:
      return state;
  }
};

export default activateReducer;