import { Admin_AuthState } from './loginTypes';
import {  Admin_AuthAction, Admin_AuthActionTypes } from './loginActions';

const initialState: Admin_AuthState = {
  user: null,
  loading: false,
  error: null,
  redirectPath: null,
};

const authReducer = (state = initialState, action: Admin_AuthAction):Admin_AuthState => {
  switch (action.type) {
    case Admin_AuthActionTypes.ADMIN_LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case Admin_AuthActionTypes.ADMIN_LOGIN_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case Admin_AuthActionTypes.ADMIN_LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case Admin_AuthActionTypes.ADMIN_LOGIN_REDIRECT:
      return {...state, redirectPath: action.payload };
// Forgot ***********
    case Admin_AuthActionTypes.ADMIN_FORGOT_REQUEST:
      return { ...state, loading: true, error: null };
    case Admin_AuthActionTypes.ADMIN_FORGOT_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case Admin_AuthActionTypes.ADMIN_FORGOT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case Admin_AuthActionTypes.ADMIN_FORGOT_REDIRECT:
      return {...state, redirectPath: action.payload };
    default:
      return state;
  }
};

export default authReducer;