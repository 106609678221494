import React, { useEffect, useCallback, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    ListGroup, 
    Row, 
    Col,
    Button
} from 'react-bootstrap';
import { AiOutlineDashboard, AiOutlineUser, AiOutlineDollar, AiOutlineFilePpt, AiOutlineSetting, AiOutlineGroup } from "react-icons/ai";
import { IoWaterOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
// import { MdStorefront } from "react-icons/md";
import { CgMenuGridR } from "react-icons/cg";
import { LuCalendarClock } from "react-icons/lu";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { setLocalStorageVariable, getLocalStorageVariable } from '../../components/localStorage';
import { setSessionVariable, getSessionVariable } from '../../components/sessionStorage';
import apiUrl from '../../components/apiurl';
import Offcanvas from 'react-bootstrap/Offcanvas';

interface AuthData {
    id: number;
    category: string;
    auth_create: number;
    auth_read: number;
    auth_update: number;
    auth_delete: number;
  }

const SubMenu: React.FC = () =>{
    const userId = getLocalStorageVariable<string>('userId');
    const userAuth = getLocalStorageVariable<string>('userAuth');
    const token = getLocalStorageVariable<string>('token');
    const navigate = useNavigate();
    const selectedItem = getSessionVariable<string>('setSelectedItem');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchAuthData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };

        try {
            const res: AxiosResponse<AuthData[]> = await axios.post(`${apiUrl.url}auth_access/`, { auth: userAuth }, { headers });
            for(let i = 0 ; i < res.data.length ; i++){
                setLocalStorageVariable(res.data[i].category + '_create', res.data[i].auth_create);
                setLocalStorageVariable(res.data[i].category + '_read', res.data[i].auth_read);
                setLocalStorageVariable(res.data[i].category + '_update', res.data[i].auth_update);
                setLocalStorageVariable(res.data[i].category + '_delete', res.data[i].auth_delete);
            }
        } catch (error) {
            console.error('Error: ', error);
            return false;
        }
    }, [userAuth]);

    useEffect(() => {
        fetchAuthData();
        if (!userId || userAuth=='client' || !token) {
            navigate('/logout'); 
        }
    }, [ navigate, userId, userAuth, token ]);

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };

    return (
        <div>
            <Row className="justify-content-md-center mt-3 ">
                <Col>
                    <Button variant="outline-dark" className="d-lg-none" onClick={handleShow} style={{border: "none"}}>
                        <CgMenuGridR size="40" />
                    </Button>
                    
                    <Offcanvas show={show} onHide={handleClose} responsive="lg">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Menu</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>

                            <ListGroup  variant="flush" className="rounded w-100" >
                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/admin_dashboard' ? '#a1a1a1' : 'transparent', color: selectedItem === '/admin_dashboard' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/admin_dashboard')}>
                                    <AiOutlineDashboard size="20"/> &nbsp;Dashboard
                                </ListGroup.Item>

                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/admin_user_menu' ? '#a1a1a1' : 'transparent', color: selectedItem === '/admin_user_menu' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/admin_user_menu')}>
                                    <AiOutlineUser size="20"/> &nbsp;Billing Account
                                </ListGroup.Item>

                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/admin_association_menu' ? '#a1a1a1' : 'transparent', color: selectedItem === '/admin_association_menu' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/admin_association_menu')}>
                                    <AiOutlineGroup size="20"/> &nbsp;Association
                                </ListGroup.Item>

                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/admin_water_menu' ? '#a1a1a1' : 'transparent', color: selectedItem === '/admin_water_menu' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/admin_water_menu')}>
                                    <IoWaterOutline size="20"/> &nbsp;Water
                                </ListGroup.Item>

                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/admin_payment_menu' ? '#a1a1a1' : 'transparent', color: selectedItem === '/admin_payment_menu' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/admin_payment_menu')}>
                                    <AiOutlineDollar size="20"/> &nbsp;Payment
                                </ListGroup.Item>

                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/admin_report_menu' ? '#a1a1a1' : 'transparent', color: selectedItem === '/admin_report_menu' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/admin_report_menu')}>
                                    <AiOutlineFilePpt size="20"/> &nbsp;Reports
                                </ListGroup.Item>
                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/admin_transactions' ? '#a1a1a1' : 'transparent', color: selectedItem === '/admin_transactions' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/admin_transactions')}>
                                    <LuCalendarClock size="20"/> &nbsp;Transactions
                                </ListGroup.Item>
                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/admin_calendar' ? '#a1a1a1' : 'transparent', color: selectedItem === '/admin_calendar' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/admin_calendar')}>
                                    <IoCalendarNumberOutline size="20"/> &nbsp;Calendar
                                </ListGroup.Item>
                                <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/admin_settings_menu' ? '#a1a1a1' : 'transparent', color: selectedItem === '/admin_settings_menu' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/admin_settings_menu')}>
                                    <AiOutlineSetting size="20"/> &nbsp;Manage
                                </ListGroup.Item>
                                {/* <ListGroup.Item action variant="light"
                                    style={{ backgroundColor: selectedItem === '/admin_settings_menu' ? '#a1a1a1' : 'transparent', color: selectedItem === '/admin_settings_menu' ? '#fff' : '#000' }}
                                    onClick={() => handleItemClick('/admin_settings_menu')}>
                                    <MdStorefront size="20"/> &nbsp;Marketplace
                                </ListGroup.Item> */}
                            </ListGroup>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Col>
            </Row>
        </div>
    );
};

export default SubMenu;