import React, {useState} from 'react';

import { 
    Row,
    Col,
    Alert,
    Card,
    Image,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SubMenu from '../submenu';
import { AiOutlineDashboard, AiOutlineDollar, AiOutlineGroup } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { BsCashCoin } from "react-icons/bs";
import { LuWallet } from "react-icons/lu";
import { IoWaterOutline } from "react-icons/io5";
import { MdOutlineBalance } from "react-icons/md";

// import { useNavigate } from 'react-router-dom';
// import { getLocalStorageVariable } from '../../../components/localStorage';

const Wallet: React.FC = () =>{
    // const navigate = useNavigate();

    // useEffect(() => {
    //     const userId = getLocalStorageVariable<string>('userId');
    //     const userAuth = getLocalStorageVariable<string>('userAuth');

    //     if (!userId && userAuth!='client') {
    //         navigate('/admin_login'); 
    //     }
    // }, [navigate]);
    

    return (
        <div className="container-fluid "> 
            <Row className="justify-content-md-center">
                <Col sm={3}>
                    <SubMenu/>
                </Col>
                <Col xs lg="9"  className="p-3 bg-white rounded shadow-sm">
                    <Row className="justify-content-md-center">
                        <Col >
                        <div style = {{ width:"400px"}} />
                        <h4 className="text-warning" ><LuWallet size="30"/> Wallet</h4>
                        <hr/>
                            <Col>
                                <Link to="/bills" style={{ textDecoration: 'none'}}>
                                <Card className="m-1 rounded shadow-lg">
                                    <Card.Body className='text-right'>
                                    <div style={{ position: 'absolute', height: '10px', left: 30, top: 20 }}>
                                        {/* <FeatherIcon icon="book" size="40" className="text-danger" stroke-width="1" /> */}
                                        <LuWallet size="50" className="text-warning"/>
                                    </div>
                                    {/* <Card.Title><h3>{totbal.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h3></Card.Title> */}
                                    <Card.Title className='text-end'><h3>0.00</h3></Card.Title>
                                        <Card.Text className='pt-3 text-end'>
                                        Amount
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="bg-warning">
                                    <big className="text-light">Wallet Balance</big>
                                    </Card.Footer>
                                </Card>
                                </Link>
                            </Col>
                        </Col>
                    </Row>
                    <Row xs={1} md={4} className="g-4 mt-1">
                      <Col>
                        <Link to="/association" style={{ textDecoration: 'none'}}>
                          <Card className="m-1 rounded shadow-lg">
                            <Card.Body>
                                <Card.Text className='pt-3 text-center'>
                                    <BsCashCoin size="50" className="text-success"/>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="bg-secondary">
                              <big className="text-light">Cash In</big>
                            </Card.Footer>
                          </Card>
                        </Link>
                      </Col>
                      <Col>
                        <Link to="/water" style={{ textDecoration: 'none'}}>
                          <Card className="m-1 rounded shadow-lg">
                            <Card.Body className='text-right'>
                                <Card.Text className='pt-3 text-center'>
                                    <IoSettingsOutline size="50" className="text-primary"/>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="bg-secondary">
                              <big className="text-light">Settings</big>
                            </Card.Footer>
                          </Card>
                        </Link>
                      </Col>
                      
                    </Row>
                    
                </Col>
            </Row>
        </div>
    );
};

export default Wallet;