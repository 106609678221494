// import React, { useState, useEffect, useCallback } from 'react';
// import axios, { AxiosResponse } from 'axios';
// import apiUrl from '../components/apiurl';
// import { getLocalStorageVariable } from '../components/localStorage';

// interface UserData {
//   id: number;
//   auth: number;
//   fname: string;
// }

// const UserMenu: React.FC = () => {
//     const token = getLocalStorageVariable<string>('token');
//     const [data, setData] = useState<UserData[] | null>(null); // Update type to array of UserData

//     const fetchData = useCallback(async () => {
//         const headers = {
//             Authorization: `${token}`,
//           };

//           console.log('Token:', token); // Log the token for debugging

//         try {
//             const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}admin_get_admin_user`, { headers });
//             setData(res.data.length > 0 ? res.data : null);
//         } catch (error) {
//             console.error('Error: ', error);
//         }
//     }, []);

//     useEffect(() => {
//         fetchData();
//     }, [fetchData]);

//     console.log(data);

//         return (
//             <div className="container-fluid">
//             {data ? (
//                 <table>
//                 <tbody>
//                     {data.map((r, i) => (
//                     <tr key={i} className="align-middle text-secondary">
//                         <td className="text-center align-middle">{i + 1}</td>
//                         <td className="text-center align-middle">{r.auth}</td>
//                         <td className="text-center align-middle">{r.fname}</td>
//                     </tr>
//                     ))}
//                 </tbody>
//                 </table>
//             ) : (
//                 <p>Loading...</p>
//             )}
//             </div>
//         );
//     };

// export default UserMenu;


// import React from 'react';
// import me from '../images/casa.jpg';

// const SubdivisionMap: React.FC = () => {
//   const handleHouseClick = (houseId: string) => {
//     // Handle click event for the house with the given ID
//     console.log(`Clicked on house ${houseId}`);
//   };

//   return (
//     <div>
//       <img
//         src={me} // Replace with the actual path to your image
//         alt="Subdivision Map"
//         useMap="#housesMap"
//         width="30%"
//       />
//       <map name="housesMap">
//         <area
//           shape="rect"
//           coords="100,50,200,150" // Replace with the coordinates of the first house
//           alt="House 1"
//           onClick={() => handleHouseClick('1')}
//           style={{cursor: 'pointer'}}
          
//         />
//         {/* Add more <area> elements for other houses */}
//       </map>
//     </div>
//   );
// };

// export default SubdivisionMap;

// *********************************************************************************************************

// import React, { useState } from 'react';

// interface Item {
//   id: number;
//   name: string;
// }

// const items: Item[] = [
//   { id: 1, name: 'Item 1' },
//   { id: 2, name: 'Item 2' },
//   { id: 3, name: 'Item 3' },
//   // Add more items as needed
// ];

// const ITEMS_PER_PAGE = 1;

// const PaginationExample: React.FC = () => {
//   const [currentPage, setCurrentPage] = useState(1);

//   const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
//   const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
//   const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

//   const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

//   return (
//     <div>
//       <h1>Pagination Example</h1>
//       <ul>
//         {currentItems.map((item) => (
//           <li key={item.id}>{item.name}</li>
//         ))}
//       </ul>
//       <div>
//         {items.length > ITEMS_PER_PAGE && (
//           <ul className="pagination">
//             {Array.from({ length: Math.ceil(items.length / ITEMS_PER_PAGE) }).map((_, index) => (
//               <li key={index} className={index + 1 === currentPage ? 'active' : ''}>
//                 <button onClick={() => paginate(index + 1)}>{index + 1}</button>
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//     </div>
//   );
// };

// export default PaginationExample;





// Install necessary packages:
// npm install react react-dom typescript @types/react @types/node

// import React, { useState } from 'react';
// import apiUrl from '../components/apiurl';
// import { getLocalStorageVariable } from '../components/localStorage';
// import React, { useState } from 'react';
// import axios, { AxiosResponse } from 'axios';

// const SendEmail: React.FC = () => {
//   const [to, setTo] = useState('');
//   const [subject, setSubject] = useState('');
//   const [text, setText] = useState('');
//   const token = getLocalStorageVariable<string>('token');

//   const handleSubmit = async () => {
//     const headers = {
//           Authorization: `${token}`,
//         };
//     try {
//       // const res: AxiosResponse<AuthData[]> = await axios.post(`${apiUrl.url}auth_access/`, { auth: userAuth }, { headers });
//       const response = await axios.post(`${apiUrl.url}send_email`, {
//         to,
//         subject,
//         text,
//       }, { headers });

//       if (response.status === 200) {
//         console.log('Email sent successfully');
//       } else {
//         console.error('Failed to send email');
//       }
//     } catch (error) {
//       console.error('Error sending email', error);
//       console.log('Response Data:', error); 
//     }
//   };

//   return (
//     <div>
//       <h1>Email Sender</h1>
//       <form>
//         <label>
//           To:
//           <input type="email" value={to} onChange={(e) => setTo(e.target.value)} />
//         </label>
//         <br />
//         <label>
//           Subject:
//           <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
//         </label>
//         <br />
//         <label>
//           Text:
//           <textarea value={text} onChange={(e) => setText(e.target.value)} />
//         </label>
//         <br />
//         <button type="button" onClick={handleSubmit}>
//           Send Email
//         </button>
//       </form>
//     </div>
//   );
// };

// export default SendEmail;
// declare const require: {
//   context(directory: string, useSubdirectories?: boolean, regExp?: RegExp): {
//     keys(): string[];
//     <T>(id: string): T;
//   };
// };

// const importAll = (context: any) => context.keys().map(context);

// const images = importAll(require.context('../images/avatar/', false, /\.(png|jpe?g|svg)$/));

// const SendEmail: React.FC = () => {
//   const boy = '/static/media/1.1f1d0984d6e0f46c338d.jpg';
//   return (
// <div>
//   <img src={boy} width="40" height="40" />

//       {images.map((src:any, index:any) => (
//         <div>
//         <img key={index} src={src} alt={`Avatar ${index}`} width="40" height="40" />
//         {src}
//         </div>
//       ))}
//     </div>
//    );
//  };
// export default SendEmail;
// import React, { useState, useEffect } from 'react';

// const CountdownTimer: React.FC = () => {
//   const initialTimeInSeconds = 8 * 60 * 60; // 4 hours in seconds
//   const [remainingTime, setRemainingTime] = useState(() => {
//     // Retrieve remaining time from localStorage or use the initial time
//     const storedTime = localStorage.getItem('countdownTimer');
//     return storedTime ? parseInt(storedTime, 10) : initialTimeInSeconds;
//   });

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setRemainingTime((prevTime) => {
//         if (prevTime > 0) {
//           // Save remaining time to localStorage
//           localStorage.setItem('countdownTimer', String(prevTime - 1));
//           return prevTime - 1;
//         } else {
//           clearInterval(intervalId);
//           // Handle timer expiration, e.g., display a message or trigger an action
//           return 0;
//         }
//       });
//     }, 1000); // Update every 1000 milliseconds (1 second)

//     return () => {
//       // Cleanup the interval when the component unmounts
//       clearInterval(intervalId);
//     };
//   }, []); // Run the effect once after the initial render

//   // Convert remaining time to hours, minutes, and seconds
//   const hours = Math.floor(remainingTime / 3600);
//   const minutes = Math.floor((remainingTime % 3600) / 60);
//   const seconds = remainingTime % 60;

//   return (
//     <div>
//       <h4>
//       {hours} : {minutes} : {seconds} 
//       </h4>
//     </div>
//   );
// };

// export default CountdownTimer;
// import React from 'react';
// import ReactToPrint from 'react-to-print';
// import { CSVLink } from "react-csv";

// class ComponentToPrint extends React.Component {
//   render() {
//     return (
//       <div>
//         {/* Content you want to print */}
//         <p>This is the content to be printed.</p>
//       </div>
//     );
//   }
// }

// const PrintButton: React.FC = () => {
//   const componentRef = React.useRef(null);

//   const headers = [
//     { label: "First Name", key: "firstname" },
//     { label: "Last Name", key: "lastname" },
//     { label: "Email", key: "email" }
//   ];
  
//   const data = [
//     { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
//     { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
//     { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
//   ];

//   return (
//     <div>
//       <CSVLink data={data} headers={headers} filename={"test.csv"}
//       className="btn btn-primary"
//       target="_blank">
//         Download me
//       </CSVLink>
//       <ReactToPrint
//         trigger={() => <button>Print</button>}
//         content={() => componentRef.current}
//       />
//       <div style={{ display: 'none' }}>
//         {/* <ComponentToPrint ref={componentRef} /> */}
//       </div>
//       <div ref={componentRef}>
//         {/* Content you want to print */}
//         <p>This is the content to be printed.
//           sa ubos ni
//         </p>
//       </div>
//     </div>
//   );
// };

// export default PrintButton;

// import React from 'react';
// import * as XLSX from 'xlsx';
// import saveAs from 'file-saver';

// const YourComponent: React.FC = () => {
//   const headers = ['Name', 'Age', 'City'];
//   const data = [
//     { Name: 'John Doe', Age: 25, City: 'New York' },
//     { Name: 'Jane Doe', Age: 30, City: 'San Francisco' },
//     // ... add more data as needed
//   ];

//   const exportToExcel = () => {
//     const worksheet = XLSX.utils.json_to_sheet(data, { header: headers });
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
//     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//     const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//     saveAs(dataBlob, 'example.xlsx');
//   };

//   return (
//     <div>
//       <button onClick={exportToExcel}>Export to Excel</button>
//     </div>
//   );
// };

// export default YourComponent;


// import React, { useState } from 'react';

// interface TableData {
//   id: number;
//   name: string;
//   description: string;
//   editable: boolean;
// }

// const EditableTable: React.FC = () => {
//   const initialData: TableData[] = [
//     { id: 1, name: 'Item 1', description: '0', editable: false },
//     { id: 2, name: 'Item 2', description: '0', editable: false },
//     { id: 3, name: 'Item 3', description: '0', editable: false },
//   ];

//   const [tableData, setTableData] = useState<TableData[]>(initialData);

//   const handleDoubleClick = (id: number) => {
//     const updatedData = tableData.map((item) =>
//       item.id === id ? { ...item, editable: true } : item
//     );
//     setTableData(updatedData);
//   };

//   const handleCellChange = (id: number, value: string) => {
//     const updatedData = tableData.map((item) =>
//       item.id === id ? { ...item, description: value } : item
//     );
//     setTableData(updatedData);
//   };

//   const handleKeyDown = (id: number, e: React.KeyboardEvent<HTMLInputElement>) => {
//     if (e.key === 'Enter') {
//       // Handle the "Enter" key press here
//       // You may want to save the changes or perform any other action
//       // For now, let's blur the input to exit the edit mode
//       e.currentTarget.blur();
//     }
//   };

//   const totalDescription = tableData.reduce((total, item) => total + parseFloat(item.description), 0);

//   return (
//     <div>
//     <table>
//       <thead>
//         <tr>
//           <th>ID</th>
//           <th>Name</th>
//           <th>Description</th>
//         </tr>
//       </thead>
//       <tbody>
//         {tableData.map((item) => (
//           <tr key={item.id}>
//             <td>{item.id}</td>
//             <td>{item.name}</td>
//             <td
//               onDoubleClick={() => handleDoubleClick(item.id)}
//               style={{ cursor: 'pointer' }}
//             >
//               {item.editable ? (
//                 <input
//                   type="number"
//                   value={item.description}
//                   onChange={(e) => handleCellChange(item.id, e.target.value)}
//                   onKeyDown={(e) => handleKeyDown(item.id, e)}
//                 />
//               ) : (
//                 item.description
//               )}
//             </td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//     <div>
//     <strong>Total Description:</strong> {totalDescription}
//   </div>
//   </div>
//   );
// };

// export default EditableTable;



// import React, { useState } from 'react';
// import axios from 'axios';
// import apiUrl from '../components/apiurl';
// import { getLocalStorageVariable } from '../components/localStorage';

// function App() {
//     const token = getLocalStorageVariable<string>('token');
//     const [file, setFile] = useState<File | null>(null);

//     const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         const selectedFile = event.target.files?.[0];
//         if (selectedFile) {
//             // Check if the file type is Excel
//             if (selectedFile.type === 'application/vnd.ms-excel' || selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
//               setFile(selectedFile);
//           } else {
//               alert('Please upload a valid Excel file.');
//               // Reset the file input
//               event.target.value = '';
//           }
//         }
//     };

//     const handleUpload = async () => {
//         if (file) {
//             const formData = new FormData();
//             formData.append('file', file);

//             try {              
//                 await axios.post(`${apiUrl.url}admin_user_upload/upload`, formData, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data',
//                         Authorization: `${token}`,
//                     },
//                 });
//                 console.log('File uploaded successfully');
//             } catch (error) {
//                 console.error('Error uploading file: ' + (error as any).message);
//             }
//         }
//     };

//     return (
//         <div>
//             <input type="file" onChange={handleFileChange} />
//             <button onClick={handleUpload}>Upload</button>
//         </div>
//     );
// }

// export default App;

// App.tsx
// App.tsx
// import React, { useState } from 'react';
// import axios from 'axios';
// import QRCode from 'qrcode.react';
// import apiUrl from '../components/apiurl';
// import { getLocalStorageVariable } from '../components/localStorage';

// const App: React.FC = () => {
//   const token = getLocalStorageVariable<string>('token');
//   const [authCode, setAuthCode] = useState<string>('');

//   const handleLogin = async () => {
//     try {              
//           await axios.post(`${apiUrl.url}admin_user_upload/upload`, {
//               headers: {
//                   'Content-Type': 'multipart/form-data',
//                   Authorization: `${token}`,
//                   body: JSON.stringify({ authCode }),
//               },
              
//           });
//           console.log('File uploaded successfully');
//       } catch (error) {
//           console.error('Error uploading file: ' + (error as any).message);
//       }
//     };

//   return (
//     <div>
//       <h1>Two-Factor Authentication Example</h1>

//       <input
//         type="text"
//         placeholder="Enter 6-digit code from Authenticator"
//         value={authCode}
//         onChange={(e) => setAuthCode(e.target.value)}
//       />
//       <button onClick={handleLogin}>Login</button>

//     </div>
//   );
// };

// export default App;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import apiUrl from '../components/apiurl';

// const App: React.FC = () => {
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState('');
 
 
//   useEffect(() => {
//     const currentDate = new Date();
//     const formattedDate = formatDate(currentDate);
//     console.log(formattedDate)
//   }, []); 

//   const formatDate = (date: Date): string => {
//     const year = date.getFullYear();
//     const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
//     const day = date.getDate().toString().padStart(2, '0');

//     return `${year}-${month}-${day}`;
//   };

//   useEffect(() => {
//     // Fetch messages when the component mounts
//     // You may need to implement your own API for fetching chat history
//     // Example: axios.get('http://localhost:3001/api/messages').then((response) => {
//     //   setMessages(response.data);
//     // });

//     // For simplicity, this example assumes you have an API to fetch messages.
//   }, []);

//   const handleSendMessage = () => {
//     // Send the message to your backend (which will interact with Viber)
//     axios.post(`${apiUrl.url}viber/webhook`, {
//       sender: {
//         id: '+639553053508', // Replace with the actual Viber user ID
//       },
//       message: {
//         text: newMessage,
//       },
//     });

//     // Update the local messages state if needed
//     // setMessages([...messages, { sender: 'user', content: newMessage }]);

//     // Clear the input field
//     setNewMessage('');
//   };

//   return (
//     <div>
//       <h1>Viber Integration</h1>
//       <div>
//         {messages.map((message: any, index: number) => (
//           <div key={index}>
//             <strong>{message.sender}:</strong> {message.content}
//           </div>
//         ))}
//       </div>
//       <div>
//         <input
//           type="text"
//           placeholder="Message"
//           value={newMessage}
//           onChange={(e) => setNewMessage(e.target.value)}
//         />
//         <button onClick={handleSendMessage}>Send</button>
//       </div>
//     </div>
//   );
// };

// export default App;

// ExcelReader.tsx
import React, { ChangeEvent, useState } from 'react';
import * as XLSX from 'xlsx';

// interface ExcelReaderProps {
//   onFileChange: (data: any[][]) => void;
// }

const ExcelReader: React.FC = () => {
  const [tableData, setTableData] = useState<any[][]>([]);

  const readExcelFile = (file: File): Promise<any[][]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          resolve(jsonData);
        } catch (error) {
          reject(error);
        }
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const data = await readExcelFile(file);
        setTableData(data);
        // onFileChange(data);
      } catch (error) {
        console.error('Error reading Excel file:', error);
      }
    }
  };


  return (
    <div>
      <h1>Excel File Reader</h1>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
      {tableData.length > 0 && 
      <table>
      <thead>
        <tr>
          {tableData.length > 0 &&
            tableData[0].map((header, index) => (
              <th key={index}>{header}</th>
            ))}
        </tr>
      </thead>
      <tbody>
        {tableData.slice(1).map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
    }
    </div>
  );
};

export default ExcelReader;







