// src/components/LoginForm.tsx
import React from 'react';
import {
  Button,
  Container,
  Row,
  InputGroup,
  Alert,
  Col
} from 'react-bootstrap';
import { AiOutlineLogin } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
// import { AiOutlineKey } from "react-icons/ai";
import { AiOutlineSend } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { loginRequest } from './redux/loginActions';
import logo from '../../../images/linao.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { LoadingPage,LoginFailed } from '../../../components/loader';
import me from '../../../images/casa.jpg';
import { getLocalStorageVariable } from '../../../components/localStorage';

const LoginSchema = Yup.object().shape({
  email: Yup
    .string()
    .email('Invalid email')
    .required('Email is required!'),
  password: Yup
    .string()
    .required('Password is required!'),
});

const LoginForm: React.FC = () => {
  const redirectPath = useSelector((state: RootState) => state.LoginReducer.redirectPath);
  const failed = useSelector((state: RootState) => state.LoginReducer.error);
  const loading = useSelector((state: RootState) => state.LoginReducer.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const userId = getLocalStorageVariable<string>('userId');
    const userAuth = getLocalStorageVariable<string>('userAuth');
    if (userId && userAuth=='client') {
      navigate('/dashboard'); 
    }

    if (redirectPath) {
      navigate(redirectPath);
      window.location.reload();
    }
  }, [redirectPath, navigate, dispatch]);

  const handleSubmit = (values: any) => {
    const { email, password } = values;
    dispatch(loginRequest(email, password));
  };

  const handleItemClick = (path: string) => {
    // setSessionVariable('setSelectedItem', path);
    navigate(path);
};

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={LoginSchema}
      onSubmit={handleSubmit}
    >
      <div style={{
          backgroundImage : `url(${me})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          overflow: 'hidden',
          height:'91vh',
          width:'100%'
        }}>
        <div style={{
          position:'absolute',
          top:'0',right:'0',left:'0',bottom:'0',
          backgroundColor:'rgba(0,0,0,0.2)',
          zIndex:'0'
        }}></div>

      <Container style={{
        position:'relative',
        zIndex:'1'
      }}>
        {loading?<LoadingPage/>:""}
        {failed?<LoginFailed/>:""}

        <Row className="justify-content-md-center">
          <Col sm lg="4" className="mt-5 my-3 p-3 bg-white bg-opacity-75 rounded shadow-lg" >
              <div className="text-info text-center mt-3"> <img src={`${logo}`} alt="" width="180" height="110"/></div>
              <hr/>
              
              <Alert variant="primary">
                  <Alert.Heading><AiOutlineLogin size="25" /> Account Login</Alert.Heading>
              </Alert>
                <Form>
                  <Row>
                      <Col sm>   
                          <InputGroup className="mt-3 mb-1">
                            <InputGroup className="w-25">
                              <InputGroup.Text className="w-100">
                                <div className="text-center w-100"><AiOutlineMail size="25"/></div>
                              </InputGroup.Text>
                            </InputGroup>
                              <Field 
                                type="email" 
                                name="email" 
                                placeholder="E-mail"
                                className="w-75 form-control"
                              />
                            <ErrorMessage name="email">
                              {msg => <div style={{color:'red',padding:'5px', margin:'3px'}}>{msg}</div>}
                            </ErrorMessage>
                          </InputGroup>
                      </Col>
                    </Row>

                    <Row>
                        <Col sm>   
                          <InputGroup className="mt-1 mb-1">
                              <InputGroup className="w-25">
                                <InputGroup.Text className="w-100">
                                  <div className="text-center w-100"><RiLockPasswordLine size="25" /></div>
                                </InputGroup.Text>
                              </InputGroup>
                                <Field 
                                  type="password" 
                                  name="password" 
                                  placeholder="Password"
                                  className="w-75 form-control"
                                />
                              <ErrorMessage name="password">
                                {msg => <div style={{color:'red',padding:'5px', margin:'3px'}}>{msg}</div>}
                              </ErrorMessage>
                          </InputGroup>
                        </Col>
                    </Row>
                    <br/>
                    <div className="mb-3">
                        <div onClick={() => handleItemClick('/forgot')} style={{ textDecoration: 'none', color:'blue', cursor: 'pointer' }}>Forgot password?</div>
                    </div>
                    {/* <ReCAPTCHA sitekey="6Le73ZQaAAAAAMiABk5UPq2s-nPc1RCVS0opsrp8" onChange={onChange} /> */}
                    <br/>
                  <div className="d-grid gap-2">
                      <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-5" >
                          <AiOutlineSend size="20"/> Login
                      </Button>
                  </div>
                </Form>
          </Col>
          <Col sm lg="5">
          </Col>
        </Row>
      </Container>
      </div>
    </Formik>
  );
};

export default LoginForm;
