import { Association_Bill, Search_Account, Credit_Memo, Debit_Memo } from './adminAssociationTypes';

export enum AssociationActionTypes {
    // Create
    ASSOCIATION_CREATE_REQUEST = 'ASSOCIATION_CREATE_REQUEST',
    ASSOCIATION_CREATE_SUCCESS = 'ASSOCIATION_CREATE_SUCCESS',
    ASSOCIATION_CREATE_FAILURE = 'ASSOCIATION_CREATE_FAILURE',
    ASSOCIATION_CREATE_REDIRECT = 'ASSOCIATION_CREATE_REDIRECT',
    // Search
    ASSOCIATION_SEARCH_REQUEST = 'ASSOCIATION_SEARCH_REQUEST',
    ASSOCIATION_SEARCH_SUCCESS = 'ASSOCIATION_SEARCH_SUCCESS',
    ASSOCIATION_SEARCH_FAILURE = 'ASSOCIATION_SEARCH_FAILURE',
    ASSOCIATION_SEARCH_REDIRECT = 'ASSOCIATION_SEARCH_REDIRECT',
    // Credit Memo
    CREDIT_MEMO_REQUEST = 'CREDIT_MEMO_REQUEST',
    CREDIT_MEMO_SUCCESS = 'CREDIT_MEMO_SUCCESS',
    CREDIT_MEMO_FAILURE = 'CREDIT_MEMO_FAILURE',
    CREDIT_MEMO_REDIRECT = 'CREDIT_MEMO_REDIRECT',
    // Debit Memo
    DEBIT_MEMO_REQUEST = 'DEBIT_MEMO_REQUEST',
    DEBIT_MEMO_SUCCESS = 'DEBIT_MEMO_SUCCESS',
    DEBIT_MEMO_FAILURE = 'DEBIT_MEMO_FAILURE',
    DEBIT_MEMO_REDIRECT = 'DEBIT_MEMO_REDIRECT',
  }

  export type AssociationAction =
    | CreateRequestAction
    | CreateSuccessAction
    | CreateFailureAction
    | CreateRedirectAction
    // Search
    | SearchRequestAction
    | SearchSuccessAction
    | SearchFailureAction
    | SearchRedirectAction
     // Credit Memo
    | CreditRequestAction
    | CreditSuccessAction
    | CreditFailureAction
    | CreditRedirectAction
    // Credit Memo
    | DebitRequestAction
    | DebitSuccessAction
    | DebitFailureAction
    | DebitRedirectAction;

// ***** Create Association *****  
  interface CreateRequestAction {
    type: AssociationActionTypes.ASSOCIATION_CREATE_REQUEST;
    payload: Association_Bill;
  }
  interface CreateSuccessAction {
    type: AssociationActionTypes.ASSOCIATION_CREATE_SUCCESS;
    payload: Association_Bill;
  }
  interface CreateFailureAction {
    type: AssociationActionTypes.ASSOCIATION_CREATE_FAILURE;
    payload: string;
  }
  interface CreateRedirectAction {
    type: AssociationActionTypes.ASSOCIATION_CREATE_REDIRECT;
    payload: string;
  }
  
  export const associationRequest = (info: Association_Bill): CreateRequestAction => ({
    type: AssociationActionTypes.ASSOCIATION_CREATE_REQUEST,
    payload: info,
  });
  
  export const associationSuccess = (info: Association_Bill): CreateSuccessAction => ({
    type: AssociationActionTypes.ASSOCIATION_CREATE_SUCCESS,
    payload: info,
  });
  
  export const associationFailure = (error: string): CreateFailureAction => ({
    type: AssociationActionTypes.ASSOCIATION_CREATE_FAILURE,
    payload: error,
  });

  export const associationRedirect = (path: string): CreateRedirectAction => ({
    type: AssociationActionTypes.ASSOCIATION_CREATE_REDIRECT,
    payload: path,
  });
  

  // ***** Search Association *****  
  interface SearchRequestAction {
    type: AssociationActionTypes.ASSOCIATION_SEARCH_REQUEST;
    payload: Search_Account;
  }
  interface SearchSuccessAction {
    type: AssociationActionTypes.ASSOCIATION_SEARCH_SUCCESS;
    payload: Search_Account;
  }
  interface SearchFailureAction {
    type: AssociationActionTypes.ASSOCIATION_SEARCH_FAILURE;
    payload: string;
  }
  interface SearchRedirectAction {
    type: AssociationActionTypes.ASSOCIATION_SEARCH_REDIRECT;
    payload: string;
  }
  
  export const associationSearchRequest = (search: string): SearchRequestAction => ({
    type: AssociationActionTypes.ASSOCIATION_SEARCH_REQUEST,
    payload: {search},
  });
  
  export const associationSearchSuccess = (info: Search_Account): SearchSuccessAction => ({
    type: AssociationActionTypes.ASSOCIATION_SEARCH_SUCCESS,
    payload: info,
  });
  
  export const associationSearchFailure = (error: string): SearchFailureAction => ({
    type: AssociationActionTypes.ASSOCIATION_SEARCH_FAILURE,
    payload: error,
  });

  export const associationSearchRedirect = (path: string): SearchRedirectAction => ({
    type: AssociationActionTypes.ASSOCIATION_SEARCH_REDIRECT,
    payload: path,
  });


  // ***** Credit Memo *****  
  interface CreditRequestAction {
    type: AssociationActionTypes.CREDIT_MEMO_REQUEST;
    payload: Credit_Memo;
  }
  interface CreditSuccessAction {
    type: AssociationActionTypes.CREDIT_MEMO_SUCCESS;
    payload: Credit_Memo;
  }
  interface CreditFailureAction {
    type: AssociationActionTypes.CREDIT_MEMO_FAILURE;
    payload: string;
  }
  interface CreditRedirectAction {
    type: AssociationActionTypes.CREDIT_MEMO_REDIRECT;
    payload: string;
  }
  
  export const creditMemoRequest = (info: Credit_Memo): CreditRequestAction => ({
    type: AssociationActionTypes.CREDIT_MEMO_REQUEST,
    payload: info,
  });
  
  export const creditMemoSuccess = (info: Credit_Memo): CreditSuccessAction => ({
    type: AssociationActionTypes.CREDIT_MEMO_SUCCESS,
    payload: info,
  });
  
  export const creditMemoFailure = (error: string): CreditFailureAction => ({
    type: AssociationActionTypes.CREDIT_MEMO_FAILURE,
    payload: error,
  });

  export const creditMemoRedirect = (path: string): CreditRedirectAction => ({
    type: AssociationActionTypes.CREDIT_MEMO_REDIRECT,
    payload: path,
  });


  
  // ***** Debit Memo *****  
  interface DebitRequestAction {
    type: AssociationActionTypes.DEBIT_MEMO_REQUEST;
    payload: Debit_Memo;
  }
  interface DebitSuccessAction {
    type: AssociationActionTypes.DEBIT_MEMO_SUCCESS;
    payload: Debit_Memo;
  }
  interface DebitFailureAction {
    type: AssociationActionTypes.DEBIT_MEMO_FAILURE;
    payload: string;
  }
  interface DebitRedirectAction {
    type: AssociationActionTypes.DEBIT_MEMO_REDIRECT;
    payload: string;
  }
  
  export const debitMemoRequest = (info: Debit_Memo): DebitRequestAction => ({
    type: AssociationActionTypes.DEBIT_MEMO_REQUEST,
    payload: info,
  });
  
  export const debitMemoSuccess = (info: Debit_Memo): DebitSuccessAction => ({
    type: AssociationActionTypes.DEBIT_MEMO_SUCCESS,
    payload: info,
  });
  
  export const debitMemoFailure = (error: string): DebitFailureAction => ({
    type: AssociationActionTypes.DEBIT_MEMO_FAILURE,
    payload: error,
  });

  export const debitMemoRedirect = (path: string): DebitRedirectAction => ({
    type: AssociationActionTypes.DEBIT_MEMO_REDIRECT,
    payload: path,
  });