import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    InputGroup,
    Button,
    Table,
    Alert,
    Breadcrumb,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AiOutlineSend, AiOutlineControl, AiOutlineBars, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";

import SubMenu from '../submenu';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import { createAuthRequest } from './redux/adminSettingsActions';
import { LoadingPage, CreateAdminUserSuccess, CreateUserFailed, AuthAccessFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import { setSessionVariable } from '../../../components/sessionStorage';
import apiUrl from '../../../components/apiurl';

const CreateSchema = Yup.object().shape({
    auth: Yup.string()
        .trim()
        .max(7, 'Must be 7 characters or less')
        .required("Access type is required"),
    category: Yup.string()
        .trim()
        .max(15, 'Must be 15 characters or less')
        .required("Category is required"),
    create: Yup.string()
        .trim()
        .required("Create access is required"),
    read: Yup.string()
        .trim()
        .required("Read access is required"),
    update: Yup.string()
        .trim()
        .required("Update access is required."),
    delete: Yup.string()
        .trim()
        .required("Delete access is required."),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });

  interface UserData {
    id: number;
    auth: string;
    category: string;
    auth_create: number;
    auth_read: number;
    auth_update: number;
    auth_delete: number;
  }

const AccessSettings: React.FC = () =>{
    const failed = useSelector((state: RootState) => state.Admin_SettingsReducer.error);
    const loading = useSelector((state: RootState) => state.Admin_SettingsReducer.loading);
    const success = useSelector((state: RootState) => state.Admin_SettingsReducer.info);
    const settings_create = getLocalStorageVariable<string>('settings_create');
    const token = getLocalStorageVariable<string>('token');
    const [data, setData] = useState<UserData[] | null>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };

        try {
            const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}admin_get_auth`, { headers });
            setData(res.data.length > 0 ? res.data : null);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [token]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);
   
    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };
   
    const handleSubmit = (values: any) => {
        const payload = {
            auth: values.auth,
            category: values.category,
            auth_create: values.create,
            auth_read: values.read,
            auth_update: values.update,
            auth_delete: values.delete,
        }
        dispatch(createAuthRequest(payload));
      };

    return (
        <Formik
            initialValues={{ 
                auth: "",
                category: "",
                create: "",
                read: "",
                update: "",
                delete: "",
                confirm: false,
            }}
            validationSchema={CreateSchema}
            onSubmit={handleSubmit}
            >
            <div className="container-fluid ">
                {loading?<LoadingPage/>:""}
                {failed?<CreateUserFailed/>:""}
                {success?<CreateAdminUserSuccess />:""}
                {settings_create != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-warning" ><AiOutlineControl size="30" /> Access Settings</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_settings_menu')}>Settings Menu</Breadcrumb.Item>
                                <Breadcrumb.Item active>Management User</Breadcrumb.Item>
                            </Breadcrumb>
                        <hr/>
                        <div style = {{ width:"400px"}} />
                        Create new access data.
                        <Alert style={{border: "1px solid rgb(128, 128, 128)", backgroundColor:'#e6ffe3'}} className='mt-2'>
                        <Form>
                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Access <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="auth" 
                                                maxLength="10"
                                                className="w-75 form-control"
                                            >
                                                <option value="">- Select an option -</option>
                                                <option value="admin">Administrator</option>
                                                <option value="manager">Manager</option>
                                                <option value="encoder">Encoder</option>
                                                <option value="accnt">Accounting</option>
                                                <option value="guest">Guest</option>
                                            </Field>
                                        <ErrorMessage name="auth">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Category <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="category" 
                                                maxLength="10"
                                                className="w-50 form-control"
                                            >
                                                <option value="">- Select an option -</option>
                                                <option value="dashboard">Dashboard</option>
                                                <option value="user">Billing Account</option>
                                                <option value="association">Association</option>
                                                <option value="water">Water</option>
                                                <option value="payment">Payment</option>
                                                <option value="report">Report</option>
                                                <option value="settings">Settings</option>
                                            </Field>
                                        <ErrorMessage name="category">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm></Col>
                            </Row>

                            <Row className="mb-1">
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Create <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="create" 
                                                maxLength="10"
                                                className="w-75 form-control border-warning"
                                            >
                                                <option value="">- Select an option -</option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </Field>
                                        <ErrorMessage name="create">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Read <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="read" 
                                                maxLength="10"
                                                className="w-75 form-control border-warning"
                                            >
                                                <option value="">- Select an option -</option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </Field>
                                        <ErrorMessage name="read">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Update <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="update" 
                                                maxLength="10"
                                                className="w-75 form-control border-warning"
                                            >
                                                <option value="">- Select an option -</option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </Field>
                                        <ErrorMessage name="update">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Delete <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="delete" 
                                                maxLength="10"
                                                className="w-75 form-control border-warning"
                                            >
                                                <option value="">- Select an option -</option>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>
                                            </Field>
                                        <ErrorMessage name="delete">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <br/>
                                <Col sm> 
                                    <label>
                                        <Field type="checkbox" name="confirm" />
                                        &nbsp;Please confirm for creating new data.
                                    </label>
                                    <ErrorMessage name="confirm">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            <br/>
                            <div className="d-grid gap-2">
                                <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-1" >
                                    <AiOutlineSend size="20"/> Submit
                                </Button>
                            </div>
                        </Form>
                        </Alert>

                        <hr />
                        <h4 className="text-warning mt-3" ><AiOutlineBars size="30" /> Access Setting List</h4>
                        <Table responsive striped bordered hover size="sm">
                            <thead>
                                <tr className="text-center">
                                    <th>#</th>
                                    <th>Access</th>
                                    <th>Category</th>
                                    <th>Create</th>
                                    <th>Read</th>
                                    <th>Update</th>
                                    <th>Delete</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                                {data ? (
                                    <tbody>
                                        {data.map((r, i) => (
                                        <tr key={i} className="align-middle text-secondary">
                                            <td className="text-center align-middle">{i + 1}</td>
                                            <td className="text-center align-middle">{r.auth}</td>
                                            <td className="">{r.category}</td>
                                            <td className="text-center">{r.auth_create}</td>
                                            <td className="text-center">{r.auth_read}</td>
                                            <td className="text-center">{r.auth_update}</td>
                                            <td className="text-center">{r.auth_delete}</td>
                                            <td className="text-center align-middle">
                                                <Button variant="outline-success" size="sm" className='me-2 btn btn-block rounded-pill'><AiOutlineEdit /> Update</Button>
                                                {/* <Button variant="outline-danger" size="sm" className='btn btn-block rounded-pill'><AiOutlineDelete  /> Delete</Button> */}
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr><td>Loading...</td></tr>
                                    </tbody>
                                )}
                        </Table>
                    </Col>
                </Row>
            </div>
        </Formik>
    );
};

export default AccessSettings;