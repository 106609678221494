import React, { useState, useEffect, useRef } from 'react';
import { 
  Row, 
  Col,
  Alert,
  Breadcrumb,
} from 'react-bootstrap';
import SubMenu from '../submenu';
import axios from 'axios';
import { AiOutlineComment } from "react-icons/ai";
import apiUrl from '../../../components/apiurl';
import { getLocalStorageVariable } from '../../../components/localStorage';

interface Message {
  id: number;
  sender: string | null;
  content: string;
  timestamp: string;
  recipient?: string;
  isSeen?: boolean;
}

interface Message_List {
  id: number;
  sender: string | null;
  recipient: string;
  isseen?: boolean;
}

interface Message_List_User_Details {
  id: number;
  fname: string;
  mi: string;
  lname: string;
  phase: string;
  block: string;
  lot: string;
  avatar: string;
}

const Messages: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [messages_list, setMessagesList] = useState<Message_List[]>([]);
    const [messages_list_user_details, setMessagesListUserDetails] = useState<Array<Array<Message_List_User_Details>>>([]);
    const sender = getLocalStorageVariable<string>('userId');
    const token = getLocalStorageVariable<string>('token');
    const [reciever, setReciever] = useState('');
    const [content, setContent] = useState('');
    const [recAvatar, setRecAvatar] = useState('');
    const userAvatar = getLocalStorageVariable<string>('avatar');
    // const [selectedMessageId, setSelectedMessageId] = useState<number | null>(null);
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const headers = {
      Authorization: `${token}`,
    };

    // ***** Message List display *****
    const getMessageList = (sender:string) => {
      axios.get(`${apiUrl.url}messages/message_list/${sender}`,{headers})
        .then(response => {
          setMessagesList(response.data);
            const userDetailsPromises = response.data.map((item: { sender: string })  =>
              axios.get(`${apiUrl.url}messages/message_list_user_details/${item.sender}`,{headers})
            );
              Promise.all(userDetailsPromises)
                .then(userDetailsResponses => {
                  const userDetailsData = userDetailsResponses.map(response => response.data);
                  setMessagesListUserDetails(userDetailsData);
                })
                .catch(error => console.error(error));
            })
        .catch(error => console.error(error));
      }

    useEffect(() => {
      if (sender) {
        getMessageList(sender);
      }
    }, [sender]);
          
  // ***** To make the message div start from bottom *****
    useEffect(() => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
      }
    }, [messages]);

  // ***** Sending Message *****
    const sendMessage = () => {
      axios.post(`${apiUrl.url}messages`, { sender, content, recipient: reciever },{headers})
        .then(response => {
          setMessages([...messages, { id: response.data.id, sender, content, timestamp: new Date().toISOString() }]);
          setContent('');
        })
        .catch(error => console.error(error));
    };

  // ***** Selecting Message to Display *****  
    const selectMessage = (messageId: number, id: any, avatar: string) => {
      axios.get(`${apiUrl.url}messages/message_display/${messageId}/${sender}`,{headers})
        .then(response => {
          setMessages(response.data);
          if(sender==response.data[0].sender){
            setReciever(response.data[0].recipient)
            markAsRead(response.data[0].recipient,response.data[0].sender);
          }else{
            setReciever(response.data[0].sender)
            markAsRead(response.data[0].sender,response.data[0].recipient);
          }
          setSelectedItem(id);
          setRecAvatar(avatar);
          if (sender) {
            getMessageList(sender);
          }
        })
        .catch(error => {
          console.error(error);
        });
    };

    // useEffect(() => {
    //   const intervalId = setInterval(() => {
    //     if (selectedItem !== null) {
    //       selectMessage(selectedItem, selectedItem, recAvatar);
    //     }
    //   }, 1000);
    //   return () => clearInterval(intervalId);
    // }, [selectedItem, recAvatar]);

  
    const markAsRead = (sender: number, recipient: number) => {
      axios.put(`${apiUrl.url}messages/${sender}/${recipient}`,null,{headers})
        .then(response => {
          // console.log(`Message ${messageId} marked as read`);
          })
        .catch(error => console.error(error));
    };
    
    
  // ***** Assuming message.timestamp is a string representing a valid date
    const formattedTimestamp = (timestamp: string) => {
      const date = new Date(timestamp);

      const timeOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      };
      const dateOptions: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
      };

      const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);
      const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
      return `${formattedTime} | ${formattedDate}`;
    };

return (
  <div className="container-fluid ">
    <Row className="justify-content-md-center">
      <Col>
          <SubMenu/>
      </Col>
      <Col lg="10"  className="p-3 bg-white rounded shadow-sm">
          <h4 className="text-primary" ><AiOutlineComment size="30" /> Messages</h4>
                  <Breadcrumb>
                      <Breadcrumb.Item href="/admin_dashboard">Dashboard</Breadcrumb.Item>
                      <Breadcrumb.Item active>Messages</Breadcrumb.Item>
                  </Breadcrumb>
              <hr/>
            <section >
              <div className="row">
                <div className="col-md-12">
            
                  <Alert style={{border: "1px solid rgb(128, 128, 128)"}} className=''>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
                              <div className="input-group rounded mb-3">
                                <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search"
                                  aria-describedby="search-addon" />
                              </div>
            
                              <div style={{ position: 'relative', height: '550px', overflowY: 'auto'}}>
                                <ul className="list-unstyled mb-0 bg-light">
                                  {messages_list_user_details.map((userDetailsArray, index) => (
                                    <li className="p-2 pb-0 border-bottom" style={{cursor:'pointer'}} key={index}>
                                      <div onClick={() => selectMessage(userDetailsArray[0]?.id, userDetailsArray[0]?.id, userDetailsArray[0]?.avatar)} className="d-flex justify-content-between" style={{ textDecoration: 'none' }}>
                                      {selectedItem==userDetailsArray[0]?.id?
                                        <div className="d-flex flex-row text-primary fw-bold">
                                          <div>
                                            <img
                                              src={userDetailsArray[0]?.avatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'}
                                              alt="avatar"
                                              className="d-flex align-self-center me-3"
                                              width="50"
                                            />
                                          </div>
                                          <div className="pt-1">
                                            <p className="large mb-0">{userDetailsArray[0]?.fname} {userDetailsArray[0]?.mi} {userDetailsArray[0]?.lname} </p>
                                            <p className="large text-muted mb-2">Phase {userDetailsArray[0]?.phase}, Block {userDetailsArray[0]?.block}, Lot {userDetailsArray[0]?.lot}</p>
                                          </div>
                                        </div>
                                      :
                                        <div className="d-flex flex-row">
                                          <div>
                                            <img
                                              src={userDetailsArray[0]?.avatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'}
                                              alt="avatar"
                                              className="d-flex align-self-center me-3"
                                              width="50"
                                            />
                                          </div>
                                          <div className="pt-1">
                                            <p className="mb-0 text-muted">{userDetailsArray[0]?.fname} {userDetailsArray[0]?.mi} {userDetailsArray[0]?.lname}</p>
                                            <p className="small text-muted mb-2">Phase {userDetailsArray[0]?.phase}, Block {userDetailsArray[0]?.block}, Lot {userDetailsArray[0]?.lot}</p>
                                          </div>
                                        </div>
                                      }
                                        <div className="pt-1">
                                          {messages_list.map(message => (
                                            <div key={message.id}>
                                              {message.sender == userDetailsArray[0]?.id?.toString() && (
                                                message.isseen ? '' : <span className="badge bg-danger rounded-pill float-end">&nbsp;</span>
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                          </div>
            
                          <div className="col-md-6 col-lg-7 col-xl-8">
                            
                            <div className="p-3 pe-3 shadow-sm" 
                              style={{ position: 'relative', height: '550px', overflowY: 'auto', backgroundColor:'white'}} ref={messagesContainerRef}>
                              {messages.map((message, index) => (
                                <div key={index}>
                                  {sender==message.recipient?
                                    <div className="d-flex flex-row justify-content-start"key={index}>
                                      <img src={recAvatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'}
                                        alt="avatar 1" style={{width: '45px', height: '100%'}}/>
                                      <div>
                                        <p className="medium p-2 ms-3 mb-1 rounded-3" style={{backgroundColor: '#e1e1e1'}}>
                                          {message.content} 
                                        </p>
                                        <p className="small me-3 mb-3 rounded-3 text-muted">{formattedTimestamp(message.timestamp)}</p>
                                      </div>
                                    </div>
                                  :
                                    <div className="d-flex flex-row justify-content-end" key={index}>
                                      <div>
                                        <p className="medium p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                                          {message.content} 
                                        </p>
                                        <p className="small me-3 mb-3 rounded-3 text-muted">{formattedTimestamp(message.timestamp)}</p>
                                      </div>
                                      <img src={userAvatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'}
                                        alt="avatar 1" style={{width: '45px', height: '100%'}}/>
                                    </div>
                                  }
                                </div>
                              ))}

                            </div>
            
                            <div className="text-muted d-flex justify-content-start align-items-center pt-3 mt-2">
                                {selectedItem?
                                  <input
                                    type="text"
                                    className="form-control form-control-md rounded-pill"
                                    id="exampleFormControlInput2"
                                    placeholder="Type message"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault();
                                        sendMessage();
                                      }
                                    }}
                                  />
                                :<></>}
                            </div>
            
                          </div>
                        </div>
            
                      </div>
                    </Alert>
                  </div>
                </div>
            </section>
          </Col>
        </Row>
      </div>
    );
  };
  
  export default Messages;
  
