import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    Card,
    Container,
    Alert,
    Breadcrumb,
    InputGroup,
    Button,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import SubMenu from '../submenu';
import apiUrl from '../../../components/apiurl';
import { setLocalStorageVariable, getLocalStorageVariable } from '../../../components/localStorage';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import { AiOutlineBell, AiOutlineDollar } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
import { FaRegListAlt } from "react-icons/fa";
import { VscOpenPreview } from "react-icons/vsc";
import { IoWaterOutline } from "react-icons/io5";

const SearchSchema = Yup.object().shape({
    search: Yup.string()
        .trim(),
  });

interface Notification {
  id: number;
  user_id: number;
  message: string;
  type: string;
  user_type: string;
  timestamp: string;
  status: boolean;
}

const Notifications: React.FC = () => {
    const userId = getLocalStorageVariable<string>('userId');
    const userAuth = getLocalStorageVariable<string>('userAuth');
    const token = getLocalStorageVariable<string>('token');
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [newNotification, setNewNotification] = useState<string>('');


    const fetchNotificationhData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<Notification[]> = await axios.get(`${apiUrl.url}notifications/${userId}/${userAuth}`, { headers });
            setNotifications(res.data);
        } catch (error) {
            console.error('Error: ', error);
            return false;
        }
    }, [userId, userAuth, token]);

    useEffect(() => {
        fetchNotificationhData();
    }, []);


    // const handleCreateNotification = useCallback(async () => {
    //     const headers = {
    //         Authorization: `${token}`,
    //       };
    //     try {
    //         if (!userId || !newNotification) {
    //             console.error('userId or newNotification is missing.');
    //             return false;
    //         }
    
    //         const res: AxiosResponse<{ data: Notification }> = await axios.post(
    //             `${apiUrl.url}notifications`,
    //             { user_id: userId, message: newNotification },
    //             { headers: { Authorization: `${token}` } }
    //         );
    //         window.location.reload();
    //     } catch (error) {
    //         console.error('Error creating notification:', error);
    //         return false;
    //     }
    // }, [userId, newNotification, token]);
    

    const handleMarkAsSeen = useCallback(async (notificationId:any) => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res = await axios.patch(`${apiUrl.url}notifications/${notificationId}/mark-as-seen`,{},{ headers });
            setNotifications(notifications.map(notification =>
                notification.id === notificationId ? { ...notification, status: true } : notification
            ));
            window.location.reload();
        } catch (error) {
            console.error('Error marking notification as seen:', error);
            return false;
        }
    }, [token]); 
    


    const handleSearch = (values: { search: string }) => {
        // setSearchTerm(values.search);
        // setCurrentPage(1);
    };



    return (
        <div className="container-fluid ">
            <Row className="justify-content-md-center">
                <Col sm={3}>
                    <SubMenu/>
                </Col>
                <Col lg="9"  className="p-3 bg-white rounded shadow-sm">
                    <h4 className="text-primary" ><AiOutlineBell size="30" /> Notifications</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item active>Notifications</Breadcrumb.Item>
                            </Breadcrumb>
                        <hr/>
                        Transaction notifications
                        <div>
                            {/* <h1>Notification App</h1>
                            <div>
                                <input
                                type="text"
                                placeholder="Enter notification message"
                                value={newNotification}
                                onChange={(e) => setNewNotification(e.target.value)}
                                />
                                <button onClick={handleCreateNotification}>Create Notification</button>
                            </div> */}
                            {/* <div>
                                    <ul>
                                        {notifications.map(notification => (
                                            <li key={notification.id}>
                                                {notification.message} - {new Date(notification.timestamp).toLocaleString()}
                                                {!notification.status && (
                                                    <button onClick={() => handleMarkAsSeen(notification.id)}>Mark as Seen</button>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                            </div> */}
                    <Alert style={{border: "1px solid rgb(128, 128, 128)"}} className='mt-2 bg-light'>
                    <Row>
                        
                        <Col lg="4">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{ 
                                search: "",
                            }}
                            validationSchema={SearchSchema}
                            onSubmit={handleSearch}
                            >
                                <Form>
                                    {/* <Row> */}
                                        {/* <Col xs lg="3"  className="mt-2 bg-white rounded"> */}
                                            <InputGroup className=" mb-3">
                                                {/* <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Search
                                                    </InputGroup.Text>
                                                </InputGroup> */}
                                                    <Field 
                                                        type="search" 
                                                        name="search"
                                                        placeholder="&#x1F50E; Search..."
                                                        maxLength="50"
                                                        className="w-50 form-control rounded-pill"
                                                        style={{border: "1px solid rgb(128, 128, 128)"}}
                                                    >
                                                    </Field>
                                                    <Button type="submit" variant="outline-success" style={{display:'none'}}><BiSearchAlt size="20" className='ms-2 me-2'/></Button>
                                                <ErrorMessage name="search">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        {/* </Col> */}
                                    {/* </Row> */}
                                </Form>
                            </Formik>
                            
                                    <ListGroup variant="flush" className="rounded" >

                                        {notifications.map(notification => (
                                            <ListGroup.Item
                                                action onClick={() => handleMarkAsSeen(notification.id)}
                                                variant="light"
                                                // as="li"
                                                key={notification.id}
                                                className="d-flex justify-content-between align-items-start"
                                                style={{cursor: 'pointer'}}
                                            >
                                                    {!notification.status ? 
                                                    (<>
                                                        <IoWaterOutline size="25" className='me-3' />
                                                        <div className=" me-auto text-dark" >
                                                        
                                                            <div className="fw-bold">{notification.type}</div>
                                                            <div className="fw-bold">{new Date(notification.timestamp).toLocaleString()}</div>
                                                        </div>
                                                        <Badge bg="danger" pill>
                                                            &nbsp;
                                                        </Badge>
                                                    </>):
                                                    (<>
                                                        <AiOutlineDollar size="25" className='me-3' />
                                                        <div className=" me-auto text-secondary" >
                                                            <div className="fw-light">{notification.type}</div>
                                                            <div className="fw-light">{new Date(notification.timestamp).toLocaleString()}</div>
                                                        </div>
                                                    </>)}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Col>

                                <Col lg="8">
                                    <h5 className="text-primary" ><VscOpenPreview size="30" /> Preview</h5>
                                    <hr/>
                                        

                                </Col>
                                </Row>
                            
                            </Alert>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

export default Notifications;
