import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { 
  Admin_AuthActionTypes, 
  admin_loginSuccess, 
  admin_loginFailure, 
  admin_redirect,
  admin_forgotSuccess, 
  admin_forgotFailure, 
  admin_forgotRedirect
 } from './loginActions';
import apiUrl from '../../../../components/apiurl';
import axios from 'axios';
import { setLocalStorageVariable } from '../../../../components/localStorage';
import { setSessionVariable } from '../../../../components/sessionStorage';

function* admin_login(action: any): Generator<any, void, AxiosResponse> {
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
      const day = date.getDate().toString().padStart(2, '0');
  
      return `${year}-${month}-${day}`;
    };
    
  try {
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);

    const { email, password, authCode } = action.payload;
    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_login`, { email, password, authCode });
    const user = response.data;

      if(user!=''){
        yield put(admin_loginSuccess(user));
        yield put(admin_redirect('/admin_dashboard'));
        setLocalStorageVariable('userId', user.id);
        setLocalStorageVariable('userAuth', user.auth);
        setLocalStorageVariable('avatar', user.avatar);
        setLocalStorageVariable('token', user.token);
        setLocalStorageVariable('loginDate', formattedDate);
        setSessionVariable('setSelectedItem', '/admin_dashboard');
      }else{
        yield put(admin_loginFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(admin_loginFailure(errorMessage));
  }
}



// Forgot *************
function* admin_forgot(action: any): Generator<any, void, AxiosResponse> {
  try {
    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_login/forgot`, action.payload);
    const user = response.data;
    console.log(user)

      if(user!=''){
        yield put(admin_forgotSuccess(user));
        yield put(admin_forgotRedirect('/admin_login'));
          const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}send_email_no_token`, {
            to: action.payload.email,
            subject: 'Password Reset Notification',
            html: `<div style="font-family:Arial, Helvetica, sans-serif; font-size: 16px;">
              <div style="background-color: #00c04b; color: white; padding: 10px; border: none; width: 95%; text-align: center;">
                <h1>Casa Mira - Linao</h1>
                <h2>Reset Password</h2>
              </div>
              <div style="padding: 30px;">
                  Your password in Casa Mira - Linao Application is reset. <br/>Your temporary password is: <b> ${action.payload.password}</b><br/><br/>
                    This password is system generated.<br/>
                    Please update your password after you login.
                  <br/><br/>
                  Best regards,<br/>
                  Casa Mira - Linao Application Team
              </div>
            </div>`,
          });
    
          if (response.status === 200) {
            console.log('Email sent successfully');
          } else {
            console.error('Failed to send email');
          }

      }else{
        yield put(admin_forgotFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(admin_forgotFailure(errorMessage));
  }
}

function* admin_watchLogin() {
  yield takeLatest(Admin_AuthActionTypes.ADMIN_LOGIN_REQUEST, admin_login);
  yield takeLatest(Admin_AuthActionTypes.ADMIN_FORGOT_REQUEST, admin_forgot);
}

export default admin_watchLogin;
