import { User, Forgot_User } from './loginTypes';

export enum AuthActionTypes {
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    LOGIN_REDIRECT = 'LOGIN_REDIRECT',
    // Forgot
    FORGOT_REQUEST = 'FORGOT_REQUEST',
    FORGOT_SUCCESS = 'FORGOT_SUCCESS',
    FORGOT_FAILURE = 'FORGOT_FAILURE',
    FORGOT_REDIRECT = 'FORGOT_REDIRECT',
  }
  
  interface LoginRequestAction {
    type: AuthActionTypes.LOGIN_REQUEST;
    payload: { email: string; password: string };
  }
  interface LoginSuccessAction {
    type: AuthActionTypes.LOGIN_SUCCESS;
    payload: User;
  }
  interface LoginFailureAction {
    type: AuthActionTypes.LOGIN_FAILURE;
    payload: string;
  }
  interface LoginRedirectAction {
    type: AuthActionTypes.LOGIN_REDIRECT;
    payload: string;
  }
  
  export type AuthAction =
    | LoginRequestAction
    | LoginSuccessAction
    | LoginFailureAction
    | LoginRedirectAction
    | ForgotRequestAction
    | ForgotSuccessAction
    | ForgotFailureAction
    | ForgotRedirectAction;
  
  // src/store/auth/actions.ts
  export const loginRequest = (email: string, password: string): LoginRequestAction => ({
    type: AuthActionTypes.LOGIN_REQUEST,
    payload: { email, password },
  });
  
  export const loginSuccess = (user: User): LoginSuccessAction => ({
    type: AuthActionTypes.LOGIN_SUCCESS,
    payload: user,
  });
  
  export const loginFailure = (error: string): LoginFailureAction => ({
    type: AuthActionTypes.LOGIN_FAILURE,
    payload: error,
  });

  export const redirect = (path: string): LoginRedirectAction => ({
    type: AuthActionTypes.LOGIN_REDIRECT,
    payload: path,
  });
  
  
// ********************* Forgot ******************************

interface ForgotRequestAction {
  type: AuthActionTypes.FORGOT_REQUEST;
  payload: Forgot_User;
}

interface ForgotSuccessAction {
  type: AuthActionTypes.FORGOT_SUCCESS;
  payload: Forgot_User;
}

interface ForgotFailureAction {
  type: AuthActionTypes.FORGOT_FAILURE;
  payload: string;
}

interface ForgotRedirectAction {
  type: AuthActionTypes.FORGOT_REDIRECT;
  payload: string;
}


export const forgotRequest = (user: Forgot_User): ForgotRequestAction => ({
  type: AuthActionTypes.FORGOT_REQUEST,
  payload: user,
});

export const forgotSuccess = (user: Forgot_User): ForgotSuccessAction => ({
  type: AuthActionTypes.FORGOT_SUCCESS,
  payload: user,
});

export const forgotFailure = (error: string): ForgotFailureAction => ({
  type: AuthActionTypes.FORGOT_FAILURE,
  payload: error,
});

export const forgotRedirect = (path: string): ForgotRedirectAction => ({
  type: AuthActionTypes.FORGOT_REDIRECT,
  payload: path,
});