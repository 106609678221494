import React, { useState, useEffect, useCallback, ChangeEvent, useMemo } from 'react';
import ReactToPrint from 'react-to-print';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    InputGroup,
    Button,
    Alert,
    Table,
    Breadcrumb,
    Pagination,
    Image,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AiOutlineSend, AiOutlineFileAdd, AiOutlineBars } from "react-icons/ai";
import { FiUploadCloud, FiDownloadCloud } from "react-icons/fi";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaPrint } from "react-icons/fa";
import { TfiPrinter } from "react-icons/tfi";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaWater } from "react-icons/fa";
import { BiSearchAlt } from "react-icons/bi";
import { FaFileCsv } from "react-icons/fa";
import SubMenu from '../submenu';
import { useNavigate } from 'react-router-dom';
import { setSessionVariable, getSessionVariable } from '../../../components/sessionStorage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { waterRequest } from './redux/adminWaterActions';
import { AuthAccessFailed } from '../../../components/loader';
import { LoadingPage, CreateWaterSuccess, CreateWaterFailed } from './loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import apiUrl from '../../../components/apiurl';
import linao from '../../../images/linao.png';    
import clipm from '../../../images/clipm.png';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import saveAs from 'file-saver';
import Barcode from 'react-barcode';

  
const SearchSchema = Yup.object().shape({
    search: Yup.string()
        .trim(),
  });

const CreateSchema = Yup.object().shape({
    fdate: Yup.string()
        .required("From date is required")
        .test('isValidDate', 'Invalid date', function (value) {
            if (!value) {
              return true;
            }
            const parsedDate = new Date(value);
            return !isNaN(parsedDate.getTime());
          }),
    tdate: Yup.string()
        .required("To date is required")
        .test('isValidDate', 'Invalid date', function (value) {
            if (!value) {
              return true;
            }
            const parsedDate = new Date(value);
            return !isNaN(parsedDate.getTime());
          })
          .test('isAfterFDate', 'To date should be after From date', function (value) {
            if (!value || !this.parent.fdate) {
              // Let the previous validation handle empty values
              return true;
            }
            return new Date(value) >= new Date(this.parent.fdate);
          }),
    cdate: Yup.string()
        .required("Bill date is required")
        .test('isValidDate', 'Invalid date', function (value) {
            if (!value) {
              return true;
            }
            const parsedDate = new Date(value);
            return !isNaN(parsedDate.getTime());
          }),
    accounting: Yup.string()
        .max(5, 'Must be 5 characters or less')
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Accounting is required."),
    manager: Yup.string()
        .max(5, 'Must be 5 characters or less')
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Manager is required."),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });

  interface SystemData {
    user_acct_no: number;
    charge_amount: number;
    water_rate: number;
    water_no: number;
    name: string;
    bank: string;
    bank_account: string;
  }

  interface UserData {
    id: number;
    account: number;
    fname: string;
    mi: string;
    lname: string;
    phase: string;
    block: string;
    lot: string;
    email: string;
    cellphone: string;
    lot_area: number;
    dues: number;
    water_balance: number;
    status: number;
  }

  interface AdminData {
    id: number;
    auth: string;
    fname: string;
    mi: string;
    lname: string;
  }

  interface WaterData {
    water_no: number;
    account: number;
    fname: string;
    mi: string;
    lname: string;
    phase: string;
    block: string;
    lot: string;
    previous: string;
    present: string;
    consumed: number;
    water_rate: number;
    charge: number;
    cdate: string;
    date_from: string;
    date_to: string;
    total_amount: number;
    balance: number;
    accounting_id: number;
    manager_id: number;
    discount: number;
  }

  interface ExcelRow {
    [key: string]: string | number; // Adjust the types based on your Excel data types
  }

const ITEMS_PER_PAGE = 50;

const Water_Bill: React.FC = () =>{
    const failed = useSelector((state: RootState) => state.Admin_WaterReducer.error);
    const loading = useSelector((state: RootState) => state.Admin_WaterReducer.loading);
    const success = useSelector((state: RootState) => state.Admin_WaterReducer.info);
    const token = getLocalStorageVariable<string>('token');
    const [systemData, setSystemData] = useState<SystemData[] | null>(null);
    const [userData, setUserData] = useState<UserData[] | null>(null);
    const [adminData, setAdminData] = useState<AdminData[] | null>(null);
    const [WaterData, setWaterData] = useState<WaterData[] | null>(null);
    const [waterAllData, setAllWaterData] = useState<WaterData[] | null>(null);
    const [filteredWaterData, setFilteredWaterData] = useState<WaterData[]>([]);
    const navigate = useNavigate();
    const selectedItem = getSessionVariable<string>('setSelectedItem');
    // const [file, setFile] = useState<File | null>(null);
    // const [data, setData] = useState<any[]>([]);
    // const [header, setHeaders] = useState<string[]>([]);
    // const [loadingUpload, setLoading] = useState('');
    // const [successUpload, setSuccess] = useState('');
    // const [failedUpload, setFailed] = useState('');
    const [isPrinting, setIsPrinting] = useState(false);
    const [isPrintingList, setIsPrintingList] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize] = useState(50); // Adjust according to your needs

    const water_create = getLocalStorageVariable<string>('water_create');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermDate, setSearchTermDate] = useState('2024-01-01');
    const dispatch = useDispatch();
    const componentRef = React.useRef(null);
    const printAllRef = React.useRef(null);
    const id = '1';

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = filteredWaterData?.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    const totalPages = Math.ceil((filteredWaterData?.length || 0) / ITEMS_PER_PAGE);

    const headers = [
        { label: "Date", key: "cdate" },
        { label: "Bill #", key: "association_no" },
        { label: "First Name", key: "fname" },
        { label: "Last Name", key: "lname" },
        { label: "Phase", key: "phase" },
        { label: "Block", key: "block" },
        { label: "Lot", key: "lot" },
        { label: "Lot Area", key: "lot_area" },
        { label: "Dues", key: "dues" },
        { label: "Charge", key: "charge" },
        { label: "Total Amount", key: "total_amount" },
      ];

      const formatDateExcel = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
      };

      const formattedData = useMemo(() => {
        return filteredWaterData?.map((item) => ({
          ...item,
          date_from: formatDateExcel(item.date_from),
          date_to: formatDateExcel(item.date_to),
          cdate: formatDateExcel(item.cdate),
        }));
      }, [filteredWaterData]);

    //   const formattedData = filteredWaterData.map((item) => ({
    //     ...item,
    //     date_from: formatDateExcel(item.date_from),
    //     date_to: formatDateExcel(item.date_to),
    //     cdate: formatDateExcel(item.cdate),
    //   }));
      
      const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const dataBlob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(dataBlob, "association_bill.xlsx");
      };

// ***** Print List *****
    const handlePrintList = () => {
        setIsPrintingList(true);
        // Open a new window and write the content to be printed
        const printWindow = window.open('', '_blank');
        printWindow?.document.write('<html><head><title>Print</title></head><body>');
        printWindow?.document.write((printableContentList as any).outerHTML);
        printWindow?.document.write('</body></html>');
        printWindow?.document.close();

        // Trigger the print
        printWindow?.print();
        setIsPrintingList(false);     
    }

const printableContentList = (
    <div>
        <div style={{fontSize:'10px'}} className="print-content">
                <Table responsive striped bordered hover size="sm">
                    <thead>
                        <tr className="text-center">
                            <th>#</th>
                            <th>Date</th>
                            <th>Bill #</th>
                            <th>Account #</th>
                            <th>Name</th>
                            <th>Phase</th>
                            <th>Block</th>
                            <th>Lot</th>
                            <th>Previous</th>
                            <th>Present</th>
                            <th>Consumed</th>
                            <th>Rate</th>
                            <th>Charge</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                        {filteredWaterData?.length!=0? (
                            <tbody>
                                {filteredWaterData?.map((r, i) => (
                                <tr key={i} className="align-middle text-secondary">
                                    <td className="text-center align-middle">{i + 1}</td>
                                    <td className="text-center align-middle">{(new Date(r.cdate)).toLocaleDateString("en-PH")}</td>
                                    <td className="text-center align-middle">{r.water_no}</td>
                                    <td className="text-center align-middle">{r.account}</td>
                                    <td className="">{r.fname} {r.mi}. {r.lname}</td>
                                    <td className="text-center align-middle">{r.phase}</td>
                                    <td className="text-center align-middle">{r.block}</td>
                                    <td className="text-center align-middle">{r.lot}</td>
                                    <td className="text-center align-middle">{r.previous}</td>
                                    <td className="text-center align-middle">{r.present}</td>
                                    <td className="text-center align-middle">{r.consumed}</td>
                                    <td className="text-center align-middle">{r.water_rate}</td>
                                    <td className="text-center align-middle">{r.charge}</td>
                                    <td className="text-end">
                                        {(r.total_amount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr><td colSpan={15} className='text-danger'>No association bill found...</td></tr>
                            </tbody>
                        )}
                </Table>
                <style>
                    {`
                    @media print {
                        body {
                            margin: 0;
                        }
                        .print-content {
                        }
                        @page {
                            size: A4;
                            margin: 5mm;
                        }
                    }
                    `}
                </style>
            </div>
        </div>
    );

     // ***** Print Per User *****
     const handlePrint = () => {
        setIsPrinting(true);
        // Open a new window and write the content to be printed
        const printWindow = window.open('', '_blank');
        printWindow?.document.write('<html><head><title>Print</title></head><body>');
        printWindow?.document.write((printableContent as any).outerHTML);
        printWindow?.document.write('</body></html>');
        printWindow?.document.close();

        // Trigger the print
        printWindow?.print();
        setIsPrinting(false);     
    }
        // Content to be printed
    const printableContent = (
            <div>
                {currentItems?.length && (
                    <div style={{fontSize:'10px'}}>
                        {currentItems?.map((r, i) => (
                            <Alert variant=""  key={i} className="print-page">
                            <Image  src={clipm} style={{ width: '10rem' }} /><br/>
                            {/* <div style={{ position: 'absolute', height: '10px', right: 100, top: 30 }}>
                            <Image src={clipm} style={{ width: '10rem'}} />
                            </div> */}
                            
                                <Row >
                                    <Col sm>
                                        <Table responsive bordered hover size="sm">
                                            <tbody>
                                                <tr>
                                                    <td className='p-2'>
                                                        Cebu Landmasters, Inc-Water<br/>
                                                        10th Floor Park Centrale Tower, JM Del Mar St. Cebu IT Park,
                                                        Apas, Cebu City<br/>
                                                        Tel. No. 032-231-4914 Fax No. 032-231-5073<br/>
                                                        VAT Reg TIN: 227-599-320-000
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col sm={4}>
                                    <br/><br/>
                                        <h4 className="text-center align-middle">Water Bill</h4>
                                    </Col>
                                    
                                    <Col sm={4}>
                                    <br/><br/>
                                        <h4  className="text-center align-middle ps-4"><strong>Statement</strong></h4>
                                        <div className='text-center ps-4'>
                                            <Barcode value={r.water_no.toString()} width={2} height={30} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={2}>
                                        Account # : 
                                    </Col>
                                    <Col sm>
                                        <strong>{r.account}</strong>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={2}>
                                        Name : 
                                    </Col>
                                    <Col sm>
                                        <strong>{r.fname} {r.mi}. {r.lname}</strong>
                                    </Col>
                                    <Col sm></Col>
                                    <Col sm>
                                        <Row>
                                            <Col sm>
                                                Due Date :
                                            </Col>
                                            <Col sm>
                                                <strong className="text-danger"> {new Date(new Date(r.cdate).getTime() + 15 * 24 * 60 * 60 * 1000).toLocaleDateString("en-PH")}</strong>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={2}>
                                        Address: 
                                    </Col>
                                    <Col sm>
                                        <strong>Phase {r.phase} Block {r.block} Lot {r.lot}</strong>
                                    </Col>
                                    <Col sm></Col>
                                    <Col sm>
                                        <Row>
                                            <Col sm>
                                                Generated : 
                                            </Col>
                                            <Col sm>
                                                <strong> {(new Date(r.cdate)).toLocaleDateString("en-PH")}</strong>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Table responsive bordered hover size="sm" className='mt-3'>
                                    <thead>
                                        <tr className="text-center align-middle">
                                            <th>Date</th>
                                            <th>Transaction</th>
                                            <th>Amount</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="text-center align-middle">
                                            <td></td>
                                            <td>Balance Forwarded</td>
                                            <td></td>
                                            <td>
                                                {userData?.map((j,k)=>(
                                                    <div key={k}>{j.account==r.account?
                                                        <div>{(j.water_balance - r.total_amount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
                                                        :''}
                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                        <tr className="text-center align-middle">
                                            <td >{(new Date(r.cdate)).toLocaleDateString("en-PH")}</td>
                                            <td className='text-start ps-5' >
                                                Water Bill #: {r.water_no} <br/>
                                                Date Coverage : {(new Date(r.date_from)).toLocaleDateString("en-PH")} - {(new Date(r.date_to)).toLocaleDateString("en-PH")}<br/>
                                                {/* Association Due : Lot Area {r.lot_area.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})} @ PHP {(r.dues).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})} <br/> */}
                                                Previous Reading : {r.previous}<br/>
                                                Current Reading : {r.present}<br/>
                                                Computation : {parseInt(r.present) - parseInt(r.previous)} cubic water @ PHP {r.water_rate.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})} <br/>
                                                System Charge : {(r.charge).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}<br/>
                                                Discount : {r.discount.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})} <br/>
                                            </td>
                                            <td >{(r.total_amount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                                            <td>
                                                {userData?.map((j,k)=>(
                                                    <div key={k}>{j.account==r.account?
                                                        <div>{(j.water_balance).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
                                                        :''}
                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>

                                {userData?.map((j,k)=>(
                                    <div key={k}>{j.account==r.account?
                                        <div>
                                            <Table responsive bordered hover size="sm" className='mt-1'>
                                                <thead>
                                                    <tr className="text-center align-middle">
                                                        <th>0 - 30</th>
                                                        <th>31 - 60</th>
                                                        <th>61 - 90</th>
                                                        <th>Over 90</th>
                                                        <th>Amount Due</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="text-center align-middle">
                                                        <td >
                                                            {waterAllData?.filter(item => item.account == j.account)
                                                                .reduce((prev, current) => {
                                                                const daysDiff = Math.ceil((new Date().getTime() - new Date(current.cdate).getTime()) / 86400000);
                                                                    if (daysDiff <= 30) {
                                                                        return prev + current.balance;
                                                                    }
                                                                return prev;
                                                            }, 0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        </td>
                                                        <td >
                                                            {waterAllData?.filter(item => item.account == j.account)
                                                                .reduce((prev, current) => {
                                                                const daysDiff = Math.ceil((new Date().getTime() - new Date(current.cdate).getTime()) / 86400000);
                                                                    if ((daysDiff >= 31)&&(daysDiff <= 60)) {
                                                                        return prev + current.balance;
                                                                    }
                                                                return prev;
                                                            }, 0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        </td>
                                                        <td >
                                                            {waterAllData?.filter(item => item.account == j.account)
                                                                .reduce((prev, current) => {
                                                                const daysDiff = Math.ceil((new Date().getTime() - new Date(current.cdate).getTime()) / 86400000);
                                                                    if ((daysDiff >= 61)&&(daysDiff <= 90)) {
                                                                        return prev + current.balance;
                                                                    }
                                                                return prev;
                                                            }, 0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        </td>
                                                        <td >
                                                            {waterAllData?.filter(item => item.account == j.account)
                                                                .reduce((prev, current) => {
                                                                const daysDiff = Math.ceil((new Date().getTime() - new Date(current.cdate).getTime()) / 86400000);
                                                                    if (daysDiff >= 91) {
                                                                        return prev + current.balance;
                                                                    }
                                                                return prev;
                                                            }, 0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        </td>
                                                        <td >
                                                            {waterAllData?.filter(item => item.account == j.account)
                                                                .reduce((prev, current) => prev + current.balance, 0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        :''}
                                    </div>
                                ))}

                                <Row className='mt-1'>
                                    <Col sm>   
                                        <Table responsive bordered hover size="sm">
                                            <thead>
                                                <tr className="text-center">
                                                    <th colSpan={2}>PAYMENT METHOD</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="text-center">
                                                    <td colSpan={2}>Collection Schedule: Monday - Saturday 8:30AM - 5:00PM</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        PAYMENT OPTION<br/>
                                                        1. Cash - Casa Mira Linao/ Head Office at Park Centrale<br/>
                                                        2. Bills Payment - Over the counter at BPI Branch<br/>
                                                        3. BPI Online Banking<br/>
                                                        Bank: Bank of the Philippine Islands<br/>
                                                        Account Name: Cebu Landmasters, Inc - CEBWTR<br/>
                                                        Reference No. / Contract No.<br/>
                                                        4. Other Banks with Bills Payment: BDO & RCBC<br/><br/>

                                                        You can also pay thru GCash<br/>
                                                        1. Bills <br/>
                                                        2. Real Estate<br/>
                                                        3. Cebu Landmasters Inc.<br/>
                                                        4. Casa Mira Linao<br/>
                                                        5. Fill-up account details<br/>
                                                    </td>
                                                    <td>
                                                        Casa Mira Linao Admin Office<br/>
                                                        Office Schedule: Monday - Saturday<br/>
                                                        Office Hours: 8:30AM-12NN to 1:00PM-5:30PM<br/><br/>

                                                        To ensure posting of your online payments please email proof of<br/>
                                                        SUCCESSFUL PAYMENTS to:<br/>
                                                        gbbacor@clipm.com.ph 0917-829-3019<br/>
                                                        For your other concerns and queries you can contact our<br/> Property Manager:<br/>
                                                        Engr<br/>
                                                        Email:<br/>
                                                        Mobile:<br/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Prepared By :<br/>
                                                        {adminData?.map((j,k)=>(
                                                            <div key={k} className='text-center'>{j.id==r.accounting_id?
                                                                <div>{j.fname} {j.mi}. {j.lname}<br/>Finance Associate</div>
                                                                :''}
                                                            </div>
                                                        ))}
                                                    </td>
                                                    <td>
                                                        Checked By :<br/>
                                                        {adminData?.map((j,k)=>(
                                                            <div key={k} className='text-center'>{j.id==r.manager_id?
                                                                <div>{j.fname} {j.mi}. {j.lname}<br/>Property Manager</div>
                                                                :''}
                                                            </div>
                                                        ))}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row className='mt-3 text-primary'>
                                    <Col sm>
                                        Note: Please report your payment by providing your reference # in report payment proecdure for verification.
                                    </Col>
                                </Row>
                            </Alert>
                            ))}
                        </div>
                )}
                <style>
                {`
                    @media print {
                        .print-page {
                            page-break-before: always;
                        }
                        body {
                            margin: 0;
                        }
                    }
                `}
                </style>
            </div>
        )
    

    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = formatDate(currentDate);
        setSearchTermDate(formattedDate);
      }, []); 
    
      const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const day = date.getDate().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}`;
      };

    const fetchUser = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}admin_get_user`, { headers, params: { search: searchTerm } });
            setUserData(res.data.length > 0 ? res.data : null);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [searchTerm, token]);

    // const sum = userData?.reduce((total, currentValue) => total = total + currentValue.association_balance,0);
    // console.log(sum);

    // ***** fetch all Association Bill by date *****
    const fetchAssociation = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<WaterData[]> = await axios.get(`${apiUrl.url}admin_get_water`, { headers, params: { search: searchTermDate, page: page, pageSize: pageSize } });
            setWaterData(res.data.length > 0 ? res.data : null);
            setFilteredWaterData(res.data);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [searchTermDate, token, page]);

    // ***** fetch all Association Bill with balance to print in days *****
    const fetchAllAssociation = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<WaterData[]> = await axios.get(`${apiUrl.url}admin_get_water/all`, { headers });
            setAllWaterData(res.data);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [token]);

    // ***** fetch all Management Users *****
    const fetchAdminUser = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<AdminData[]> = await axios.get(`${apiUrl.url}admin_get_admin_user`, { headers, params: { search: searchTerm } });
            setAdminData(res.data.length > 0 ? res.data : null);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [searchTerm, token]);


    const fetchSystemData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<SystemData[]> = await axios.post(`${apiUrl.url}admin_get_system_account/`, { id: id }, { headers });
            setSystemData(res.data);
        } catch (error) {
            console.error('Error: ', error);
            return false;
        }
    }, [id, token]);


    // ****** Download Template ******
    const downloadExcel = async () => {
        try {
          const response = await axios.get(`${apiUrl.url}admin_water_template_download`, {
            headers: {
                Authorization: `${token}`,
            },
            responseType: 'blob',
          });
    
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'Water_template.xlsx';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading Excel file:', error);
        }
      };


      const [tableData, setTableData] = useState<any[][]>([]);

      const readExcelFile = (file: File): Promise<any[][]> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
    
          reader.onload = (event) => {
            try {
              const data = new Uint8Array(event.target?.result as ArrayBuffer);
              const workbook = XLSX.read(data, { type: 'array' });
              const sheetName = workbook.SheetNames[0];
              const sheet = workbook.Sheets[sheetName];
              const jsonData: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
              resolve(jsonData);
            } catch (error) {
              reject(error);
            }
          };
    
          reader.readAsArrayBuffer(file);
        });
      };
    
      const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
          try {
            const data = await readExcelFile(file);
            setTableData(data);
            // onFileChange(data);
          } catch (error) {
            console.error('Error reading Excel file:', error);
          }
        }
      };


    useEffect(() => {
        fetchSystemData();
        fetchUser();
        fetchAdminUser();
        fetchAssociation();
        fetchAllAssociation();
    }, [ fetchSystemData, fetchUser, fetchAdminUser, fetchAssociation, fetchAllAssociation ]);

    const handleDateChange = (event:any) => {
        const newDate = event.target.value;
        setSearchTermDate(newDate)
      };

    const prevPage = () => {
        setPage((prevPage) => prevPage - 1)
    }

    const nextPage = () => {
        setPage((prevPage) => prevPage + 1)
    }


    const handleSearch = (values: { search: string }) => {
        const searchTerm = values.search.toLowerCase();
        if (WaterData) {
          const filteredData = WaterData.filter((item) =>
            item.water_no.toString().includes(searchTerm) ||
            item.account.toString().includes(searchTerm) ||
            item.fname.toLowerCase().includes(searchTerm) ||
            item.lname.toLowerCase().includes(searchTerm)
          );
            setFilteredWaterData(filteredData);
            setCurrentPage(1);
        }
      };
    
   
    const handleSubmit = (values: any) => {
        if (systemData && tableData) {
            tableData.slice(1).forEach((row, rowIndex) => {
              
                const systemChargeAmount = Number(systemData[0]?.charge_amount) || 0;
                const waterRate = Number(systemData[0]?.water_rate) || 0;
    
                const payload = {
                    water_no: systemData[0]?.water_no + rowIndex + 1,
                    user_id: row[0],
                    account: row[1],
                    fname: row[2],
                    mi: row[3],
                    lname: row[4],
                    phase: row[5],
                    block: row[6],
                    lot: row[7],
                    // email: r.email,
                    // cellphone: r.cellphone,
                    previous: row[8],
                    present: row[9],
                    consumed: row[9] - row[8],
                    water_rate: waterRate,
                    charge: systemChargeAmount,
                    date_from: values.fdate,
                    date_to: values.tdate,
                    cdate: values.cdate,
                    total_amount: row[9] - row[8] > 10 ? 
                    (systemData && systemData.length > 0 ? systemData[0]?.water_rate * (row[9] - row[8]) + systemData[0]?.charge_amount : 0)
                    :
                    (systemData && systemData.length > 0 ? systemData[0]?.water_rate * 10 + systemData[0]?.charge_amount : 0),
                    accounting_id: values.accounting,
                    manager_id: values.manager,
                };
                dispatch(waterRequest(payload));
                // console.log(payload)
            });
        } else {
            console.error("systemData is null");
        }
    };

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ 
                fdate: "",
                tdate: "",
                cdate: searchTermDate,
                accounting: "",
                manager: "",
                confirm: false,
            }}
            validationSchema={CreateSchema}
            onSubmit={handleSubmit}
            >
            <div className="container-fluid ">
                {loading?<LoadingPage/>:""}
                {failed?<CreateWaterFailed/>:""}
                {success?<CreateWaterSuccess/>:""}
                {water_create != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-info" ><FaWater size="30" /> Generate Water Bill</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_water_menu')}>Water Menu</Breadcrumb.Item>
                                <Breadcrumb.Item active>Generate Bill</Breadcrumb.Item>
                            </Breadcrumb>
                            <hr/>
                        <div style = {{ width:"400px"}} />
                        Generate Water Bill for all user accounts via uploading excel file.
                        <Alert style={{border: "1px solid rgb(128, 128, 128)", backgroundColor: '#d4fcfc'}} className='mt-2 shadow-lg'>
                            <Alert style={{border: "1px solid rgb(128, 128, 128)"}} className='bg-white'>
                                Download Water Bill Template
                                <Row>
                                    <Col sm={4} className='p-2 text-center'>
                                        <RiFileExcel2Line size='40' className='text-success' />
                                        &nbsp; Water_Template.xlsx
                                    </Col>
                                    <Col sm={3} className='p-2'>
                                        <div className="d-grid gap-2">
                                            <Button onClick={downloadExcel} className="w-100 btn btn-warning btn-block rounded-pill"><FiDownloadCloud size="20"/> Download Template</Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={7} className='p-2'>
                                        <input type="file" className="w-100 form-control" accept=".xlsx" onChange={handleFileChange} />
                                    </Col>
                                    <Col sm className='pt-3'>
                                        {/* <div className="d-grid gap-2"> */}
                                        Select user template to display in the table.
                                            {/* <Button onClick={handleUpload} className="w-100 btn btn-primary btn-block rounded-pill"><FiUploadCloud size="20"/> Upload Template</Button> */}
                                        {/* </div> */}
                                    </Col>
                                </Row>
                            </Alert>
                        <Form>
                            Coverage Date - {`[ Date of the Bill, From, and To ]`}
                            <Row className="mt-1">
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Date <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="date" 
                                            name="cdate"
                                            className="w-75 form-control text-center"
                                            onChange={handleDateChange}
                                        />
                                        <ErrorMessage name="cdate">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                From <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="date" 
                                            name="fdate"
                                            className="w-75 form-control text-center"
                                        />
                                        <ErrorMessage name="fdate">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                To <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="date" 
                                            name="tdate" 
                                            className="w-75 form-control text-center"
                                        />
                                        <ErrorMessage name="tdate">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Accounting <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="accounting"
                                                className="w-75 form-control"
                                            >
                                                <option value="">- Select Option -</option>
                                                {adminData?.map((r, i) => (
                                                    r.auth === 'accnt' && (
                                                        <option key={i} value={r.id}>
                                                            {r.fname} {r.mi} {r.lname}
                                                        </option>
                                                    )
                                                ))}
                                            </Field>
                                        <ErrorMessage name="accounting">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Manager <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="manager"
                                                className="w-75 form-control"
                                            >
                                                <option value="">- Select Option -</option>
                                                {adminData?.map((r, i) => (
                                                    r.auth === 'manager' && (
                                                        <option key={i} value={r.id}>
                                                            {r.fname} {r.mi} {r.lname}
                                                        </option>
                                                    )                                            
                                                ))}
                                            </Field>
                                        <ErrorMessage name="manager">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <br/>
                                <Col sm> 
                                    <label>
                                        <Field type="checkbox" name="confirm" />
                                        &nbsp;Please confirm to generate bill.
                                    </label>
                                    <ErrorMessage name="confirm">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            <br/>
                            <div className="d-grid gap-2">
                                <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-3" >
                                    <AiOutlineSend size="20"/> Generate
                                </Button>
                            </div>
                        </Form>
                        </Alert>

                        <hr className="mt-3" />
                        <h4 className="text-info mt-4" ><AiOutlineBars size="30" /> Generated Bill List</h4>

                        <Formik
                            enableReinitialize={true}
                            initialValues={{ 
                                search: "",
                            }}
                            validationSchema={SearchSchema}
                            onSubmit={handleSearch}
                            >
                                <Form>
                                    <Row>
                                        <Col xs lg="6"  className="bg-white rounded">
                                            <InputGroup className=" mb-3">
                                                    <Field 
                                                        type="search" 
                                                        name="search"
                                                        placeholder="&#x1F50E; Search..."
                                                        maxLength="50"
                                                        className="w-50 form-control rounded-pill"
                                                        style={{border: "1px solid rgb(128, 128, 128)"}}
                                                    >
                                                    </Field>
                                                    <Button type="submit" variant="outline-success" style={{display:'none'}}><BiSearchAlt size="20" className='ms-2 me-2'/></Button>
                                                <ErrorMessage name="search">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col lg="6" className=''>
                                            {/* <Button variant="secondary" type="submit" className="btn btn-primary btn-block rounded-pill mb-3" >
                                                <FaPrint size="20"/> Print All
                                            </Button> */}
                                             <div className="btn-toolbar mb-2 mb-md-0 float-end">
                                                <div className="btn-group mr-2">
                                                    
                                                    <ReactToPrint
                                                        trigger={() => <button type="button" onClick={handlePrint}  className="btn btn-md btn-outline-success"><FaPrint size="20"/> Print</button>}
                                                        content={() => printAllRef.current}
                                                    />
                                                    <ReactToPrint
                                                        trigger={() => <button type="button" onClick={handlePrintList} className="btn btn-md btn-outline-success"><TfiPrinter size="20"/> Print List</button>}
                                                        content={() => componentRef.current}
                                                    />
                                                    <button type="button" onClick={exportToExcel} className="btn btn-md btn-outline-success"><RiFileExcel2Fill size="20"/> Export to Excel</button>
                                                    <CSVLink data={formattedData} headers={headers} filename={"association_bill.csv"}
                                                        className="btn btn-md btn-outline-success"
                                                        target="_blank">
                                                            <FaFileCsv size="20"/> Export to CSV
                                                    </CSVLink>
                                                </div>
                                                    <div style={{ display: 'none' }}>
                                                        <div ref={printAllRef} >
                                                            {isPrinting ? null : printableContent}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'none' }}>
                                                        <div ref={componentRef} >
                                                            {isPrintingList ? null : printableContentList}
                                                        </div>
                                                    </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </Formik>

                        <Table responsive striped bordered hover size="sm">
                            <thead>
                                <tr className="text-center">
                                    <th>#</th>
                                    <th>Bill #</th>
                                    <th>Account #</th>
                                    <th>Name</th>
                                    <th>Phase</th>
                                    <th>Block</th>
                                    <th>Lot</th>
                                    <th>Previous</th>
                                    <th>Present</th>
                                    <th>Consumed</th>
                                    <th>Rate</th>
                                    <th>Charge</th>
                                    <th>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            
                                </tbody>
                                
                                {currentItems?.length!=0? (
                                    <tbody>
                                        {currentItems?.map((r, i) => (
                                        <tr key={i} className="align-middle text-secondary">
                                            <td className="text-center align-middle">{i + 1}</td>
                                            {/* <td className="text-center align-middle">{(new Date(r.cdate)).toLocaleDateString("en-PH")}</td> */}
                                            <td className="text-center align-middle">{r.water_no}</td>
                                            <td className="text-center align-middle">{r.account}</td>
                                            <td className="">{r.fname} {r.mi}. {r.lname}</td>
                                            <td className="text-center align-middle">{r.phase}</td>
                                            <td className="text-center align-middle">{r.block}</td>
                                            <td className="text-center align-middle">{r.lot}</td>
                                            <td className="text-center align-middle">{r.previous}</td>
                                            <td className="text-center align-middle">{r.present}</td>
                                            <td className="text-center align-middle">{r.consumed}</td>
                                            <td className="text-center align-middle">{r.water_rate}</td>
                                            <td className="text-center align-middle">{r.charge}</td>
                                            <td className="text-end">
                                                {(r.total_amount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        {tableData.slice(1).map((row, rowIndex) => (
                                            
                                            <tr key={rowIndex} className="align-middle text-secondary">
                                                <td className="text-center align-middle">{rowIndex + 1}</td>
                                                {/* {row.map((cell, cellIndex) => (
                                                    <td key={cellIndex} className="text-center align-middle">{cell}</td>
                                                ))} */}
                                                <td className="text-center align-middle">
                                                    {systemData && systemData.length > 0 ? systemData[0]?.water_no + rowIndex + 1 : "N/A"}
                                                </td>
                                                <td className="text-center align-middle">{row[1]}</td>
                                                <td className="">{row[2]} {row[3]}. {row[4]}</td>
                                                <td className="text-center align-middle">{row[5]}</td>
                                                <td className="text-center align-middle">{row[6]}</td>
                                                <td className="text-center align-middle">{row[7]}</td>
                                                <td className="text-center align-middle">{row[8]}</td>
                                                <td className="text-center align-middle">{row[9]}</td>
                                                <td className="text-center align-middle">{row[9] - row[8]}</td>
                                                <td className="text-center align-middle">
                                                    {systemData && systemData.length > 0 ? systemData[0]?.water_rate : "N/A"}
                                                </td>
                                                <td className="text-center align-middle">
                                                    {systemData && systemData.length > 0 ? systemData[0]?.charge_amount : "N/A"}
                                                </td>
                                                <td className="text-center align-middle">
                                                    {row[9] - row[8] > 10 ? 
                                                    (systemData && systemData.length > 0 ? systemData[0]?.water_rate * (row[9] - row[8]) + systemData[0]?.charge_amount : "N/A")
                                                    :
                                                    (systemData && systemData.length > 0 ? systemData[0]?.water_rate * 10 + systemData[0]?.charge_amount : "N/A")}
                                                </td>
                                            </tr>
                                        
                                        ))}
                                    </tbody>
                                )}
                        </Table>
                        <Pagination>
                            <Pagination.Prev onClick={prevPage} disabled={page === 1} />
                            <Pagination.Item>Page {page}</Pagination.Item>
                            <Pagination.Next onClick={nextPage} />
                        </Pagination>

                        {/* {filteredWaterData?.length && filteredWaterData.length > ITEMS_PER_PAGE && (
                                <Pagination>
                                    <Pagination.First onClick={() => paginate(1)} />
                                    <Pagination.Prev onClick={() => paginate(currentPage - 1)} />

                                    {Array.from({ length: totalPages }).map((_, index) => {
                                        if (index >= currentPage - 2 && index <= currentPage + 2) {
                                        return (
                                            <Pagination.Item
                                            key={index}
                                            active={index + 1 === currentPage}
                                            onClick={() => paginate(index + 1)}
                                            >
                                            {index + 1}
                                            </Pagination.Item>
                                        );
                                        }

                                        return null;
                                    })}

                                    {currentPage + 2 < totalPages && (
                                        <Pagination.Ellipsis key={`ellipsis`} />
                                    )}

                                    <Pagination.Next onClick={() => paginate(currentPage + 1)} />
                                    <Pagination.Last onClick={() => paginate(totalPages)} />
                                </Pagination>
                            )} */}
                    </Col>
                </Row>
            </div>
        </Formik>
    );
};

export default Water_Bill;