import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    InputGroup,
    Button,
    Alert,
    Breadcrumb,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AiOutlineSend, AiOutlineForm, AiOutlineCheckCircle , AiOutlineCloseCircle, AiOutlineDelete } from "react-icons/ai";
import SubMenu from '../submenu';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import { updateRequest } from './redux/adminUserActions';
import { LoadingPage, CreateUserSuccess, CreateUserFailed, AuthAccessFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import apiUrl from '../../../components/apiurl';
import { getSessionVariable } from '../../../components/sessionStorage';
import { setSessionVariable } from '../../../components/sessionStorage';

const CreateSchema = Yup.object().shape({
    account: Yup.string()
        .max(10, 'Must be 10 characters or less')
        .required("Account # is required"),
    fname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Firstname is required"),
    mi: Yup.string()
        .trim()
        .max(1, 'Must be 1 character only')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only"),
    lname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Lastname is required."),
    phase: Yup.string()
        .trim()
        .max(5, 'Must be 5 characters or less')
        .matches(/^([a-z\s A-Z\s 0-9])+$/, "Please input valid cellphone # ex: 09XXXXXXXX.")
        .required("Phase is required."),
    block: Yup.string()
        .trim()
        .max(5, 'Must be 5 characters or less')
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Block number is required."),
    lot: Yup.string()
        .trim()
        .max(5, 'Must be 5 characters or less')
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Lot number is required."),
    lotarea: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .required("Lot area is required."),
    floorarea: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .required("Floor area is required."),
    dues: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .required("Monthly Dues is required."),
    // security: Yup.string()
    //     .trim()
    //     .max(10, 'Must be 10 characters or less')
    //     .required("Security question is required"),
    // answer: Yup.string()
    //     .trim()
    //     .max(30, 'Must be 30 characters or less')
    //     .required("Answer is required"),
    // cellphone: Yup.string()
    //     .trim()
    //     .max(11, 'Must be 11 character only')
    //     .matches(/^[0-9]{11}$/, "Please input valid cellphone # ex: 09XXXXXXXX.")
    //     .required("Cellphone number is required."),
    // email: Yup.string()
    //     .trim()
    //     .max(50, 'Must be 50 character only')
    //     .email("Enter a valid email.")
    //     .required("Email address is required."),
        // .test('email-exists', 'Email already exists', async function (value) {
        //     try {
        //         const token = getLocalStorageVariable<string>('token');
        //         const headers = {
        //             Authorization: `${token}`,
        //         };

        //         const response: AxiosResponse = await axios.post(`${apiUrl.url}check_email/`, {email: value});
        //             if (response.data.msg) {
        //                 return false; // Email is considered as already taken
        //             }
        //         return !response.data.exists;
        //     } catch (error) {
        //       console.error('Error checking email duplication:', error);
        //       return false;
        //     }
        //   }),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });


const Update_User: React.FC = () =>{
    const failed = useSelector((state: RootState) => state.Admin_CreateUserReducer.error);
    const loading = useSelector((state: RootState) => state.Admin_CreateUserReducer.loading);
    const success = useSelector((state: RootState) => state.Admin_CreateUserReducer.info);
    const token = getLocalStorageVariable<string>('token');
    const user_update = getLocalStorageVariable<string>('user_update');

    const account = getSessionVariable<any>('account');
    const fname = getSessionVariable<any>('fname');
    const mi = getSessionVariable<any>('mi');
    const lname = getSessionVariable<any>('lname');
    const phase = getSessionVariable<any>('phase');
    const block = getSessionVariable<any>('block');
    const lot = getSessionVariable<any>('lot');
    const lotarea = getSessionVariable<any>('lotarea');
    const floorarea = getSessionVariable<any>('floorarea');
    const dues = getSessionVariable<any>('dues');
    const status = getSessionVariable<any>('status');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!account || !token) {
            navigate('/admin_update_user_list'); 
        }
    }, [ navigate, account, token ]);

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };
   
    const handleSubmit = (values: any) => {
        const payload = {
            account: values.account,
            fname: values.fname,
            mi: values.mi,
            lname: values.lname,
            lotarea: values.lotarea,
            floorarea: values.floorarea,
            dues: values.dues,
            security: values.security,
            answer: values.answer,
            cellphone: values.cellphone,
            email: values.email,
        }
        dispatch(updateRequest(payload));
      };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ 
                account: account || '',
                fname: fname || '',
                mi: mi || '',
                lname: lname || '',
                phase: phase || '',
                block: block || '',
                lot: lot || '',
                lotarea: lotarea || '',
                floorarea: floorarea || '',
                dues: dues || '',
                confirm: false,
            }}
            validationSchema={CreateSchema}
            onSubmit={handleSubmit}
            >
            <div className="container-fluid ">
                {loading?<LoadingPage/>:""}
                {failed?<CreateUserFailed/>:""}
                {success?<CreateUserSuccess/>:""}
                {user_update != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-primary mb-3" ><AiOutlineForm size="30" /> Update User Account</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_user_menu')}>User Menu</Breadcrumb.Item>
                                <Breadcrumb.Item href="/admin_update_user_list">Update User List</Breadcrumb.Item>
                                <Breadcrumb.Item active>Update User</Breadcrumb.Item>
                            </Breadcrumb>
                            
                        <hr/>
                        <div style = {{ width:"400px"}} />
                        Updating user account
                        <Alert style={{border: "1px solid rgb(128, 128, 128)", backgroundColor:'#c9dbff'}} className='mt-2 shadow-lg'>
                        <Form>
                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Account # 
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="account" 
                                            maxLength="10"
                                            placeholder="Account Number"
                                            className="w-75 form-control"
                                            disabled={true}
                                        />
                                        <ErrorMessage name="account">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>
                                    {status==1?
                                        <h4 className="text-success mt-1 mb-1" ><strong><AiOutlineCheckCircle  size="25" /> Active</strong></h4>:
                                        <h4 className="text-danger mt-1 mb-1" ><strong><AiOutlineCloseCircle size="25" /> Inactive</strong></h4>}
                                </Col>
                                <Col sm>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Phase
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="phase" 
                                            maxLength="5"
                                            placeholder="Phase"
                                            className="w-75 form-control"
                                            disabled={true}
                                        />
                                        <ErrorMessage name="phase">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Block
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="block" 
                                            maxLength="5"
                                            placeholder="Block"
                                            className="w-75 form-control"
                                            disabled={true}
                                        />
                                        <ErrorMessage name="block">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Lot
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="lot" 
                                            maxLength="5"
                                            placeholder="Lot"
                                            className="w-75 form-control"
                                            disabled={true}
                                        />
                                        <ErrorMessage name="lot">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Firstname <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="fname"
                                            maxLength="30"
                                            placeholder="Firstname"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="fname">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                MI
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="mi" 
                                            maxLength="1"
                                            placeholder="MI"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="mi">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Lastname <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="lname" 
                                            maxLength="30"
                                            placeholder="Lastname"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="lname">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Lot Area <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="lotarea" 
                                            maxLength="10"
                                            placeholder="Lot Area"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="lotarea">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Floor Area <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="floorarea" 
                                            maxLength="10"
                                            placeholder="Floor Area"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="floorarea">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Dues <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="dues" 
                                            maxLength="10"
                                            placeholder="Dues"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="dues">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Security <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="security" 
                                                maxLength="10"
                                                placeholder="Account Number"
                                                className="w-75 form-control"
                                            >
                                                <option value="">- Security Question -</option>
                                                <option value="city">In what city were you born?</option>
                                                <option value="pet">What is the name of your favorite pet?</option>
                                                <option value="maiden">What is your mother's maiden name?</option>
                                                <option value="high">What high school did you attend?</option>
                                                <option value="elementary">What was the name of your elementary school?</option>
                                                <option value="car">What was the make of your first car?</option>
                                                <option value="favorite">What was your favorite food as a child?</option>
                                                <option value="spouse">Where did you meet your spouse?</option>
                                                <option value="born">What year was your father or mother born?</option>
                                            </Field>
                                        <ErrorMessage name="security">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Answer <strong className='text-danger'>&nbsp;*</strong>
                                        </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="answer" 
                                            maxLength="30"
                                            placeholder="Answer"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="answer">
                                        {msg => <div style={{color:'red',padding:'5px', margin:'3px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>  

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Cellphone <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="cellphone" 
                                            maxLength="11"
                                            placeholder="09XXXXXXXXX"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="cellphone">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Email <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="email" 
                                            name="email" 
                                            maxLength="50"
                                            placeholder="Email"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="email">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row> */}
                            <br/>
                                <Col sm> 
                                    <label>
                                        <Field type="checkbox" name="confirm" />
                                        &nbsp;Please confirm for updating the account.
                                    </label>
                                    <ErrorMessage name="confirm">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            <br/>
                            <div className="d-grid gap-2">
                                <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-3" >
                                    <AiOutlineSend size="20"/> Submit
                                </Button>
                            </div>
                        </Form>
                        </Alert>
                    </Col>
                </Row>
            </div>
        </Formik>
    );
};

export default Update_User;