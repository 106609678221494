import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { 
  ActionTypes, 
  createSuccess, 
  createFailure, 
  redirect,
  updateSuccess,
  updateFailure,
  updateRedirect,
} from './adminUserActions';
import apiUrl from '../../../../components/apiurl';
import axios from 'axios';
import { getLocalStorageVariable } from '../../../../components/localStorage';

// ***** Create User *****
function* adminCreateUser(action: any): Generator<any, void, AxiosResponse> {
  try {
    const token = getLocalStorageVariable<string>('token');
    const headers = {
      Authorization: `${token}`,
    };

    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_create_user`, action.payload, { headers });
    const user = response.data;

      if(user!=''){
        yield put(createSuccess(user));
        yield put(redirect('/admin_create_user'));
        // console.log(user)
      }else{
        yield put(createFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(createFailure(errorMessage));
  }
}

// ***** Update User *****
function* adminUpdateUser(action: any): Generator<any, void, AxiosResponse> {
  try {
    const token = getLocalStorageVariable<string>('token');
    const headers = {
      Authorization: `${token}`,
    };

    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_update_user`, action.payload, { headers });
    const user = response.data;

      if(user!=''){
        yield put(updateSuccess(user));
        yield put(updateRedirect('/admin_update_user_list'));

          // const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}send_email`, {
          // // const response = await axios.post(`${apiUrl.url}send_email`, {
          //   to: action.payload.email,
          //   subject: 'User Update Notification',
          //   html: `<div style="font-family:Arial, Helvetica, sans-serif; font-size: 16px;">
          //   <div style="background-color: #00c04b; color: white; padding: 5px; border: none; width: 95%; text-align: center;">
          //     <h2>Casa Mira - Linao</h2>
          //     <h4>User Account Update</h4>
          //   </div>
          //   <div style="padding: 30px;">
          //       Hello ${action.payload.fname},
          //     <br/>
          //     <br/>
          //       Your account information updated succesfully.<br/>Please double check if you made this transaction.
          //       <br/><br/>
          //       Best regards,<br/>
          //       Casa Mira - Linao Application Team
          //   </div>
          // </div>`,
          // }, { headers });

          // if (response.status === 200) {
          //   console.log('Email sent successfully');
          // } else {
          //   console.error('Failed to send email');
          // }
          
      }else{
        yield put(updateFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(updateFailure(errorMessage));
  }
}

function* watchAdminCreateUser() {
  yield takeLatest(ActionTypes.ADMIN_CREATE_USER_REQUEST, adminCreateUser);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_USER_REQUEST, adminUpdateUser);
}

export default watchAdminCreateUser;
