import React, { useEffect, useState, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { getLocalStorageVariable } from '../../../components/localStorage';
import apiUrl from '../../../components/apiurl';

interface DataItem {
  date: string;
  payment: number;
  bill: number;
  amt: number;
}

interface WaterData {
  water_no: number;
  account: number;
  fname: string;
  mi: string;
  lname: string;
  phase: string;
  block: string;
  lot: string;
  charge: number;
  cdate: string;
  date_from: string;
  date_to: string;
  total_amount: number;
  balance: number;
  paid: number;
  accounting_id: number;
  manager_id: number;
  discount: number;
}

interface WaterBarChartProps {}

const WATER_BARCHART: React.FC<WaterBarChartProps> = () => {
  const token = getLocalStorageVariable<string>('token');
  const [associationAllData, setAllWaterData] = useState<WaterData[] | null>(null);

  const fetchAllAssociation = useCallback(async () => {
    const headers = {
      Authorization: `${token}`,
    };
    try {
      const res: AxiosResponse<WaterData[]> = await axios.get(`${apiUrl.url}dashboard_admin_get_water`, { headers });
      setAllWaterData(res.data);
    } catch (error) {
      console.error('Error: ', error);
    }
  }, [token]);

  useEffect(() => {
    fetchAllAssociation();
  }, [fetchAllAssociation]);

  // const filterDataByMonth = (month: number): WaterData[] => {
  //   return associationAllData?.filter((item) => new Date(item.cdate).getMonth() === month) || [];
  // };
  const filterDataByMonth = (year: number, month: number): WaterData[] => {
    return associationAllData?.filter(
      (item) =>
        new Date(item.cdate).getFullYear() === year &&
        new Date(item.cdate).getMonth() === month
    ) || [];
  };
  
  const calculateTotalAmount = (data: WaterData[]): number => {
    return data.reduce((total, item) => total + item.total_amount, 0);
  };

  const calculateTotalPayment = (data: WaterData[]): number => {
    return data.reduce((total, item) => total + item.paid, 0);
  };

// Inside the data mapping
const currentYear = new Date().getFullYear();
const previousYear = currentYear - 1;

const data: DataItem[] = Array.from({ length: 24 }, (_, index) => {
  const year = index < 12 ? currentYear : previousYear;
  const month = index % 12;
  const filteredData = filterDataByMonth(year, month);
  const totalAmount = calculateTotalAmount(filteredData);
  const totalPayment = calculateTotalPayment(filteredData);

  return {
    date: `${year}-${(month + 1).toString().padStart(2, '0')}`,
    bill: totalAmount,
    payment: totalPayment,
    amt: 0, // You may need to calculate this based on your requirements
  };
});

// Sort the data by date
data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());


  // const bill = associationAllData?.reduce((total, currentValue) => total = total + currentValue.total_amount,0);


  // const data: DataItem[] = [
  //   {
  //     date: '2000-01',
  //     payment: 4000,
  //     bill: 2000,
  //     amt: 2400,
  //   },
  //   // Add other data items as needed
  // ];


  const monthTickFormatter = (tick: string): string => {
    const date = new Date(tick);
    return (date.getMonth() + 1).toString();
  };

  const renderQuarterTick = (props: any): React.ReactElement | null => {
    const { x, y, payload } = props;
    const { value, offset } = payload;
    const date = new Date(value);
    const month = date.getMonth();
    const quarterNo = Math.floor(month / 3) + 1;
    const isMidMonth = month % 3 === 1;

      if (month % 3 === 1) {
        return <text x={x} y={y - 4} textAnchor="middle">{`Q${quarterNo}`}</text>;
      }

      const isLast = month === 12;

      if (month % 3 === 0 || isLast) {
        const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;

        return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
      }
    return null;
  };

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={monthTickFormatter} />
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            interval={0}
            tick={(props: any) => renderQuarterTick(props) as React.ReactElement<SVGElement, string | React.JSXElementConstructor<any>>}
            height={1}
            scale="band"
            xAxisId="quarter"
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="bill" fill="#8884d8" />
          <Bar dataKey="payment" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WATER_BARCHART;
