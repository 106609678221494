import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    InputGroup,
    Button,
    Table,
    Alert,
    Pagination,
    Modal,
    Image,
    Breadcrumb,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { AiOutlineSend, AiOutlineUserAdd, AiOutlineBars, AiOutlineEdit } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
import { BsQrCode } from "react-icons/bs";
import SubMenu from '../submenu';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import { createAdminUserRequest } from './redux/adminSettingsActions';
import { LoadingPage, CreateAdminUserSuccess, CreateUserFailed, AuthAccessFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import { setSessionVariable } from '../../../components/sessionStorage';
import apiUrl from '../../../components/apiurl';
import QRCode from 'qrcode.react';

declare const require: {
    context(directory: string, useSubdirectories?: boolean, regExp?: RegExp): {
      keys(): string[];
      <T>(id: string): T;
    };
  };
  const importAll = (context: any) => context.keys().map(context);
  const images = importAll(require.context('../../../images/avatar/', false, /\.(png|jpe?g|svg)$/));

  interface MyVerticallyCenteredModalProps {
    show: boolean;
    onHide: () => void;
    setAvatar: React.Dispatch<React.SetStateAction<string>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>; 
  }

  const MyVerticallyCenteredModal: React.FC<MyVerticallyCenteredModalProps> = ({ show, onHide, setAvatar, setShow }) => {
    const { setFieldValue } = useFormikContext();

    const handleImageClick = (src: string) => {
        setFieldValue('avatar', src);
        setAvatar(src);
        handleClose();
      };
    
      const handleClose = () => {
        setShow(false);
      };
    
      return (
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Select Avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <div>
            <div className="d-flex flex-wrap">
              {images.map((src: any, index: any) => (
                <div key={index} onClick={() => handleImageClick(src)}>
                  <div className="p-2">
                    <img src={src} alt={`Avatar ${index}`} width="60" height="60" style={{cursor:'pointer'}} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
const SearchSchema = Yup.object().shape({
    search: Yup.string()
        .trim(),
  });

const CreateSchema = Yup.object().shape({
    auth: Yup.string()
        .trim()
        .max(7, 'Must be 7 characters or less')
        .required("Access type is required"),
    fname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Firstname is required"),
    mi: Yup.string()
        .trim()
        .max(1, 'Must be 1 character only')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only"),
    lname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Lastname is required."),
    // security: Yup.string()
    //     .trim()
    //     .max(10, 'Must be 10 characters or less')
    //     .required("Security question is required"),
    // answer: Yup.string()
    //     .trim()
    //     .max(30, 'Must be 30 characters or less')
    //     .required("Answer is required"),
    cellphone: Yup.string()
        .trim()
        .max(11, 'Must be 11 character only')
        .matches(/^[0-9]{11}$/, "Please input valid cellphone # ex: 09XXXXXXXX.")
        .required("Cellphone number is required."),
    email: Yup.string()
        .trim()
        .max(50, 'Must be 50 character only')
        .email("Enter a valid email.")
        .required("Email address is required.")
        .test('email-exists', 'Email already exists', async function (value) {
            try {
                const token = getLocalStorageVariable<string>('token');
                const headers = {
                    Authorization: `${token}`,
                };
                const response: AxiosResponse = await axios.post(`${apiUrl.url}admin_check_email/`, {email: value},{ headers });
                    if (response.data.msg) {
                        return false; 
                    }
                return !response.data.exists;
            } catch (error) {
              console.error('Error checking email duplication:', error);
              return false;
            }
          }),
    avatar: Yup.string()
        .trim()
        .max(50, 'Must be 50 character only'),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });

  interface UserData {
    id: number;
    auth: string;
    fname: string;
    mi: string;
    lname: string;
    cellphone: string;
    email: string;
    secret_key: string;
    status: number;
  }

const ITEMS_PER_PAGE = 20;

const Create_Admin_User: React.FC = () =>{
    const failed = useSelector((state: RootState) => state.Admin_SettingsReducer.error);
    const loading = useSelector((state: RootState) => state.Admin_SettingsReducer.loading);
    const success = useSelector((state: RootState) => state.Admin_SettingsReducer.info);
    const settings_create = getLocalStorageVariable<string>('settings_create');
    const token = getLocalStorageVariable<string>('token');
    const [data, setData] = useState<UserData[] | null>(null);
    const [randomString, setRandomString] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [avatar, setAvatar] = useState('/static/media/default.d1c5ffe2b3cea9d80c7b.jpg');
    const [modalShow, setModalShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [qrCodeSecret, setqrCodeSecret] = useState<string>('');

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    const totalPages = Math.ceil((data?.length || 0) / ITEMS_PER_PAGE);
   

    const generateRandomString = (length: number): string => {
        const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let result = '';
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
        return result;
      };
    useEffect(() => {
        const generatedString = generateRandomString(6);
        setRandomString(generatedString);
    }, []);


    const fetchData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}admin_get_admin_user`, { headers, params: { search: searchTerm } });
            setData(res.data.length > 0 ? res.data : null);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [searchTerm, token]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSearch = (values: { search: string }) => {
        setSearchTerm(values.search);
        setCurrentPage(1);
    };

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };
   
    const handleSubmit = (values: any) => {
        const payload = {
            auth: values.auth,
            fname: values.fname,
            mi: values.mi,
            lname: values.lname,
            // security: values.security,
            // answer: values.answer,
            cellphone: values.cellphone,
            email: values.email,
            password: randomString,
            avatar: values.avatar,
        }
        dispatch(createAdminUserRequest(payload));
      };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ 
                auth: "",
                fname: "",
                mi: "",
                lname: "",
                // security: "",
                // answer: "",
                cellphone: "",
                email: "",
                password: randomString,
                avatar: avatar,
                confirm: false,
            }}
            validationSchema={CreateSchema}
            onSubmit={handleSubmit}
            >
            <div className="container-fluid ">
                {loading?<LoadingPage/>:""}
                {failed?<CreateUserFailed/>:""}
                {success?<CreateAdminUserSuccess />:""}
                {settings_create != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-warning" ><AiOutlineUserAdd size="30" /> Create Management User</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_settings_menu')}>Settings Menu</Breadcrumb.Item>
                                <Breadcrumb.Item active>Management User</Breadcrumb.Item>
                            </Breadcrumb>
                        <hr/>
                        <div style = {{ width:"400px"}} />
                        Create new user by inputing details below.
                        <Alert style={{border: "1px solid rgb(128, 128, 128)", backgroundColor:'#fff8d4'}} className='mt-2'>
                        <Form>
                            <Row>
                                <Col sm={4} className='text-center'>
                                    <Image src={avatar} width="100" height="100" roundedCircle className='mt-3 me-3' />
                                    <Button onClick={() => setModalShow(true)} variant="outline-primary w-50" className='btn btn-block rounded-pill mt-3'>
                                        <BiSearchAlt size="20" className='ms-2 me-2'/> Select Avatar
                                    </Button>
                                    <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} setAvatar={setAvatar} setShow={setModalShow} />
                                </Col>
                                <Col sm>
                                    <Field 
                                        type="text" 
                                        name="avatar"
                                        maxLength="50"
                                        placeholder="Avatar"
                                        className="w-75 form-control"
                                        hidden
                                        disabled={true}
                                    />
                                    <Alert style={{ backgroundColor:'#c2fcf7'}}>
                                        New management user registration.<br/> The system will send your random generated password via email.<br/> Please check your email account.
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-3 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Access <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="auth" 
                                                maxLength="10"
                                                placeholder="Account Number"
                                                className="w-75 form-control"
                                            >
                                                <option value="">- Select an option -</option>
                                                <option value="admin">Administrator</option>
                                                <option value="manager">Manager</option>
                                                <option value="encoder">Encoder</option>
                                                <option value="accnt">Accounting</option>
                                                <option value="guest">Guest</option>
                                            </Field>
                                        <ErrorMessage name="auth">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>
                                </Col>
                                <Col sm>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Firstname <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="fname"
                                            maxLength="30"
                                            placeholder="Firstname"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="fname">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                MI
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="mi" 
                                            maxLength="1"
                                            placeholder="MI"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="mi">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Lastname <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="lname" 
                                            maxLength="30"
                                            placeholder="Lastname"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="lname">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col sm>   
                                    <InputGroup className="mt-3 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Security <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="security" 
                                                maxLength="10"
                                                placeholder="Account Number"
                                                className="w-75 form-control"
                                            >
                                                <option value="">- Security Question -</option>
                                                <option value="city">In what city were you born?</option>
                                                <option value="pet">What is the name of your favorite pet?</option>
                                                <option value="maiden">What is your mother's maiden name?</option>
                                                <option value="high">What high school did you attend?</option>
                                                <option value="elementary">What was the name of your elementary school?</option>
                                                <option value="car">What was the make of your first car?</option>
                                                <option value="favorite">What was your favorite food as a child?</option>
                                                <option value="spouse">Where did you meet your spouse?</option>
                                                <option value="born">What year was your father or mother born?</option>
                                            </Field>
                                        <ErrorMessage name="security">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-3 mb-1">
                                        <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Answer <strong className='text-danger'>&nbsp;*</strong>
                                        </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="answer" 
                                            maxLength="30"
                                            placeholder="Answer"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="answer">
                                        {msg => <div style={{color:'red',padding:'5px', margin:'3px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>   */}

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Cellphone <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="cellphone" 
                                            maxLength="11"
                                            placeholder="09XXXXXXXXXX"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="cellphone">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Email <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="email" 
                                            name="email" 
                                            placeholder="Email"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="email">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm> 
                                    <Field 
                                        type="text" 
                                        name="password" 
                                        className="w-75 form-control"
                                        disabled={true}
                                        hidden
                                    />
                                </Col>
                                <Col sm> 
                                </Col>
                            </Row>

                            <br/>
                                <Col sm> 
                                    <label>
                                        <Field type="checkbox" name="confirm" />
                                        &nbsp;Please confirm for creating new user.
                                    </label>
                                    <ErrorMessage name="confirm">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            <br/>
                            <div className="d-grid gap-2">
                                <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-3" >
                                    <AiOutlineSend size="20"/> Submit
                                </Button>
                            </div>
                        </Form>
                        </Alert>


                        <hr  />
                        <h4 className="text-warning mt-3" ><AiOutlineBars size="30" /> Management User List</h4>

                        <Formik
                            enableReinitialize={true}
                            initialValues={{ 
                                search: "",
                            }}
                            validationSchema={SearchSchema}
                            onSubmit={handleSearch}
                            >
                                <Form>
                                    <Row>
                                        <Col xs lg="6"  className="bg-white rounded">
                                            <InputGroup className=" mb-3">
                                                    <Field 
                                                        type="search" 
                                                        name="search"
                                                        placeholder="&#x1F50E; Search..."
                                                        maxLength="50"
                                                        className="w-50 form-control rounded-pill"
                                                        style={{border: "1px solid rgb(128, 128, 128)"}}
                                                    >
                                                    </Field>
                                                    <Button type="submit" variant="outline-success" style={{display:'none'}}><BiSearchAlt size="20" className='ms-2 me-2' /></Button>
                                                <ErrorMessage name="search">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </Formik>

                        
                        <Table responsive striped bordered hover size="sm">
                            <thead>
                                <tr className="text-center">
                                    <th>#</th>
                                    <th>Access</th>
                                    <th>Firstname</th>
                                    <th>MI</th>
                                    <th>Lastname</th>
                                    <th>Cellphone</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                                {currentItems ? (
                                    <tbody>
                                        {currentItems.map((r, i) => (
                                        <tr key={i} className="align-middle text-secondary">
                                            <td className="text-center align-middle">{i + 1}</td>
                                            <td className="text-center align-middle">{r.auth}</td>
                                            <td className="">{r.fname}</td>
                                            <td className="text-center">{r.mi}</td>
                                            <td className="">{r.lname}</td>
                                            <td className="text-center align-middle">{r.cellphone}</td>
                                            <td className="">{r.email}</td>
                                            <td className="text-center align-middle">
                                                {r.status==1?<strong className='text-success'>Active</strong>:<strong className='text-danger'>Inactive</strong>}
                                            </td>
                                            <td className="text-center align-middle">
                                                <Button onClick={() => {setShowModal(true); setqrCodeSecret(r.secret_key)}} variant="outline-primary" size="sm" className='me-2 btn btn-block rounded-pill'><BsQrCode /> QR Code</Button>
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr><td colSpan={8} className='text-danger'>No admin user found...</td></tr>
                                    </tbody>
                                )}
                        </Table>

                            <Modal show={showModal} onHide={handleCloseModal} centered variant='light'>
                                <Modal.Header className="d-flex justify-content-center">
                                    <Modal.Title>QR Code for Authenticator</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="d-flex justify-content-center">
                                    <div>
                                        <QRCode value={qrCodeSecret} size={300} />
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="d-flex justify-content-center">
                                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                                </Modal.Footer>
                            </Modal>

                        {data?.length && data.length > ITEMS_PER_PAGE && (
                                <Pagination>
                                    <Pagination.First onClick={() => paginate(1)} />
                                    <Pagination.Prev onClick={() => paginate(currentPage - 1)} />

                                    {Array.from({ length: totalPages }).map((_, index) => {
                                        // Show the current page and two pages before and after it
                                        if (index >= currentPage - 2 && index <= currentPage + 2) {
                                        return (
                                            <Pagination.Item
                                            key={index}
                                            active={index + 1 === currentPage}
                                            onClick={() => paginate(index + 1)}
                                            >
                                            {index + 1}
                                            </Pagination.Item>
                                        );
                                        }

                                        return null;
                                    })}

                                    {/* Show ellipsis between Pagination.Prev and Pagination.Next */}
                                    {currentPage + 2 < totalPages && (
                                        <Pagination.Ellipsis key={`ellipsis`} />
                                    )}

                                    <Pagination.Next onClick={() => paginate(currentPage + 1)} />
                                    <Pagination.Last onClick={() => paginate(totalPages)} />
                                </Pagination>
                            )}
                    </Col>
                </Row>
            </div>
        </Formik>
    );
};

export default Create_Admin_User;