import { User, Auth } from './adminSettingsTypes';

export enum ActionTypes {
    // ***** Create Admin User *****
    ADMIN_CREATE_ADMIN_USER_REQUEST = 'ADMIN_CREATE_ADMIN_USER_REQUEST',
    ADMIN_CREATE_ADMIN_USER_SUCCESS = 'ADMIN_CREATE_ADMIN_USER_SUCCESS',
    ADMIN_CREATE_ADMIN_USER_FAILURE = 'ADMIN_CREATE_ADMIN_USER_FAILURE',
    ADMIN_CREATE_ADMIN_USER_REDIRECT = 'ADMIN_CREATE_ADMIN_USER_REDIRECT',
    // ***** Access Settings *****
    ADMIN_CREATE_AUTH_REQUEST = 'ADMIN_CREATE_AUTH_REQUEST',
    ADMIN_CREATE_AUTH_SUCCESS = 'ADMIN_CREATE_AUTH_SUCCESS',
    ADMIN_CREATE_AUTH_FAILURE = 'ADMIN_CREATE_AUTH_FAILURE',
    ADMIN_CREATE_AUTH_REDIRECT = 'ADMIN_CREATE_AUTH_REDIRECT',
  }

export type AdminCreateUserAction =
    // ***** Create Admin User *****
    | AdminCreateAdminUserRequestAction
    | AdminCreateAdminUserSuccessAction
    | AdminCreateAdminUserFailureAction
    | AdminCreateAdminUserRedirectAction
    // ***** Access Settings *****
    | AdminCreateAuthRequestAction
    | AdminCreateAuthSuccessAction
    | AdminCreateAuthFailureAction
    | AdminCreateAuthRedirectAction;


  // ***** Create Admin User Start*****
  interface AdminCreateAdminUserRequestAction {
    type: ActionTypes.ADMIN_CREATE_ADMIN_USER_REQUEST;
    payload: User;
  }
  interface AdminCreateAdminUserSuccessAction {
    type: ActionTypes.ADMIN_CREATE_ADMIN_USER_SUCCESS;
    payload: User;
  }
  interface AdminCreateAdminUserFailureAction {
    type: ActionTypes.ADMIN_CREATE_ADMIN_USER_FAILURE;
    payload: string;
  }
  interface AdminCreateAdminUserRedirectAction {
    type: ActionTypes.ADMIN_CREATE_ADMIN_USER_REDIRECT;
    payload: string;
  }
  
  export const createAdminUserRequest = (user: User): AdminCreateAdminUserRequestAction => ({
    type: ActionTypes.ADMIN_CREATE_ADMIN_USER_REQUEST,
    payload: user,
  });
  export const createAdminUserSuccess = (user: User): AdminCreateAdminUserSuccessAction => ({
    type: ActionTypes.ADMIN_CREATE_ADMIN_USER_SUCCESS,
    payload: user,
  });
  export const createAdminUserFailure = (error: string): AdminCreateAdminUserFailureAction => ({
    type: ActionTypes.ADMIN_CREATE_ADMIN_USER_FAILURE,
    payload: error,
  });
  export const redirect = (path: string): AdminCreateAdminUserRedirectAction => ({
    type: ActionTypes.ADMIN_CREATE_ADMIN_USER_REDIRECT,
    payload: path,
  });
  // ***** Create Admin User End *****



  // ***** Access Settings Start*****
  interface AdminCreateAuthRequestAction {
    type: ActionTypes.ADMIN_CREATE_AUTH_REQUEST;
    payload: Auth;
  }
  interface AdminCreateAuthSuccessAction {
    type: ActionTypes.ADMIN_CREATE_AUTH_SUCCESS;
    payload: Auth;
  }
  interface AdminCreateAuthFailureAction {
    type: ActionTypes.ADMIN_CREATE_AUTH_FAILURE;
    payload: string;
  }
  interface AdminCreateAuthRedirectAction {
    type: ActionTypes.ADMIN_CREATE_AUTH_REDIRECT;
    payload: string;
  }
  
  export const createAuthRequest = (auth: Auth): AdminCreateAuthRequestAction => ({
    type: ActionTypes.ADMIN_CREATE_AUTH_REQUEST,
    payload: auth,
  });
  export const createAuthSuccess = (auth: Auth): AdminCreateAuthSuccessAction => ({
    type: ActionTypes.ADMIN_CREATE_AUTH_SUCCESS,
    payload: auth,
  });
  export const createAuthFailure = (error: string): AdminCreateAuthFailureAction => ({
    type: ActionTypes.ADMIN_CREATE_AUTH_FAILURE,
    payload: error,
  });
  export const redirectAuth = (path: string): AdminCreateAuthRedirectAction => ({
    type: ActionTypes.ADMIN_CREATE_AUTH_REDIRECT,
    payload: path,
  });
  // ***** Access Settings End*****