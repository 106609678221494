import React, { useState, useEffect, useRef }  from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { 
  ActionTypes, 
  createAdminUserSuccess, 
  createAdminUserFailure, 
  redirect,
  createAuthSuccess, 
  createAuthFailure, 
  redirectAuth,
} from './adminSettingsActions';
import apiUrl from '../../../../components/apiurl';
import axios from 'axios';
import { getLocalStorageVariable } from '../../../../components/localStorage';


function* adminCreateAdminUser(action: any): Generator<any, void, AxiosResponse> {  
  try {
    const token = getLocalStorageVariable<string>('token');
    const headers = {
      Authorization: `${token}`,
    };

    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_create_admin_user`, action.payload, { headers });
    const user = response.data;

      if(user!=''){
        yield put(createAdminUserSuccess(user));
        yield put(redirect('/admin_settings_create_admin_user'));

          const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}send_email`, {
          // const response = await axios.post(`${apiUrl.url}send_email`, {
            to: action.payload.email,
            subject: 'Management User Registration',
            html: `<div style="font-family:Arial, Helvetica, sans-serif; font-size: 16px;">
            <div style="background-color: #4CAF50; color: white; padding: 10px; border: none; width: 95%; text-align: center;">
              <h1>Casa Mira - Linao</h1>
              <h2>New User Password</h2>
            </div>
            <div style="padding: 30px;">
                Hello ${action.payload.fname},
              <br/>
              <br/>
                Thank you for registering as a management user. Your temporary password is: <b> ${action.payload.password}</b><br/>
                This password is system generated.<br/>
                Please update your password after you login.<br/><br/>
                <div style="width: 100%; ">
                  <button style="background-color: #1b76de; color: white; padding: 10px; border: none; border-radius: 50px;">
                    <a href="YOUR_RESET_PASSWORD_LINK" style="text-decoration: none; color: white;">Portal Login</a>
                  </button>
                </div>
                <br/><br/>
                Best regards,<br/><br/>
                Casa Mira - Linao Application Team
            </div>
          </div>`,
          }, { headers });
    
          if (response.status === 200) {
            console.log('Email sent successfully');
          } else {
            console.error('Failed to send email');
          }

      }else{
        yield put(createAdminUserFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(createAdminUserFailure(errorMessage));
  }
}


function* adminCreateAuth(action: any): Generator<any, void, AxiosResponse> {
  try {
    const token = getLocalStorageVariable<string>('token');
    const headers = {
      Authorization: `${token}`,
    };

    console.log(action.payload)
    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_create_auth`, action.payload, { headers });
    const auth = response.data;

      if(auth!=''){
        yield put(createAuthSuccess(auth));
        yield put(redirectAuth('/admin_settings_access_settings'));
      }else{
        yield put(createAuthFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(createAuthFailure(errorMessage));
  }
}

function* watchAdminCreateUser() {
  yield takeLatest(ActionTypes.ADMIN_CREATE_ADMIN_USER_REQUEST, adminCreateAdminUser);
  yield takeLatest(ActionTypes.ADMIN_CREATE_AUTH_REQUEST, adminCreateAuth);
}

export default watchAdminCreateUser;
