import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    InputGroup,
    Button,
    Alert,
    Image,
    Breadcrumb,
    Modal,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { AiOutlineSend, AiOutlineIdcard } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
// import me from '../../../images/avatar/23.jpg';
import SubMenu from '../submenu';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { updateUserRequest } from './redux/headersActions';
import { LoadingPage, UpdateUserSuccess, UpdateUserFailed } from './loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import apiUrl from '../../../components/apiurl';

declare const require: {
    context(directory: string, useSubdirectories?: boolean, regExp?: RegExp): {
      keys(): string[];
      <T>(id: string): T;
    };
  };
  const importAll = (context: any) => context.keys().map(context);
  const images = importAll(require.context('../../../images/avatar/', false, /\.(png|jpe?g|svg)$/));

  interface MyVerticallyCenteredModalProps {
    show: boolean;
    onHide: () => void;
    setAvatar: React.Dispatch<React.SetStateAction<string | null>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>; 
  }

  const MyVerticallyCenteredModal: React.FC<MyVerticallyCenteredModalProps> = ({ show, onHide, setAvatar, setShow }) => {
    const { setFieldValue } = useFormikContext();

    const handleImageClick = (src: string) => {
        setFieldValue('avatar', src);
        setAvatar(src);
        handleClose();
      };
    
      const handleClose = () => {
        setShow(false);
      };
    
      return (
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Select Avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <div>
            <div className="d-flex flex-wrap">
              {images.map((src: any, index: any) => (
                <div key={index} onClick={() => handleImageClick(src)}>
                  <div className="p-2">
                    <img src={src} alt={`Avatar ${index}`} width="60" height="60" style={{cursor:'pointer'}} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

const CreateSchema = Yup.object().shape({
    id: Yup.string()
        .required("ID is required"),
    fname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Firstname is required"),
    mi: Yup.string()
        .trim()
        .max(1, 'Must be 1 character only')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only"),
    lname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Lastname is required."),
    security: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .required("Security question is required"),
    answer: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .required("Answer is required"),
    cellphone: Yup.string()
        .trim()
        .max(11, 'Must be 11 character only')
        .matches(/^[0-9]{11}$/, "Please input valid cellphone # ex: 09XXXXXXXX.")
        .required("Cellphone number is required."),
    email: Yup.string()
        .trim()
        .max(50, 'Must be 50 character only')
        .email("Enter a valid email.")
        .required("Email address is required."),
    password: Yup.string()
        .required("Password is required.")
        .min(6, "Password length must be at least 6 characters."),
    confirm_password: Yup.string()
        .required("Confirm Password is required")
        .min(6, "Password length must be at least 6 characters.")
        .oneOf([Yup.ref("password")], "Passwords do not match."),
    avatar: Yup.string()
        .trim()
        .max(50, 'Must be 50 character only'),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });


  interface UserData {
    account: number;
    auth: string;
    fname: string;
    mi: string;
    lname: string;
    cellphone: string;
    email: string;
    security: string;
    answer: string;
    phase: string;
    block: string;
    lot: string;
  }

const Profile: React.FC = () =>{
    const failed = useSelector((state: RootState) => state.User_HeadersReducer.error);
    const loading = useSelector((state: RootState) => state.User_HeadersReducer.loading);
    const success = useSelector((state: RootState) => state.User_HeadersReducer.info);
    const userId = getLocalStorageVariable<number>('userId');
    const userAuth = getLocalStorageVariable<string>('userAuth');
    const userAvatar = getLocalStorageVariable<string>('avatar');
    const token = getLocalStorageVariable<string>('token');
    const [avatar, setAvatar] = useState<string | null>(userAvatar);
    const [modalShow, setModalShow] = useState(false);

    const [dataUser, setUser] = useState<UserData[] | null>(null);
    const [account, setAccount] = useState(0);
    const [fname, setFname] = useState('');
    const [mi, setMi] = useState('');
    const [lname, setLname] = useState('');
    const [cellphone, setCellphone] = useState('');
    const [email, setEmail] = useState('');
    const [security, setSecurity] = useState('');
    const [answer, setAnswer] = useState('');
    const [phase, setPhase] = useState('');
    const [block, setBlock] = useState('');
    const [lot, setLot] = useState('');

    const dispatch = useDispatch();

    const fetchUser = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}profile`, { headers, params: { id: userId } });
            setUser(res.data.length > 0 ? res.data : null);
            setAccount(res.data[0].account);
            setFname(res.data[0].fname);
            setMi(res.data[0].mi);
            setLname(res.data[0].lname);
            setCellphone(res.data[0].cellphone);
            setEmail(res.data[0].email);
            setSecurity(res.data[0].security);
            setAnswer(res.data[0].answer);
            setPhase(res.data[0].phase);
            setBlock(res.data[0].block);
            setLot(res.data[0].lot);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [userId, token]);

    useEffect(() => {
        fetchUser();
    }, [ fetchUser ]);
   
    const handleSubmit = (values: any) => {
        const payload = {
            id: values.id,
            fname: values.fname,
            mi: values.mi,
            lname: values.lname,
            security: values.security,
            answer: values.answer,
            cellphone: values.cellphone,
            email: values.email,
            password: values.password,
            avatar: values.avatar,
        }
        dispatch(updateUserRequest(payload));
      };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                id: userId,
                fname: fname,
                mi: mi,
                lname: lname,
                security: security,
                answer: answer,
                cellphone: cellphone,
                email: email,
                password: "",
                confirm_password: "",
                avatar: avatar,
                confirm: false,
            }}
            validationSchema={CreateSchema}
            onSubmit={handleSubmit}
            >
            <div className="container-fluid ">
                {loading?<LoadingPage/>:""}
                {failed?<UpdateUserFailed/>:""}
                {success?<UpdateUserSuccess/>:""}

                <Row className="justify-content-md-center">
                    <Col lg="3">
                        <SubMenu/>
                    </Col>
                    <Col xs lg="9"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-primary" ><AiOutlineIdcard size="30" /> Profile</h4>
                        
                            <Breadcrumb>
                                <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item active>Create User</Breadcrumb.Item>
                            </Breadcrumb>
                            <hr/>
                        <div style = {{ width:"400px"}} />
                        <Alert style={{border: "1px solid rgb(128, 128, 128)"}} className='mt-2 bg-white shadow-lg'>
                            <Row >
                                <Col sm={3} className='text-center'>
                                    <Image src={avatar || '/static/media/default.d1c5ffe2b3cea9d80c7b.jpg'} width="150" height="150" roundedCircle /><br/>
                                    <Button onClick={() => setModalShow(true)} variant="outline-primary w-75" className='btn btn-block rounded-pill mb-3 mt-2'><BiSearchAlt size="20" className='ms-2 me-2'/> Change Avatar</Button>
                                    <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} setAvatar={setAvatar} setShow={setModalShow} />
                                    <Field 
                                        type="text" 
                                        name="avatar"
                                        maxLength="50"
                                        placeholder="Avatar"
                                        className="w-75 form-control"
                                        hidden
                                        disabled={true}
                                    />
                                    <Field 
                                        type="text" 
                                        name="id"
                                        maxLength="10"
                                        placeholder="Avatar"
                                        className="w-75 form-control"
                                        hidden
                                        disabled={true}
                                    />
                                </Col>
                                <Col sm={9}>
                                    <Alert style={{ backgroundColor:'#e6ffe3'}}>
                                        <Row >
                                            <Col sm={2} className='text-muted'>
                                                Account #
                                            </Col>
                                            <Col sm>
                                                <strong>{account}</strong>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col sm={2} className='text-muted'>
                                                Name
                                            </Col>
                                            <Col sm>
                                                <strong>{fname} {mi} {lname}</strong>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col sm={2} className='text-muted'>
                                                Address
                                            </Col>
                                            <Col sm>
                                                <strong>Phase {phase} Block {block} Lot {lot}</strong>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col sm={2} className='text-muted'>
                                                Cellphone
                                            </Col>
                                            <Col sm>
                                                <strong>{cellphone}</strong>
                                            </Col>
                                            <Col sm={2} className='text-muted'>
                                                Email
                                            </Col>
                                            <Col sm>
                                                <strong>{email}</strong>
                                            </Col>
                                        </Row>
                                    </Alert>
                                </Col>
                            </Row>

                        <Form>
                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-3 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Firstname
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="fname"
                                            maxLength="30"
                                            placeholder="Firstname"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="fname">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-3 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                MI
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="mi" 
                                            maxLength="1"
                                            placeholder="MI"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="mi">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-3 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Lastname
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="lname" 
                                            maxLength="30"
                                            placeholder="Lastname"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="lname">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-3 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Security
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="security" 
                                                maxLength="10"
                                                placeholder="Account Number"
                                                className="w-75 form-control"
                                            >
                                                <option value="">- Security Question -</option>
                                                <option value="city">In what city were you born?</option>
                                                <option value="pet">What is the name of your favorite pet?</option>
                                                <option value="maiden">What is your mother's maiden name?</option>
                                                <option value="high">What high school did you attend?</option>
                                                <option value="elementary">What was the name of your elementary school?</option>
                                                <option value="car">What was the make of your first car?</option>
                                                <option value="favorite">What was your favorite food as a child?</option>
                                                <option value="spouse">Where did you meet your spouse?</option>
                                                <option value="born">What year was your father or mother born?</option>
                                            </Field>
                                        <ErrorMessage name="security">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-3 mb-1">
                                        <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Answer
                                        </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                type="text" 
                                                name="answer" 
                                                placeholder="Answer"
                                                className="w-75 form-control"
                                            />
                                        <ErrorMessage name="answer">
                                        {msg => <div style={{color:'red',padding:'5px', margin:'3px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>  

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-3 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Cellphone
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                type="text" 
                                                name="cellphone" 
                                                maxLength="11"
                                                placeholder="Cellphone #"
                                                className="w-75 form-control"
                                            />
                                        <ErrorMessage name="cellphone">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-3 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Email
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                type="email" 
                                                name="email" 
                                                placeholder="Email"
                                                className="w-75 form-control"
                                            />
                                        <ErrorMessage name="email">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-3 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Password <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                type="password" 
                                                name="password" 
                                                placeholder="Password"
                                                className="w-75 form-control"
                                            />
                                        <ErrorMessage name="password">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-3 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Confirm <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                type="password" 
                                                name="confirm_password" 
                                                placeholder="Confirm Password"
                                                className="w-75 form-control"
                                            />
                                        <ErrorMessage name="confirm_password">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>

                            <br/>
                                <Col lg='6'> 
                                    <Alert style={{backgroundColor:'#fffdd0'}} >
                                        Note: You need to re-input your password if you will edit your profile.
                                    </Alert>
                                </Col>
                            
                                <Col sm> 
                                    <label>
                                        <Field type="checkbox" name="confirm" />
                                        &nbsp;Please confirm for editing your account.
                                    </label>
                                    <ErrorMessage name="confirm">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            <br/>
                            <div className="d-grid gap-2">
                                <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-3" >
                                    <AiOutlineSend size="20"/> Save
                                </Button>
                            </div>
                        </Form>
                        </Alert>
                    </Col>
                </Row>
            </div>
        </Formik>
    );
};

export default Profile;