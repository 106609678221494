// src/redux/sagas.ts
import { all, fork } from 'redux-saga/effects';

//admin
import admin_loginSaga from '../pages/admin/login/redux/loginSaga';
import admin_createUserSaga from '../pages/admin/user/redux/adminUserSaga';
import admin_settingsSaga from '../pages/admin/settings/redux/adminSettingsSaga';
import admin_headersSaga from '../pages/admin/headers/redux/adminHeadersSaga';
import admin_associationSaga from '../pages/admin/association/redux/adminAssociationSaga';
import admin_waterSaga from '../pages/admin/water/redux/adminWaterSaga';

// user
import loginSaga from '../pages/users/login/redux/loginSaga';
import activateSaga from '../pages/users/activation/redux/activateSaga';
import headersSaga from '../pages/users/headers/redux/headersSaga';

function* rootSaga() {
  yield all([
    // admin
    fork(admin_loginSaga),
    fork(admin_createUserSaga),
    fork(admin_settingsSaga),
    fork(admin_headersSaga),
    fork(admin_associationSaga),
    fork(admin_waterSaga),
    // user
    fork(activateSaga),
    fork(loginSaga),
    fork(headersSaga),
  ]);
}

export default rootSaga;
