import { WaterState } from './adminWaterTypes';
import {  WaterAction, WaterActionTypes } from './adminWaterActions';

const initialState: WaterState = {
  info: null,
  loading: false,
  error: null,
  redirectPath: null,
  searcherror: null,
};

const waterReducer = (state = initialState, action: WaterAction): WaterState => {
  switch (action.type) {
    // Create
    case WaterActionTypes.WATER_CREATE_REQUEST:
      return { ...state, loading: true, error: null };
    case WaterActionTypes.WATER_CREATE_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case WaterActionTypes.WATER_CREATE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case WaterActionTypes.WATER_CREATE_REDIRECT:
      return {...state, redirectPath: action.payload };
    // Search
    case WaterActionTypes.WATER_SEARCH_REQUEST:
      return { ...state, loading: true, error: null };
    case WaterActionTypes.WATER_SEARCH_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case WaterActionTypes.WATER_SEARCH_FAILURE:
      return { ...state, loading: false, searcherror: action.payload };
    case WaterActionTypes.WATER_SEARCH_REDIRECT:
      return {...state, redirectPath: action.payload };
    // Credit Memo
    case WaterActionTypes.CREDIT_MEMO_REQUEST:
      return { ...state, loading: true, error: null };
    case WaterActionTypes.CREDIT_MEMO_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case WaterActionTypes.CREDIT_MEMO_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case WaterActionTypes.CREDIT_MEMO_REDIRECT:
      return {...state, redirectPath: action.payload };
    // Debit Memo
    case WaterActionTypes.DEBIT_MEMO_REQUEST:
      return { ...state, loading: true, error: null };
    case WaterActionTypes.DEBIT_MEMO_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case WaterActionTypes.DEBIT_MEMO_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case WaterActionTypes.DEBIT_MEMO_REDIRECT:
      return {...state, redirectPath: action.payload };
    default:
      return state;
  }
};

export default waterReducer;