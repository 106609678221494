import React, { PureComponent, ReactNode } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface DataItem {
  date: string;
  payment: number;
  bill: number;
  amt: number;
}

interface ExampleProps {}

interface ExampleState {}

const data: DataItem[] = [
  {
    date: '2000-01',
    payment: 4000,
    bill: 2400,
    amt: 2400,
  },
  {
    date: '2000-02',
    payment: 3000,
    bill: 1398,
    amt: 2210,
  },
  {
    date: '2000-03',
    payment: 2000,
    bill: 9800,
    amt: 2290,
  },
  {
    date: '2000-04',
    payment: 2780,
    bill: 3908,
    amt: 2000,
  },
  {
    date: '2000-05',
    payment: 1890,
    bill: 4800,
    amt: 2181,
  },
  {
    date: '2000-06',
    payment: 2390,
    bill: 3800,
    amt: 2500,
  },
  {
    date: '2000-07',
    payment: 3490,
    bill: 4300,
    amt: 2100,
  },
  {
    date: '2000-08',
    payment: 4000,
    bill: 2400,
    amt: 2400,
  },
  {
    date: '2000-09',
    payment: 3000,
    bill: 1398,
    amt: 2210,
  },
  {
    date: '2000-10',
    payment: 2000,
    bill: 9800,
    amt: 2290,
  },
  {
    date: '2000-11',
    payment: 2780,
    bill: 3908,
    amt: 2000,
  },
  {
    date: '2000-12',
    payment: 1890,
    bill: 4800,
    amt: 2181,
  },
  {
    date: '2001-01',
    payment: 1890,
    bill: 4800,
    amt: 2181,
  },
];

const monthTickFormatter = (tick: string): string => {
  const date = new Date(tick);
  return (date.getMonth() + 1).toString();
};

const renderQuarterTick = (props: any): React.ReactElement | null => {
  const { x, y, payload } = props;
  const { value, offset } = payload;
  const date = new Date(value);
  const month = date.getMonth();
  const quarterNo = Math.floor(month / 3) + 1;
  const isMidMonth = month % 3 === 1;

  if (month % 3 === 1) {
    return <text x={x} y={y - 4} textAnchor="middle">{`Q${quarterNo}`}</text>;
  }

  const isLast = month === 12;

  if (month % 3 === 0 || isLast) {
    const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;

    return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
  }

  return null;
};

class Association_BarChart extends PureComponent<ExampleProps, ExampleState> {
  render() {
    return (
      <div >
        <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={monthTickFormatter} />
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            interval={0}
            tick={(props: any) => renderQuarterTick(props) as React.ReactElement<SVGElement, string | React.JSXElementConstructor<any>>}
            height={1}
            scale="band"
            xAxisId="quarter"
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="bill" fill="#8884d8" />
          <Bar dataKey="payment" fill="#82ca9d" />
        </BarChart>
        </ResponsiveContainer>
        </div>
    );
  }
}

export default Association_BarChart;