import { User, Search_Account } from './activateTypes';

export enum ActionTypes {
    // search
    ACTIVATE_SEARCH_REQUEST = 'ACTIVATE_SEARCH_REQUEST',
    ACTIVATE_SEARCH_SUCCESS = 'ACTIVATE_SEARCH_SUCCESS',
    ACTIVATE_SEARCH_FAILURE = 'ACTIVATE_SEARCH_FAILURE',
    // update
    ACTIVATE_UPDATE_REQUEST = 'ACTIVATE_UPDATE_REQUEST',
    ACTIVATE_UPDATE_SUCCESS = 'ACTIVATE_UPDATE_SUCCESS',
    ACTIVATE_UPDATE_FAILURE = 'ACTIVATE_UPDATE_FAILURE',
    ACTIVATE_UPDATE_REDIRECT = 'ACTIVATE_UPDATE_REDIRECT',
  }

  // search
  interface Activate_SearchRequestAction {
    type: ActionTypes.ACTIVATE_SEARCH_REQUEST;
    payload: { account: string };
  }
  interface Activate_SearchSuccessAction {
    type: ActionTypes.ACTIVATE_SEARCH_SUCCESS;
    payload: Search_Account;
  }
  interface Activate_SearchFailureAction {
    type: ActionTypes.ACTIVATE_SEARCH_FAILURE;
    payload: string;
  }


  // update
  interface Activate_UpdateRequestAction {
    type: ActionTypes.ACTIVATE_UPDATE_REQUEST;
    payload: User;
  }
  interface Activate_UpdateSuccessAction {
    type: ActionTypes.ACTIVATE_UPDATE_SUCCESS;
    payload: User;
  }
  interface Activate_UpdateFailureAction {
    type: ActionTypes.ACTIVATE_UPDATE_FAILURE;
    payload: string;
  }
  interface Activate_UpdateRedirectAction {
    type: ActionTypes.ACTIVATE_UPDATE_REDIRECT;
    payload: string;
  }
  
  export type ActivateAction =
    | Activate_SearchRequestAction
    | Activate_SearchSuccessAction
    | Activate_SearchFailureAction
    | Activate_UpdateRequestAction
    | Activate_UpdateSuccessAction
    | Activate_UpdateFailureAction
    | Activate_UpdateRedirectAction;
  
  // search
  export const activate_searchRequest = (account: string): Activate_SearchRequestAction => ({
    type: ActionTypes.ACTIVATE_SEARCH_REQUEST,
    payload: { account },
  });
  
  export const activate_searchSuccess = (account: Search_Account): Activate_SearchSuccessAction => ({
    type: ActionTypes.ACTIVATE_SEARCH_SUCCESS,
    payload: account,
  });
  
  export const activate_searchFailure = (error: string): Activate_SearchFailureAction => ({
    type: ActionTypes.ACTIVATE_SEARCH_FAILURE,
    payload: error,
  });

  
  // update
  export const activate_updateRequest = (user: User): Activate_UpdateRequestAction => ({
    type: ActionTypes.ACTIVATE_UPDATE_REQUEST,
    payload: user,
  });
  
  export const activate_updateSuccess = (user: User): Activate_UpdateSuccessAction => ({
    type: ActionTypes.ACTIVATE_UPDATE_SUCCESS,
    payload: user,
  });
  
  export const activate_updateFailure = (error: string): Activate_UpdateFailureAction => ({
    type: ActionTypes.ACTIVATE_UPDATE_FAILURE,
    payload: error,
  });

  export const activate_updateRedirect = (path: string): Activate_UpdateRedirectAction => ({
    type: ActionTypes.ACTIVATE_UPDATE_REDIRECT,
    payload: path,
  });
  