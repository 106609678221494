// src/store/auth/sagas.ts
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { 
  ActionTypes, 
  activate_searchSuccess, 
  activate_searchFailure, 
  activate_updateSuccess, 
  activate_updateFailure, 
  activate_updateRedirect 
} from './activateActions';
import apiUrl from '../../../../components/apiurl';
import axios from 'axios';
import { setSessionVariable } from '../../../../components/sessionStorage';

// search
function* activate_search(action: any): Generator<any, void, AxiosResponse> {
  try {
    const { account } = action.payload;
    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}activate_search`, { account });
    const user = response.data;

      if(user!=''){
        yield put(activate_searchSuccess(user));
        // yield put(admin_redirect('/admin_dashboard'));
        setSessionVariable('user_id', user[0].id);
        setSessionVariable('account', user[0].account);
        setSessionVariable('fname', user[0].fname);
        setSessionVariable('mi', user[0].mi);
        setSessionVariable('lname', user[0].lname);
        setSessionVariable('phase', user[0].phase);
        setSessionVariable('block', user[0].block);
        setSessionVariable('lot', user[0].lot);
        window.location.reload();
        // console.log(user[0].account)
      }else{
        yield put(activate_searchFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(activate_searchFailure(errorMessage));
  }
}


// update
function* activate(action: any): Generator<any, void, AxiosResponse> {
  try {
    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}activation`, action.payload);
    const user = response.data;

      if(user!=''){
        yield put(activate_updateSuccess(user));
        yield put(activate_updateRedirect('/login'));

          const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}send_email_no_token`, {
            to: action.payload.email,
            subject: 'Account Activation',
            html: `<div style="font-family:Arial, Helvetica, sans-serif; font-size: 16px;">
            <div style="background-color: #00c04b; color: white; padding: 5px; border: none; width: 95%; text-align: center;">
              <h2>Casa Mira - Linao</h2>
              <h4>User Account Activation</h4>
            </div>
            <div style="padding: 30px;">
                Hello,
              <br/>
              <br/>
                Your account is now activated.<br/>Please give us some feedbacks to improve the system.<br/>Thank you.
                <br/><br/>
                Best regards,<br/>
                Casa Mira - Linao Application Team
            </div>
          </div>`});
    
          if (response.status === 200) {
            console.log('Email sent successfully');
          } else {
            console.error('Failed to send email');
          }

          // console.log(headers)

      }else{
        yield put(activate_updateFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(activate_updateFailure(errorMessage));
  }
}

function* admin_watchLogin() {
  yield takeLatest(ActionTypes.ACTIVATE_SEARCH_REQUEST, activate_search);
  yield takeLatest(ActionTypes.ACTIVATE_UPDATE_REQUEST, activate);
}

export default admin_watchLogin;
