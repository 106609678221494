import { AssociationState } from './adminAssociationTypes';
import {  AssociationAction, AssociationActionTypes } from './adminAssociationActions';

const initialState: AssociationState = {
  info: null,
  loading: false,
  error: null,
  redirectPath: null,
  searcherror: null,
};

const associationReducer = (state = initialState, action: AssociationAction): AssociationState => {
  switch (action.type) {
    // Create
    case AssociationActionTypes.ASSOCIATION_CREATE_REQUEST:
      return { ...state, loading: true, error: null };
    case AssociationActionTypes.ASSOCIATION_CREATE_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case AssociationActionTypes.ASSOCIATION_CREATE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case AssociationActionTypes.ASSOCIATION_CREATE_REDIRECT:
      return {...state, redirectPath: action.payload };
    // Search
    case AssociationActionTypes.ASSOCIATION_SEARCH_REQUEST:
      return { ...state, loading: true, error: null };
    case AssociationActionTypes.ASSOCIATION_SEARCH_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case AssociationActionTypes.ASSOCIATION_SEARCH_FAILURE:
      return { ...state, loading: false, searcherror: action.payload };
    case AssociationActionTypes.ASSOCIATION_SEARCH_REDIRECT:
      return {...state, redirectPath: action.payload };
    // Credit Memo
    case AssociationActionTypes.CREDIT_MEMO_REQUEST:
      return { ...state, loading: true, error: null };
    case AssociationActionTypes.CREDIT_MEMO_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case AssociationActionTypes.CREDIT_MEMO_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case AssociationActionTypes.CREDIT_MEMO_REDIRECT:
      return {...state, redirectPath: action.payload };
    // Debit Memo
    case AssociationActionTypes.DEBIT_MEMO_REQUEST:
      return { ...state, loading: true, error: null };
    case AssociationActionTypes.DEBIT_MEMO_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case AssociationActionTypes.DEBIT_MEMO_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case AssociationActionTypes.DEBIT_MEMO_REDIRECT:
      return {...state, redirectPath: action.payload };
    default:
      return state;
  }
};

export default associationReducer;