import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { 
  ActionTypes, 
  updateAdminUserSuccess, 
  updateAdminUserFailure, 
  redirect,
  // createAuthSuccess, 
  // createAuthFailure, 
  // redirectAuth,
} from './adminHeadersActions';
import apiUrl from '../../../../components/apiurl';
import axios from 'axios';
import { getLocalStorageVariable, setLocalStorageVariable } from '../../../../components/localStorage';


function* adminUpdateAdminUser(action: any): Generator<any, void, AxiosResponse> {
  try {
    const token = getLocalStorageVariable<string>('token');
    const headers = {
      Authorization: `${token}`,
    };

    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_create_admin_user/update`, action.payload, { headers });
    const user = response.data;

      if(user!=''){
        yield put(updateAdminUserSuccess(user));
        setLocalStorageVariable('avatar', action.payload.avatar);
        yield put(redirect('/admin_profile'));

          const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}send_email`, {
          // const response = await axios.post(`${apiUrl.url}send_email`, {
            to: action.payload.email,
            subject: 'User Update Notification',
            html: `<div style="font-family:Arial, Helvetica, sans-serif; font-size: 16px;">
            <div style="background-color: #00c04b; color: white; padding: 5px; border: none; width: 95%; text-align: center;">
              <h2>Casa Mira - Linao</h2>
              <h4>User Account Update</h4>
            </div>
            <div style="padding: 30px;">
                Hello ${action.payload.fname},
              <br/>
              <br/>
                Your account information updated succesfully.<br/>Please double check if you made this transaction.
                <br/><br/>
                Best regards,<br/>
                Casa Mira - Linao Application Team
            </div>
          </div>`,
          }, { headers });
    
          if (response.status === 200) {
            console.log('Email sent successfully');
          } else {
            console.error('Failed to send email');
          }

      }else{
        yield put(updateAdminUserFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(updateAdminUserFailure(errorMessage));
  }
}


// function* adminCreateAuth(action: any): Generator<any, void, AxiosResponse> {
//   try {
//     const token = getLocalStorageVariable<string>('token');
//     const headers = {
//       Authorization: `${token}`,
//     };

//     console.log(action.payload)
//     const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}admin_create_auth`, action.payload, { headers });
//     const auth = response.data;

//       if(auth!=''){
//         yield put(createAuthSuccess(auth));
//         yield put(redirectAuth('/admin_settings_access_settings'));
//       }else{
//         yield put(createAuthFailure('failed'));
//       }
//   } catch (error) {
//     const errorMessage = (error as any).message; 
//     yield put(createAuthFailure(errorMessage));
//   }
// }

function* watchAdminCreateUser() {
  yield takeLatest(ActionTypes.ADMIN_UPDATE_ADMIN_USER_REQUEST, adminUpdateAdminUser);
  // yield takeLatest(ActionTypes.ADMIN_CREATE_AUTH_REQUEST, adminCreateAuth);
}

export default watchAdminCreateUser;
