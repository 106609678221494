// src/store/auth/sagas.ts
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { 
  AuthActionTypes, 
  loginSuccess, 
  loginFailure, 
  redirect,
  forgotSuccess, 
  forgotFailure, 
  forgotRedirect 
} from './loginActions';
import apiUrl from '../../../../components/apiurl';
import axios from 'axios';
import { setLocalStorageVariable } from '../../../../components/localStorage';

function* login(action: any): Generator<any, void, AxiosResponse> {
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
      const day = date.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
    };

  try {
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);

    const { email, password } = action.payload;
    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}login`, { email, password });
    const user = response.data;

      if(user!=''){
        yield put(loginSuccess(user));
        yield put(redirect('/dashboard'));
        setLocalStorageVariable('userId', user.id);
        setLocalStorageVariable('userAuth', user.auth);
        setLocalStorageVariable('avatar', user.avatar);
        setLocalStorageVariable('token', user.token);
        setLocalStorageVariable('loginDate', formattedDate);
        
        // setLocalStorageVariable('userAuth', user[0].auth);
        // console.log(user.token)
      }else{
        yield put(loginFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(loginFailure(errorMessage));
  }
}


// ************** Forgot *************
function* forgot(action: any): Generator<any, void, AxiosResponse> {
  try {
    const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}login/forgot`, action.payload);
    const user = response.data;
    console.log(user)

      if(user!=''){
        yield put(forgotSuccess(user));
        yield put(forgotRedirect('/login'));
          const response: AxiosResponse = yield call(axios.post, `${apiUrl.url}send_email_no_token`, {
            to: action.payload.email,
            subject: 'Password Reset Notification',
            html: `<div style="font-family:Arial, Helvetica, sans-serif; font-size: 16px;">
              <div style="background-color: #00c04b; color: white; padding: 10px; border: none; width: 95%; text-align: center;">
                <h1>Casa Mira - Linao</h1>
                <h2>Reset Password</h2>
              </div>
              <div style="padding: 30px;">
                  Your password in Casa Mira - Linao Application is reset. <br/>Your temporary password is: <b> ${action.payload.password}</b><br/><br/>
                    This password is system generated.<br/>
                    Please update your password after you login.
                  <br/><br/>
                  Best regards,<br/>
                  Casa Mira - Linao Application Team
              </div>
            </div>`,
          });
    
          if (response.status === 200) {
            console.log('Email sent successfully');
          } else {
            console.error('Failed to send email');
          }

      }else{
        yield put(forgotFailure('failed'));
      }
  } catch (error) {
    const errorMessage = (error as any).message; 
    yield put(forgotFailure(errorMessage));
  }
}


function* watchLogin() {
  yield takeLatest(AuthActionTypes.LOGIN_REQUEST, login);
  yield takeLatest(AuthActionTypes.FORGOT_REQUEST, forgot);
}

export default watchLogin;
