import { User, Auth } from './headersTypes';

export enum ActionTypes {
    // ***** Create Admin User *****
    UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE',
    UPDATE_USER_REDIRECT = 'UPDATE_USER_REDIRECT',
    // ***** Access Settings *****
    // ADMIN_CREATE_AUTH_REQUEST = 'ADMIN_CREATE_AUTH_REQUEST',
    // ADMIN_CREATE_AUTH_SUCCESS = 'ADMIN_CREATE_AUTH_SUCCESS',
    // ADMIN_CREATE_AUTH_FAILURE = 'ADMIN_CREATE_AUTH_FAILURE',
    // ADMIN_CREATE_AUTH_REDIRECT = 'ADMIN_CREATE_AUTH_REDIRECT',
  }

export type UpdateUserAction =
    // ***** Create Admin User *****
    | UpdateUserRequestAction
    | UpdateUserSuccessAction
    | UpdateUserFailureAction
    | UpdateUserRedirectAction
    // ***** Access Settings *****
    // | AdminCreateAuthRequestAction
    // | AdminCreateAuthSuccessAction
    // | AdminCreateAuthFailureAction
    // | AdminCreateAuthRedirectAction;


  // ***** Create Admin User Start*****
  interface UpdateUserRequestAction {
    type: ActionTypes.UPDATE_USER_REQUEST;
    payload: User;
  }
  interface UpdateUserSuccessAction {
    type: ActionTypes.UPDATE_USER_SUCCESS;
    payload: User;
  }
  interface UpdateUserFailureAction {
    type: ActionTypes.UPDATE_USER_FAILURE;
    payload: string;
  }
  interface UpdateUserRedirectAction {
    type: ActionTypes.UPDATE_USER_REDIRECT;
    payload: string;
  }
  
  export const updateUserRequest = (user: User): UpdateUserRequestAction => ({
    type: ActionTypes.UPDATE_USER_REQUEST,
    payload: user,
  });
  export const updateUserSuccess = (user: User): UpdateUserSuccessAction => ({
    type: ActionTypes.UPDATE_USER_SUCCESS,
    payload: user,
  });
  export const updateUserFailure = (error: string): UpdateUserFailureAction => ({
    type: ActionTypes.UPDATE_USER_FAILURE,
    payload: error,
  });
  export const updateUserRedirect = (path: string): UpdateUserRedirectAction => ({
    type: ActionTypes.UPDATE_USER_REDIRECT,
    payload: path,
  });
  // ***** Create Admin User End *****



  // ***** Access Settings Start*****
  // interface AdminCreateAuthRequestAction {
  //   type: ActionTypes.ADMIN_CREATE_AUTH_REQUEST;
  //   payload: Auth;
  // }
  // interface AdminCreateAuthSuccessAction {
  //   type: ActionTypes.ADMIN_CREATE_AUTH_SUCCESS;
  //   payload: Auth;
  // }
  // interface AdminCreateAuthFailureAction {
  //   type: ActionTypes.ADMIN_CREATE_AUTH_FAILURE;
  //   payload: string;
  // }
  // interface AdminCreateAuthRedirectAction {
  //   type: ActionTypes.ADMIN_CREATE_AUTH_REDIRECT;
  //   payload: string;
  // }
  
  // export const createAuthRequest = (auth: Auth): AdminCreateAuthRequestAction => ({
  //   type: ActionTypes.ADMIN_CREATE_AUTH_REQUEST,
  //   payload: auth,
  // });
  // export const createAuthSuccess = (auth: Auth): AdminCreateAuthSuccessAction => ({
  //   type: ActionTypes.ADMIN_CREATE_AUTH_SUCCESS,
  //   payload: auth,
  // });
  // export const createAuthFailure = (error: string): AdminCreateAuthFailureAction => ({
  //   type: ActionTypes.ADMIN_CREATE_AUTH_FAILURE,
  //   payload: error,
  // });
  // export const redirectAuth = (path: string): AdminCreateAuthRedirectAction => ({
  //   type: ActionTypes.ADMIN_CREATE_AUTH_REDIRECT,
  //   payload: path,
  // });
  // ***** Access Settings End*****