import React, { useState, useEffect, useCallback } from 'react';
import ReactToPrint from 'react-to-print';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    InputGroup,
    Button,
    Alert,
    Table,
    Breadcrumb,
    Pagination,
    Image,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AiOutlineBars } from "react-icons/ai";
import { LuClipboardList } from "react-icons/lu";
import { FaPrint } from "react-icons/fa";
import { TfiPrinter } from "react-icons/tfi";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BiSearchAlt } from "react-icons/bi";
import { FaFileCsv } from "react-icons/fa";
import SubMenu from '../submenu';
import { useNavigate } from 'react-router-dom';
import { setSessionVariable } from '../../../components/sessionStorage';
import { AuthAccessFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import apiUrl from '../../../components/apiurl';
import linao from '../../../images/linao.png';    
import clipm from '../../../images/clipm.png';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import saveAs from 'file-saver';

  
const SearchSchema = Yup.object().shape({
    search: Yup.string()
        .trim(),
  });

const CreateSchema = Yup.object().shape({
    fdate: Yup.string()
        .required("From date is required"),
    tdate: Yup.string()
        .required("To date is required"),
  });

  interface SystemData {
    user_acct_no: number;
    charge_amount: number;
    association_no: number;
  }

  interface UserData {
    id: number;
    account: number;
    fname: string;
    mi: string;
    lname: string;
    phase: string;
    block: string;
    lot: string;
    email: string;
    cellphone: string;
    lot_area: number;
    dues: number;
    association_balance: number;
    status: number;
  }

  interface AdminData {
    id: number;
    auth: string;
    fname: string;
    mi: string;
    lname: string;
  }

  interface AssociationData {
    association_no: number;
    account: number;
    fname: string;
    mi: string;
    lname: string;
    phase: string;
    block: string;
    lot: string;
    lot_area: number;
    dues: number;
    charge: number;
    cdate: string;
    date_from: string;
    date_to: string;
    total_amount: number;
    balance: number;
    accounting_id: number;
    manager_id: number;
  }

  interface MemoData {
    memo_no: number;
    type: string;
    account: number;
    fname: string;
    mi: string;
    lname: string;
    phase: string;
    block: string;
    lot: string;
    date: string;
    total_amount: number;
    tbd: number;
    balance: number;
    paid: number;
    memo: string;
  }

const ITEMS_PER_PAGE = 50;

const Adjustment_List: React.FC = () =>{
    const token = getLocalStorageVariable<string>('token');
    const [systemData, setSystemData] = useState<SystemData[] | null>(null);
    const [userData, setUserData] = useState<UserData[] | null>(null);
    const [adminData, setAdminData] = useState<AdminData[] | null>(null);
    const [associationData, setAssociationData] = useState<AssociationData[] | null>(null);
    const [memoData, setMemoData] = useState<MemoData[] | null>(null);
    const [filteredMemoData, setFilteredMemoData] = useState<MemoData[] | null>(null);
    const [associationAllData, setAllAssociationData] = useState<AssociationData[] | null>(null);
    const [filteredAssociationData, setFilteredAssociationData] = useState<AssociationData[]>([]);
    const navigate = useNavigate();
    const [searchDateFrom, setSearchDateFrom] = useState('');
    const [searchDateTo, setSearchDateTo] = useState('');

    const association_read = getLocalStorageVariable<string>('association_read');
    const [searchTerm, setSearchTerm] = useState('');
    const componentRef = React.useRef(null);
    const printAllRef = React.useRef(null);
    const id = '1';

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = filteredMemoData?.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    const totalPages = Math.ceil((filteredMemoData?.length || 0) / ITEMS_PER_PAGE);

    const headers = [
        { label: "Date", key: "cdate" },
        { label: "Bill #", key: "association_no" },
        { label: "First Name", key: "fname" },
        { label: "Last Name", key: "lname" },
        { label: "Phase", key: "phase" },
        { label: "Block", key: "block" },
        { label: "Lot", key: "lot" },
        { label: "Lot Area", key: "lot_area" },
        { label: "Dues", key: "dues" },
        { label: "Charge", key: "charge" },
        { label: "Total Amount", key: "total_amount" },
      ];

      const formatDateExcel = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
      };

      const formattedData = (filteredMemoData || []).map((item) => ({
        ...item,
      }));
      
      const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const dataBlob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(dataBlob, "adjustment_list.xlsx");
      };

// ***** Print List *****
    class ComponentToPrint extends React.Component {
        render() {
          return (
            <div style={{fontSize:'12px'}} className="print-content">
                <Table responsive striped bordered hover size="sm">
                    <thead>
                        <tr className="text-center">
                            <th>#</th>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Memo #</th>
                            <th>Account #</th>
                            <th>Name</th>
                            <th>Phase</th>
                            <th>Block</th>
                            <th>Lot</th>
                            <th>TBD</th>
                            <th>Balance</th>
                            <th>Total</th>
                            <th>Remarks</th>
                        </tr>
                    </thead>
                        {filteredMemoData?.length!=0? (
                            <tbody>
                                {filteredMemoData?.map((r, i) => (
                                <tr key={i} className="align-middle text-secondary">
                                    <td className="text-center align-middle">{i + 1}</td>
                                    <td className="text-center align-middle">{r.type}</td>
                                    <td className="text-center align-middle">{(new Date(r.date)).toLocaleDateString("en-PH")}</td>
                                    <td className="text-center align-middle">{r.memo_no}</td>
                                    <td className="text-center align-middle">{r.account}</td>
                                    <td className="">{r.fname} {r.mi}. {r.lname}</td>
                                    <td className="text-center align-middle">{r.phase}</td>
                                    <td className="text-center align-middle">{r.block}</td>
                                    <td className="text-center align-middle">{r.lot}</td>
                                    <td className="text-end">
                                        {r.tbd.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                    <td className="text-end">
                                        {r.balance.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                    <td className="text-end">
                                        {(r.total_amount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                    <td className="text-start">{r.memo}</td>
                                </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr><td colSpan={15} className='text-danger'>No association bill found...</td></tr>
                            </tbody>
                        )}
                </Table>
                <style>
                    {`
                    @media print {
                        body {
                            margin: 0;
                        }
                        .print-content {
                        }
                        @page {
                            size: A4;
                            margin: 5mm;
                        }
                    }
                    `}
                </style>
            </div>
          );
        }
      }

    // ***** Print Per User *****
    class PrintAll extends React.Component {
        render() {
          return (
            <div>
                {filteredAssociationData?.length!=0? (
                    <div style={{fontSize:'12px'}}>
                        {filteredAssociationData?.map((r, i) => (
                            <Alert variant=""  key={i} className="print-page">
                            <Image  src={linao} style={{ width: '13rem' }} />
                            <div style={{ position: 'absolute', height: '10px', right: 100, top: 30 }}>
                            <Image src={clipm} style={{ width: '10rem'}} />
                            </div>
                                <Row >
                                    <Col sm={2}>
                                    </Col>
                                    <Col sm>
                                    </Col>
                                    <Col sm>
                                    <h4 className="text-secondary">Association</h4>
                                    </Col>
                                    <Col sm>
                                    </Col>
                                    <Col sm>
                                        <h4><strong className="text-secondary">Statement</strong></h4>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={2}>
                                        Association Bill # : 
                                    </Col>
                                    <Col sm>
                                        <strong className="text-secondary">{r.association_no}</strong>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={2}>
                                        Name : 
                                    </Col>
                                    <Col sm>
                                        <strong className="text-secondary">{r.fname} {r.mi}. {r.lname}</strong>
                                    </Col>
                                    <Col sm>
                                    </Col>
                                    <Col sm>
                                        <Row>
                                            <Col sm>
                                                Due Date :
                                            </Col>
                                            <Col sm>
                                                <strong className="text-danger"> {new Date(new Date(r.cdate).getTime() + 15 * 24 * 60 * 60 * 1000).toLocaleDateString("en-PH")}</strong>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={2}>
                                        Address: 
                                    </Col>
                                    <Col sm>
                                        <strong className="text-secondary">Phase {r.phase} Block {r.block} Lot {r.lot}</strong>
                                    </Col>
                                    <Col sm>
                                    </Col>
                                    <Col sm>
                                        <Row>
                                            <Col sm>
                                                Generated : 
                                            </Col>
                                            <Col sm>
                                                <strong className="text-secondary"> {(new Date(r.cdate)).toLocaleDateString("en-PH")}</strong>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Table responsive bordered hover size="sm" className='mt-3 text-secondary'>
                                    <thead>
                                        <tr className="text-center align-middle">
                                            <th>Date</th>
                                            <th>Transaction</th>
                                            <th>Amount</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="text-center align-middle">
                                            <td></td>
                                            <td>Balance Forwarded</td>
                                            <td></td>
                                            <td>
                                                {userData?.map((j,k)=>(
                                                    <div key={k}>{j.account==r.account?
                                                        <div>{(j.association_balance - r.total_amount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
                                                        :''}
                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                        <tr className="text-center align-middle">
                                            <td >{(new Date(r.cdate)).toLocaleDateString("en-PH")}</td>
                                            <td >
                                                Association Bill #: {r.association_no} <br/>
                                                Association Due for {(new Date(r.date_from)).toLocaleDateString("en-PH")} - {(new Date(r.date_to)).toLocaleDateString("en-PH")}<br/>
                                                -- Association Due, {r.lot_area} @ PHP {(r.dues).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})} + {(r.charge).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}
                                            </td>
                                            <td >{(r.total_amount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                                            <td>
                                                {userData?.map((j,k)=>(
                                                    <div key={k}>{j.account==r.account?
                                                        <div>{(j.association_balance).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2})}</div>
                                                        :''}
                                                    </div>
                                                ))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>

                                {userData?.map((j,k)=>(
                                    <div key={k}>{j.account==r.account?
                                        <div>
                                            <Table responsive bordered hover size="sm" className='mt-1 text-secondary'>
                                                <thead>
                                                    <tr className="text-center align-middle">
                                                        <th>0 - 30</th>
                                                        <th>31 - 60</th>
                                                        <th>61 - 90</th>
                                                        <th>Over 90</th>
                                                        <th>Amount Due</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="text-center align-middle">
                                                        <td >
                                                            {associationAllData?.filter(item => item.account == j.account)
                                                                .reduce((prev, current) => {
                                                                const daysDiff = Math.ceil((new Date().getTime() - new Date(current.cdate).getTime()) / 86400000);
                                                                    if (daysDiff <= 30) {
                                                                        return prev + current.balance;
                                                                    }
                                                                return prev;
                                                            }, 0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        </td>
                                                        <td >
                                                            {associationAllData?.filter(item => item.account == j.account)
                                                                .reduce((prev, current) => {
                                                                const daysDiff = Math.ceil((new Date().getTime() - new Date(current.cdate).getTime()) / 86400000);
                                                                    if ((daysDiff >= 31)&&(daysDiff <= 60)) {
                                                                        return prev + current.balance;
                                                                    }
                                                                return prev;
                                                            }, 0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        </td>
                                                        <td >
                                                            {associationAllData?.filter(item => item.account == j.account)
                                                                .reduce((prev, current) => {
                                                                const daysDiff = Math.ceil((new Date().getTime() - new Date(current.cdate).getTime()) / 86400000);
                                                                    if ((daysDiff >= 61)&&(daysDiff <= 90)) {
                                                                        return prev + current.balance;
                                                                    }
                                                                return prev;
                                                            }, 0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        </td>
                                                        <td >
                                                            {associationAllData?.filter(item => item.account == j.account)
                                                                .reduce((prev, current) => {
                                                                const daysDiff = Math.ceil((new Date().getTime() - new Date(current.cdate).getTime()) / 86400000);
                                                                    if (daysDiff >= 91) {
                                                                        return prev + current.balance;
                                                                    }
                                                                return prev;
                                                            }, 0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        </td>
                                                        <td >
                                                            {associationAllData?.filter(item => item.account == j.account)
                                                                .reduce((prev, current) => prev + current.balance, 0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        :''}
                                    </div>
                                ))}

                                

                                <Row className='mt-4 text-secondary'>
                                    <Col sm>   
                                        Bank Details<br/>
                                        Bank Name : <strong>BDO</strong>
                                    </Col>
                                    <Col sm={6}> 
                                        Account Name : <br/><strong>CASA MIRA LINAO HOME OWNERS ASSOCIATION INC.</strong><br/>
                                        Account Number : <strong>007618008851</strong>
                                    </Col>
                                    <Col sm>
                                        Please report your payment and upload screenshot for verification.
                                    </Col>
                                </Row>

                                <Row className='p-2 text-secondary text-center align-middle'>
                                    <Col sm className='text-left pl-5'>   
                                        {/* <QRCode 
                                            value={invno} 
                                            size="100"
                                        /> */}
                                    </Col>
                                    <Col sm className='pt-5'>
                                        {adminData?.map((j,k)=>(
                                            <div key={k}>{j.id==r.accounting_id?
                                                <div>{j.fname} {j.mi}. {j.lname}</div>
                                                :''}
                                            </div>
                                        ))}
                                        Finance Association
                                    </Col>
                                    <Col sm className='pt-5'>
                                        {adminData?.map((j,k)=>(
                                            <div key={k}>{j.id==r.manager_id?
                                                <div>{j.fname} {j.mi}. {j.lname}</div>
                                                :''}
                                            </div>
                                        ))}
                                        Property Manager
                                    </Col>
                                </Row>
                            </Alert>
                            ))}
                        </div>
                    ) : (
                        <div></div>
                )}
                <style>
                {`
                    @media print {
                        .print-page {
                            page-break-before: always;
                        }
                        body {
                            margin: 0;
                        }
                    }
                `}
                </style>
            </div>
          );
        }
      }

    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = formatDate(currentDate);
        setSearchDateFrom(formattedDate);
        setSearchDateTo(formattedDate);
      }, []); 
    
      const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const day = date.getDate().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}`;
      };

    const fetchUser = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}admin_get_user`, { headers, params: { search: searchTerm } });
            setUserData(res.data.length > 0 ? res.data : null);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [searchTerm, token]);

    const fetchAssociation = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<MemoData[]> = await axios.get(`${apiUrl.url}admin_get_memo/date_range`, { headers, params: { date_from: searchDateFrom, date_to: searchDateTo  } });
            setMemoData(res.data.length > 0 ? res.data : null);
            setFilteredMemoData(res.data);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [searchDateFrom, searchDateTo, token]);

    const fetchAllAssociation = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<AssociationData[]> = await axios.get(`${apiUrl.url}admin_get_association/all`, { headers });
            setAllAssociationData(res.data);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [token]);

    const fetchAdminUser = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<AdminData[]> = await axios.get(`${apiUrl.url}admin_get_admin_user`, { headers, params: { search: searchTerm } });
            setAdminData(res.data.length > 0 ? res.data : null);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [searchTerm, token]);

    const fetchSystemData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<SystemData[]> = await axios.post(`${apiUrl.url}admin_get_system_account/`, { id: id }, { headers });
            setSystemData(res.data);
        } catch (error) {
            console.error('Error: ', error);
            return false;
        }
    }, [id, token]);

    useEffect(() => {
        fetchSystemData();
        fetchUser();
        fetchAdminUser();
        fetchAssociation();
        fetchAllAssociation();
    }, [ fetchSystemData, fetchUser, fetchAdminUser, fetchAssociation, fetchAllAssociation ]);

    const handleDateChange = (event: any, dateType: 'from' | 'to') => {
        const newDate = event.target.value;
        if (dateType === 'from') {
          setSearchDateFrom(newDate);
        } else if (dateType === 'to') {
          setSearchDateTo(newDate);
        }
      };

    const handleSearch = (values: { search: string }) => {
        const searchTerm = values.search.toLowerCase();
        if (memoData) {
          const filteredData = memoData.filter((item) =>
            item.memo_no.toString().includes(searchTerm) ||
            item.account.toString().includes(searchTerm) ||
            item.fname.toLowerCase().includes(searchTerm) ||
            item.lname.toLowerCase().includes(searchTerm)
          );
            setFilteredMemoData(filteredData);
            setCurrentPage(1);
        }
      };
    
   
    const handleSubmit = (values: any) => {
        
    };

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ 
                fdate: searchDateFrom,
                tdate: searchDateTo,
            }}
            validationSchema={CreateSchema}
            onSubmit={handleSubmit}
            >
            <div className="container-fluid ">
                {association_read != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-success" ><LuClipboardList size="30" /> Adjustment List</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_association_menu')}>Association Menu</Breadcrumb.Item>
                                <Breadcrumb.Item active>Adjustment List</Breadcrumb.Item>
                            </Breadcrumb>
                            <hr/>
                        <div style = {{ width:"400px"}} />
                        
                        <Formik
                            enableReinitialize={true}
                            initialValues={{ 
                                search: "",
                            }}
                            validationSchema={SearchSchema}
                            onSubmit={handleSearch}
                            >
                            <Form>
                                <Row>
                                    <Col xs lg="6"  className="bg-white rounded">
                                        <InputGroup className=" mb-3">
                                                <Field 
                                                    type="search" 
                                                    name="search"
                                                    placeholder="&#x1F50E; Search..."
                                                    maxLength="50"
                                                    className="w-50 form-control rounded-pill"
                                                    style={{border: "1px solid rgb(128, 128, 128)"}}
                                                >
                                                </Field>
                                                <Button type="submit" variant="outline-success" style={{display:'none'}}><BiSearchAlt size="20" className='ms-2 me-2'/></Button>
                                            <ErrorMessage name="search">
                                                {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                            </ErrorMessage>
                                        </InputGroup>
                                    </Col>
                                    <Col lg="6" className=''>
                                            <div className="btn-toolbar mb-2 mb-md-0 float-end">
                                            <div className="btn-group mr-2">
                                                
                                                <ReactToPrint
                                                    trigger={() => <button type="button" className="btn btn-md btn-outline-success"><FaPrint size="20"/> Print</button>}
                                                    content={() => printAllRef.current}
                                                />
                                                <ReactToPrint
                                                    trigger={() => <button type="button"  className="btn btn-md btn-outline-success"><TfiPrinter size="20"/> Print List</button>}
                                                    content={() => componentRef.current}
                                                />
                                                <button type="button" onClick={exportToExcel} className="btn btn-md btn-outline-success"><RiFileExcel2Fill size="20"/> Export to Excel</button>
                                                <CSVLink data={formattedData} headers={headers} filename={"association_bill.csv"}
                                                    className="btn btn-md btn-outline-success"
                                                    target="_blank">
                                                        <FaFileCsv size="20"/> Export to CSV
                                                </CSVLink>
                                            </div>
                                                <div style={{ display: 'none' }}>
                                                    <PrintAll ref={printAllRef} />
                                                </div>
                                                <div style={{ display: 'none' }}>
                                                    <ComponentToPrint ref={componentRef} />
                                                </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Formik>

                        <Alert style={{border: "1px solid rgb(128, 128, 128)", backgroundColor: '#e6ffe3'}} className='mt-1'>
                        {/* Input From and To date of the adjustment date. */}
                        <Form>
                            <Row>
                                <Col sm>   
                                    <InputGroup>
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                From <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="date" 
                                            name="fdate"
                                            className="w-75 form-control"
                                            onChange={(event:any) => handleDateChange(event, 'from')}
                                        />
                                        <ErrorMessage name="fdate">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup>
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                To <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="date" 
                                            name="tdate" 
                                            className="w-75 form-control"
                                            onChange={(event:any) => handleDateChange(event, 'to')}
                                        />
                                        <ErrorMessage name="tdate">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup>
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Type 
                                            </InputGroup.Text>
                                        </InputGroup>
                                            <Field 
                                                as="select" 
                                                name="type" 
                                                maxLength="10"
                                                placeholder="Account Number"
                                                className="w-75 form-control"
                                            >
                                                <option value="">- Select Type -</option>
                                                <option value="credit">Credit Memo</option>
                                                <option value="debit">Debit Memo</option>
                                            </Field>
                                        <ErrorMessage name="type">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form>
                        </Alert>


                        <Table responsive striped bordered hover size="sm">
                            <thead>
                                <tr className="text-center">
                                    <th>#</th>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Memo #</th>
                                    <th>Account #</th>
                                    <th>Name</th>
                                    <th>Phase</th>
                                    <th>Block</th>
                                    <th>Lot</th>
                                    <th>TBD</th>
                                    <th>Balance</th>
                                    <th>Total</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                                {currentItems?.length!=0? (
                                    <tbody>
                                        {currentItems?.map((r, i) => (
                                        <tr key={i} className="align-middle text-secondary">
                                            <td className="text-center align-middle">{i + 1}</td>
                                            <td className="text-center align-middle">{r.type}</td>
                                            <td className="text-center align-middle">{(new Date(r.date)).toLocaleDateString("en-PH")}</td>
                                            <td className="text-center align-middle">{r.memo_no}</td>
                                            <td className="text-center align-middle">{r.account}</td>
                                            <td className="">{r.fname} {r.mi}. {r.lname}</td>
                                            <td className="text-center align-middle">{r.phase}</td>
                                            <td className="text-center align-middle">{r.block}</td>
                                            <td className="text-center align-middle">{r.lot}</td>
                                            <td className="text-end">
                                                {r.tbd.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </td>
                                            <td className="text-end">
                                                {r.balance.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </td>
                                            <td className="text-end">
                                                {(r.total_amount).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                            </td>
                                            <td className="text-start">{r.memo}</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr><td colSpan={15} className='text-danger'>No association bill found...</td></tr>
                                    </tbody>
                                )}
                        </Table>

                        {filteredMemoData?.length && filteredMemoData.length > ITEMS_PER_PAGE && (
                                <Pagination>
                                    <Pagination.First onClick={() => paginate(1)} />
                                    <Pagination.Prev onClick={() => paginate(currentPage - 1)} />

                                    {Array.from({ length: totalPages }).map((_, index) => {
                                        // Show the current page and two pages before and after it
                                        if (index >= currentPage - 2 && index <= currentPage + 2) {
                                        return (
                                            <Pagination.Item
                                            key={index}
                                            active={index + 1 === currentPage}
                                            onClick={() => paginate(index + 1)}
                                            >
                                            {index + 1}
                                            </Pagination.Item>
                                        );
                                        }

                                        return null;
                                    })}

                                    {/* Show ellipsis between Pagination.Prev and Pagination.Next */}
                                    {currentPage + 2 < totalPages && (
                                        <Pagination.Ellipsis key={`ellipsis`} />
                                    )}

                                    <Pagination.Next onClick={() => paginate(currentPage + 1)} />
                                    <Pagination.Last onClick={() => paginate(totalPages)} />
                                </Pagination>
                            )}
                    </Col>
                </Row>
            </div>
        </Formik>
    );
};

export default Adjustment_List;