// src/redux/rootReducer.ts
import { combineReducers } from 'redux';

//admin
import admin_loginReducer from '../pages/admin/login/redux/loginReducer';
import admin_createUserReducer from '../pages/admin/user/redux/adminUserReducer';
import admin_settingsReducer from '../pages/admin/settings/redux/adminSettingsReducer';
import admin_headersReducer from '../pages/admin/headers/redux/adminHeadersReducer';
import admin_associationReducer from '../pages/admin/association/redux/adminAssociationReducer';
import admin_waterReducer from '../pages/admin/water/redux/adminWaterReducer';

// user
import loginReducer from '../pages/users/login/redux/loginReducer';
import activateReducer from '../pages/users/activation/redux/activateReducer';
import headersReducer from '../pages/users/headers/redux/headersReducer';

const rootReducer = combineReducers({
  // admin
  Admin_LoginReducer: admin_loginReducer,
  Admin_CreateUserReducer: admin_createUserReducer,
  Admin_SettingsReducer: admin_settingsReducer,
  Admin_HeadersReducer: admin_headersReducer,
  Admin_AssociationReducer: admin_associationReducer,
  Admin_WaterReducer: admin_waterReducer,
  // user
  LoginReducer: loginReducer,
  User_ActivateReducer: activateReducer,
  User_HeadersReducer: headersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
