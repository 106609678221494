import React, { useState, useEffect, useRef }  from 'react';
import {
    Modal,
    Spinner,
    Button
} from 'react-bootstrap';
import check from '../../../images/check.png';
import failed from '../../../images/failed.png';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';


export const LoadingPage = () => {
    return (
        <Modal show={true} centered>
            <div className='d-flex justify-content-center text-center bg-secondary'>
                <Button variant="dark" disabled className='w-100'>
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/>
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className='me-3'
                    />
                    Loading ...
                </Button>
            </div>
        </Modal>
    );
}


export const UpdateUserSuccess = () => {
    const redirectPath = useSelector((state: RootState) => state.User_HeadersReducer.redirectPath);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        if (redirectPath) {
            navigate(redirectPath);
            window.location.reload();
          }
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title><img src={check} width="100" height="100" /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Recorded successfully!</h4>
                    <p>User account updated successfully.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                {/* <Button variant="secondary" onClick={handleClose} >Yes</Button> */}
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export const UpdateUserFailed = () => {
    const [show, setShow] = useState(true);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null); 
    const handleClose = () => {
        setShow(false);
    }
    useEffect(() => {
        if (show && closeButtonRef.current) {
          closeButtonRef.current.focus();
        }
      }, [show]);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header  className="d-flex justify-content-center">
                <Modal.Title><img src={failed} width="100" height="100" /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
                <div>
                    <h4>Transaction Failed!</h4>
                    <p>Some data are invalid or there are problems in the account. Please contact systems administrator and try again!</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose} ref={closeButtonRef as React.RefObject<HTMLButtonElement>}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}




