import { User, Update_User } from './adminUserTypes';

export enum ActionTypes {
    ADMIN_CREATE_USER_REQUEST = 'ADMIN_CREATE_USER_REQUEST',
    ADMIN_CREATE_USER_SUCCESS = 'ADMIN_CREATE_USER_SUCCESS',
    ADMIN_CREATE_USER_FAILURE = 'ADMIN_CREATE_USER_FAILURE',
    ADMIN_CREATE_USER_REDIRECT = 'ADMIN_CREATE_USER_REDIRECT',
    ADMIN_UPDATE_USER_REQUEST = 'ADMIN_UPDATE_USER_REQUEST',
    ADMIN_UPDATE_USER_SUCCESS = 'ADMIN_UPDATE_USER_SUCCESS',
    ADMIN_UPDATE_USER_FAILURE = 'ADMIN_UPDATE_USER_FAILURE',
    ADMIN_UPDATE_USER_REDIRECT = 'ADMIN_UPDATE_USER_REDIRECT',
  }

export type AdminCreateUserAction =
  | AdminCreateUserRequestAction
  | AdminCreateUserSuccessAction
  | AdminCreateUserFailureAction
  | AdminCreateUserRedirectAction
  | AdminUpdateUserRequestAction
  | AdminUpdateUserSuccessAction
  | AdminUpdateUserFailureAction
  | AdminUpdateUserRedirectAction;

// ***** Create User *****
  interface AdminCreateUserRequestAction {
    type: ActionTypes.ADMIN_CREATE_USER_REQUEST;
    payload: User;
  }
  interface AdminCreateUserSuccessAction {
    type: ActionTypes.ADMIN_CREATE_USER_SUCCESS;
    payload: User;
  }
  interface AdminCreateUserFailureAction {
    type: ActionTypes.ADMIN_CREATE_USER_FAILURE;
    payload: string;
  }
  interface AdminCreateUserRedirectAction {
    type: ActionTypes.ADMIN_CREATE_USER_REDIRECT;
    payload: string;
  }
  
  export const createRequest = (user: User): AdminCreateUserRequestAction => ({
    type: ActionTypes.ADMIN_CREATE_USER_REQUEST,
    payload: user,
  });
  export const createSuccess = (user: User): AdminCreateUserSuccessAction => ({
    type: ActionTypes.ADMIN_CREATE_USER_SUCCESS,
    payload: user,
  });
  export const createFailure = (error: string): AdminCreateUserFailureAction => ({
    type: ActionTypes.ADMIN_CREATE_USER_FAILURE,
    payload: error,
  });
  export const redirect = (path: string): AdminCreateUserRedirectAction => ({
    type: ActionTypes.ADMIN_CREATE_USER_REDIRECT,
    payload: path,
  });
  

  // ***** Update User *****
  interface AdminUpdateUserRequestAction {
    type: ActionTypes.ADMIN_UPDATE_USER_REQUEST;
    payload: Update_User;
  }
  interface AdminUpdateUserSuccessAction {
    type: ActionTypes.ADMIN_UPDATE_USER_SUCCESS;
    payload: Update_User;
  }
  interface AdminUpdateUserFailureAction {
    type: ActionTypes.ADMIN_UPDATE_USER_FAILURE;
    payload: string;
  }
  interface AdminUpdateUserRedirectAction {
    type: ActionTypes.ADMIN_UPDATE_USER_REDIRECT;
    payload: string;
  }
  
  export const updateRequest = (user: Update_User): AdminUpdateUserRequestAction => ({
    type: ActionTypes.ADMIN_UPDATE_USER_REQUEST,
    payload: user,
  });
  export const updateSuccess = (user: Update_User): AdminUpdateUserSuccessAction => ({
    type: ActionTypes.ADMIN_UPDATE_USER_SUCCESS,
    payload: user,
  });
  export const updateFailure = (error: string): AdminUpdateUserFailureAction => ({
    type: ActionTypes.ADMIN_UPDATE_USER_FAILURE,
    payload: error,
  });
  export const updateRedirect = (path: string): AdminUpdateUserRedirectAction => ({
    type: ActionTypes.ADMIN_UPDATE_USER_REDIRECT,
    payload: path,
  });