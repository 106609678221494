import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux'; 
import saga from './redux/sagas'
// import { applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
// import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducers from './redux/reducers';

// const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
const sagaMiddleware = createSagaMiddleware();

// const store = configureStore({
//   reducer: rootReducers,
//   middleware: [sagaMiddleware], // Add middleware like sagaMiddleware here
//   devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
// });
const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    sagaMiddleware, // Add sagaMiddleware to middleware array
  ],
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
});

sagaMiddleware.run(saga);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
