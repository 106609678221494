//local connection
// const apiUrl = {
//     url: "http://localhost:8888/billing/",
// }

//live connection
const apiUrl = {
    url: "https://billing-be.pearsportal.com/billing/",
}

export default apiUrl;