import React, {useState, useCallback, useEffect} from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row,
    Col,
    Alert,
    Card,
    Image,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SubMenu from '../submenu';
import { AiOutlineDashboard, AiOutlineDollar, AiOutlineGroup } from "react-icons/ai";
import { IoWaterOutline } from "react-icons/io5";
import { MdOutlineBalance } from "react-icons/md";
import ASSOCIATION_BARCHART from './association_barchart';
import ASSOCIATION_PIECHART from './association_piechart';
import WATER_BARCHART from './water_barchart';
import WATER_PIECHART from './water_piechart';
import apiUrl from '../../../components/apiurl';
import { getLocalStorageVariable } from '../../../components/localStorage';

interface UserData {
  account: number;
  auth: string;
  fname: string;
  mi: string;
  lname: string;
  cellphone: string;
  email: string;
  phase: string;
  block: string;
  lot: string;
}

const Dashboard: React.FC = () =>{
  const userId = getLocalStorageVariable<number>('userId');
  // const userAuth = getLocalStorageVariable<string>('userAuth');
  const userAvatar = getLocalStorageVariable<string>('avatar');
  const token = getLocalStorageVariable<string>('token');
  // const [avatar, setAvatar] = useState<string | null>(userAvatar);
  // const [modalShow, setModalShow] = useState(false);

  // const [dataUser, setUser] = useState<UserData[] | null>(null);
  // const [auth, setAuth] = useState('');
  const [account, setAccount] = useState(0);
  const [fname, setFname] = useState('');
  const [mi, setMi] = useState('');
  const [lname, setLname] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [email, setEmail] = useState('');
  const [phase, setPhase] = useState('');
  const [block, setBlock] = useState('');
  const [lot, setLot] = useState('');

  const fetchUser = useCallback(async () => {
    const headers = {
        Authorization: `${token}`,
      };
    try {
        const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}profile`, { headers, params: { id: userId } });
        // setUser(res.data.length > 0 ? res.data : null);
        // setAuth(res.data[0].auth);
        setAccount(res.data[0].account);
        setFname(res.data[0].fname);
        setMi(res.data[0].mi);
        setLname(res.data[0].lname);
        setCellphone(res.data[0].cellphone);
        setEmail(res.data[0].email);
        setPhase(res.data[0].phase);
        setBlock(res.data[0].block);
        setLot(res.data[0].lot);
    } catch (error) {
        console.error('Error: ', error);
    }
  }, [userId, token]);

  useEffect(() => {
      fetchUser();
  }, [ fetchUser ]);
    

    return (
        <div className="container-fluid"> 
            <Row className="justify-content-md-center">
                <Col sm={3}>
                    <SubMenu/>
                </Col>
                <Col xs lg="9"  className="p-3 bg-white rounded shadow-sm">
                    <Row className="justify-content-md-center">
                        <Col >
                        <div style = {{ width:"400px"}} />
                        <h4 className="text-primary" ><AiOutlineDashboard size="30"/> Dashboard</h4>
                        <hr/>
                            <Alert style={{border: "1px solid rgb(128, 128, 128)"}} className='bg-white shadow-lg mb-0'>
                              <Row>
                                <Col sm={2} className='text-center'>
                                  <Image src={userAvatar || '/static/media/1.1f1d0984d6e0f46c338d.jpg'} width="100" height="100" roundedCircle /><br/>
                                </Col>
                                <Col sm={9}>
                                  <Row >
                                      <Col sm={2} className='text-muted'>
                                          Account #
                                      </Col>
                                      <Col sm>
                                          <strong>{account}</strong>
                                      </Col>
                                  </Row>
                                  <Row >
                                      <Col sm={2} className='text-muted'>
                                          Name
                                      </Col>
                                      <Col sm>
                                          <strong>{fname} {mi} {lname}</strong>
                                      </Col>
                                  </Row>
                                  <Row >
                                      <Col sm={2} className='text-muted'>
                                          Address
                                      </Col>
                                      <Col sm>
                                        <strong>Phase {phase} Block {block} Lot {lot}</strong>
                                      </Col>
                                  </Row>
                                  <Row >
                                      <Col sm={2} className='text-muted'>
                                          Email 
                                      </Col>
                                      <Col sm>
                                          <strong>{email}</strong>
                                      </Col>
                                      <Col sm={2} className='text-muted'>
                                          Cellphone
                                      </Col>
                                      <Col sm>
                                          <strong>{cellphone}</strong>
                                      </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Alert>
                        </Col>
                    </Row>
                    <Row xs={1} md={4} className="g-4 mt-0">
                      <Col>
                        <Link to="/association" style={{ textDecoration: 'none'}}>
                          <Card className="m-1 rounded shadow-lg">
                            <Card.Body>
                              <div style={{ position: 'absolute', height: '10px', left: 30, top: 20 }}>
                                  {/* <FeatherIcon icon="credit-card" size="40" className="text-info" stroke-width="1" /> */}
                                  <AiOutlineGroup size="50" className="text-success"/>
                              </div>
                              {/* <Card.Title><h3>{totinv.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h3></Card.Title> */}
                              <Card.Title className='text-end'><h3>0.00</h3></Card.Title>
                                <Card.Text className='pt-3 text-end'>
                                  Total Amount
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="bg-success">
                              <big className="text-light">Association</big>
                            </Card.Footer>
                          </Card>
                        </Link>
                      </Col>
                      <Col>
                        <Link to="/water" style={{ textDecoration: 'none'}}>
                          <Card className="m-1 rounded shadow-lg">
                            <Card.Body className='text-right'>
                              <div style={{ position: 'absolute', height: '10px', left: 30, top: 20 }}>
                                  {/* <FeatherIcon icon="droplet" size="40" className="text-success" stroke-width="1" /> */}
                                  <IoWaterOutline size="50" className="text-info"/>
                              </div>
                              <Card.Title className='text-end'><h3>0.00</h3></Card.Title>
                                <Card.Text className='pt-3 text-end'>
                                  Total Amount
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="bg-info">
                              <big className="text-light">Water</big>
                            </Card.Footer>
                          </Card>
                        </Link>
                      </Col>
                      <Col>
                        <Link to="/bills_payment" style={{ textDecoration: 'none'}}>
                          <Card className="m-1 rounded shadow-lg">
                            <Card.Body className='text-right'>
                              <div style={{ position: 'absolute', height: '10px', left: 30, top: 20 }}>
                                  {/* <FeatherIcon icon="dollar-sign" size="40" className="text-warning" stroke-width="1" /> */}
                                  <AiOutlineDollar size="50" className="text-danger"/>
                              </div>
                              {/* <Card.Title><h3>{totpay.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h3></Card.Title> */}
                              <Card.Title className='text-end'><h3>0.00</h3></Card.Title>
                                <Card.Text className='pt-3 text-end'>
                                  Total Amount
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="bg-danger">
                              <big className="text-light">Payment</big>
                            </Card.Footer>
                          </Card>
                        </Link>
                      </Col>
                      <Col>
                        <Link to="/bills" style={{ textDecoration: 'none'}}>
                          <Card className="m-1 rounded shadow-lg">
                            <Card.Body className='text-right'>
                              <div style={{ position: 'absolute', height: '10px', left: 30, top: 20 }}>
                                  {/* <FeatherIcon icon="book" size="40" className="text-danger" stroke-width="1" /> */}
                                  <MdOutlineBalance size="50" className="text-warning"/>
                              </div>
                              {/* <Card.Title><h3>{totbal.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h3></Card.Title> */}
                              <Card.Title className='text-end'><h3>0.00</h3></Card.Title>
                                <Card.Text className='pt-3 text-end'>
                                  Total Amount
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="bg-warning">
                              <big className="text-light">Balance</big>
                            </Card.Footer>
                          </Card>
                        </Link>
                      </Col>
                    </Row>
                    
                    <Row xs={1} md={2} className="g-4 mt-3">
                      <Col className="text-center">
                        Total association bills and total payment per month.
                      <h4 className="text-primary" ><AiOutlineGroup size="30"/> Association Bar Chart</h4>
                        <ASSOCIATION_BARCHART/>
                      </Col>
                      <Col className="text-center">
                        Total water bills and total payment per month.
                      <h4 className="text-info" ><IoWaterOutline size="30"/> Water Bar Chart</h4>
                        <WATER_BARCHART/>
                      </Col>
                    </Row>

                    <Row xs={1} md={2} className="g-4 mt-3">
                      <Col className="text-center">
                        Total association bills, total receivables and total payment.
                        <h4 className="text-primary" ><AiOutlineGroup size="30"/> Association Pie Chart</h4>
                        <ASSOCIATION_PIECHART/>
                      </Col>
                      <Col className="text-center">
                        Total water bills, total receivables and total payment.
                        <h4 className="text-info" ><IoWaterOutline size="30"/> Water Pie Chart</h4>
                        <WATER_PIECHART/>
                      </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Dashboard;