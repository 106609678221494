import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    InputGroup,
    Button,
    Alert,
    Table,
    Breadcrumb,
    Pagination,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AiOutlineSend, AiOutlineUserAdd, AiOutlineBars } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
import SubMenu from '../submenu';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { createRequest } from './redux/adminUserActions';
import { LoadingPage, CreateUserSuccess, CreateUserFailed, AuthAccessFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import { setSessionVariable } from '../../../components/sessionStorage';
import apiUrl from '../../../components/apiurl';
import { useNavigate } from 'react-router-dom';

const SearchSchema = Yup.object().shape({
    search: Yup.string()
        .trim(),
  });

const CreateSchema = Yup.object().shape({
    account: Yup.string()
        .max(10, 'Must be 10 characters or less')
        .required("Account # is required"),
    fname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Firstname is required"),
    mi: Yup.string()
        .trim()
        .max(1, 'Must be 1 character only')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only"),
    lname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only")
        .required("Lastname is required."),
    phase: Yup.string()
        .trim()
        .max(5, 'Must be 5 characters or less')
        .matches(/^([a-z\s A-Z\s 0-9])+$/, "Please input valid numbers and characters.")
        .required("Phase is required."),
    block: Yup.string()
        .trim()
        .max(5, 'Must be 5 characters or less')
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Block number is required."),
    lot: Yup.string()
        .trim()
        .max(5, 'Must be 5 characters or less')
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Lot number is required.")
        .test('account-exists', 'Phase, Block and Lot already exists.', async function (value) {
            try {
              const { block } = this.parent;
              const { phase } = this.parent;
      
              const token = getLocalStorageVariable<string>('token');
              const headers = {
                Authorization: `${token}`,
              };
      
              const response = await axios.post(
                `${apiUrl.url}admin_check_account_duplication/`,
                { phase: phase, block: block, lot: value  },
                { headers }
              );
      
              if (response.data.msg) {
                return false;
              }
      
              return !response.data.exists;
            } catch (error) {
              console.error('Error checking account duplication:', error);
              return false;
            }
          }),
    lotarea: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .required("Lot area is required."),
    floorarea: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .required("Floor area is required."),
    dues: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .matches(/^\d*\.?\d*$/, "Please input valid numbers.")
        .required("Monthly Dues is required."),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });

  interface UserAccount {
    user_acct_no: number;
  }

  interface UserData {
    id: number;
    account: string;
    fname: string;
    mi: string;
    lname: string;
    phase: string;
    block: string;
    lot: string;
    status: number;
  }

const ITEMS_PER_PAGE = 30;

const Create_User: React.FC = () =>{
    const failed = useSelector((state: RootState) => state.Admin_CreateUserReducer.error);
    const loading = useSelector((state: RootState) => state.Admin_CreateUserReducer.loading);
    const success = useSelector((state: RootState) => state.Admin_CreateUserReducer.info);
    const token = getLocalStorageVariable<string>('token');
    const [data, setData] = useState<UserAccount[] | null>(null);
    const [dataUser, setUser] = useState<UserData[] | null>(null);
    const user_create = getLocalStorageVariable<string>('user_create');
    const [searchTerm, setSearchTerm] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const id = '1';

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = dataUser?.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    const totalPages = Math.ceil((dataUser?.length || 0) / ITEMS_PER_PAGE);

    const fetchUser = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            // const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}admin_get_user`, { headers });
            const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}admin_get_user`, { headers, params: { search: searchTerm } });
            setUser(res.data.length > 0 ? res.data : null);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [searchTerm, token]);

    const fetchData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<UserAccount[]> = await axios.post(`${apiUrl.url}admin_get_system_account/`, { id: id }, { headers });
            setData(res.data);
        } catch (error) {
            console.error('Error: ', error);
            return false;
        }
    }, [id, token]);

    useEffect(() => {
        fetchData();
        fetchUser();
    }, [ fetchData, fetchUser ]);

    const handleSearch = (values: { search: string }) => {
        setSearchTerm(values.search);
        setCurrentPage(1);
    };

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };
   
    const handleSubmit = (values: any) => {
        // const { email, password } = values;
        const payload = {
            account: values.account,
            fname: values.fname,
            mi: values.mi,
            lname: values.lname,
            phase: values.phase,
            block: values.block,
            lot: values.lot,
            lotarea: values.lotarea,
            floorarea: values.floorarea,
            dues: values.dues,
        }
        dispatch(createRequest(payload));
      };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ 
                account: data ? Number(data[0]?.user_acct_no) : "",
                fname: "",
                mi: "",
                lname: "",
                phase: "",
                block: "",
                lot: "",
                lotarea: "",
                floorarea: "",
                dues: "",
                confirm: false,
            }}
            validationSchema={CreateSchema}
            onSubmit={handleSubmit}
            >
            <div className="container-fluid ">
                {loading?<LoadingPage/>:""}
                {failed?<CreateUserFailed/>:""}
                {success?<CreateUserSuccess/>:""}
                {user_create != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-primary" ><AiOutlineUserAdd size="30" /> Create Account</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_user_menu')}>User Menu</Breadcrumb.Item>
                                <Breadcrumb.Item active>Create User</Breadcrumb.Item>
                            </Breadcrumb>
                            <hr/>
                        <div style = {{ width:"400px"}} />
                        Create new account by inputing details below.
                        <Alert style={{border: "1px solid rgb(128, 128, 128)", backgroundColor:'#c9dbff'}} className='mt-2 shadow-lg'>
                        <Form>
                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Account #
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="account" 
                                            maxLength="10"
                                            placeholder="Account Number"
                                            className="w-75 form-control"
                                            disabled={true}
                                        />
                                        <ErrorMessage name="account">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>
                                </Col>
                                <Col sm>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Phase <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="phase" 
                                            maxLength="5"
                                            placeholder="Phase"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="phase">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Block <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="block" 
                                            maxLength="5"
                                            placeholder="Block"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="block">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Lot <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="lot" 
                                            maxLength="5"
                                            placeholder="Lot"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="lot">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Firstname <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="fname"
                                            maxLength="30"
                                            placeholder="Firstname"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="fname">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                MI
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="mi" 
                                            maxLength="1"
                                            placeholder="MI"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="mi">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Lastname <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="lname" 
                                            maxLength="30"
                                            placeholder="Lastname"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="lname">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Lot Area <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="lotarea" 
                                            maxLength="10"
                                            placeholder="Lot Area"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="lotarea">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Floor Area <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="floorarea" 
                                            maxLength="10"
                                            placeholder="Floor Area"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="floorarea">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm>   
                                    <InputGroup className="mt-1 mb-1">
                                        <InputGroup className="w-25">
                                            <InputGroup.Text className="w-100">
                                                Dues <strong className='text-danger'>&nbsp;*</strong>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <Field 
                                            type="text" 
                                            name="dues" 
                                            maxLength="10"
                                            placeholder="Dues"
                                            className="w-75 form-control"
                                        />
                                        <ErrorMessage name="dues">
                                            {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                        </ErrorMessage>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <br/>
                                <Col sm> 
                                    <label>
                                        <Field type="checkbox" name="confirm" />
                                        &nbsp;Please confirm for creating new account.
                                    </label>
                                    <ErrorMessage name="confirm">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </Col>
                            <br/>
                            <div className="d-grid gap-2">
                                <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-3" >
                                    <AiOutlineSend size="20"/> Submit
                                </Button>
                            </div>
                        </Form>
                        </Alert>

                        <hr className="mt-5" />
                        <h4 className="text-primary mt-4" ><AiOutlineBars size="30" /> User Account List</h4>

                        <Formik
                            enableReinitialize={true}
                            initialValues={{ 
                                search: "",
                            }}
                            validationSchema={SearchSchema}
                            onSubmit={handleSearch}
                            >
                                <Form>
                                    <Row>
                                        <Col xs lg="6"  className="bg-white rounded">
                                            <InputGroup className=" mb-3">
                                                {/* <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Search
                                                    </InputGroup.Text>
                                                </InputGroup> */}
                                                    <Field 
                                                        type="search" 
                                                        name="search"
                                                        placeholder="&#x1F50E; Search..."
                                                        maxLength="50"
                                                        className="w-50 form-control rounded-pill"
                                                        style={{border: "1px solid rgb(128, 128, 128)"}}
                                                    >
                                                    </Field>
                                                    <Button type="submit" variant="outline-success" style={{display:'none'}}><BiSearchAlt size="20" className='ms-2 me-2'/></Button>
                                                <ErrorMessage name="search">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </Formik>

                        <Table responsive striped bordered hover size="sm">
                            <thead>
                                <tr className="text-center">
                                    <th>#</th>
                                    <th>Account #</th>
                                    <th>Name</th>
                                    <th>Phase</th>
                                    <th>Block</th>
                                    <th>Lot</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                                {currentItems ? (
                                    <tbody>
                                        {currentItems.map((r, i) => (
                                        <tr key={i} className="align-middle text-secondary">
                                            <td className="text-center align-middle">{i + 1}</td>
                                            <td className="text-center align-middle">{r.account}</td>
                                            <td className="">{r.fname} {r.mi}. {r.lname}</td>
                                            <td className="text-center align-middle">{r.phase}</td>
                                            <td className="text-center align-middle">{r.block}</td>
                                            <td className="text-center align-middle">{r.lot}</td>
                                            <td className="text-center align-middle">
                                                {r.status==1?<strong className='text-success'>Active</strong>:<strong className='text-danger'>Inactive</strong>}
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr><td colSpan={9} className='text-danger'>No user account found...</td></tr>
                                    </tbody>
                                )}
                        </Table>

                        {dataUser?.length && dataUser.length > ITEMS_PER_PAGE && (
                                <Pagination>
                                    <Pagination.First onClick={() => paginate(1)} />
                                    <Pagination.Prev onClick={() => paginate(currentPage - 1)} />

                                    {Array.from({ length: totalPages }).map((_, index) => {
                                        // Show the current page and two pages before and after it
                                        if (index >= currentPage - 2 && index <= currentPage + 2) {
                                        return (
                                            <Pagination.Item
                                            key={index}
                                            active={index + 1 === currentPage}
                                            onClick={() => paginate(index + 1)}
                                            >
                                            {index + 1}
                                            </Pagination.Item>
                                        );
                                        }

                                        return null;
                                    })}

                                    {/* Show ellipsis between Pagination.Prev and Pagination.Next */}
                                    {currentPage + 2 < totalPages && (
                                        <Pagination.Ellipsis key={`ellipsis`} />
                                    )}

                                    <Pagination.Next onClick={() => paginate(currentPage + 1)} />
                                    <Pagination.Last onClick={() => paginate(totalPages)} />
                                </Pagination>
                            )}
                    </Col>
                </Row>
            </div>
        </Formik>
    );
};

export default Create_User;