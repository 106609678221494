import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    InputGroup,
    Button,
    Alert,
    Table,
    Breadcrumb,
    Pagination,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AiOutlineSend, AiOutlineForm, AiOutlineEye, AiOutlineEdit, AiOutlineMessage } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
import SubMenu from '../submenu';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import { createRequest } from './redux/adminUserActions';
import { LoadingPage, CreateUserSuccess, CreateUserFailed, AuthAccessFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import { setSessionVariable } from '../../../components/sessionStorage';
import apiUrl from '../../../components/apiurl';

const SearchSchema = Yup.object().shape({
    search: Yup.string()
        .trim(),
  });

const ViewSchema = Yup.object().shape({
    id: Yup.string()
        .trim(),
  });

  interface UserData {
    id: number;
    account: string;
    fname: string;
    mi: string;
    lname: string;
    phase: string;
    block: string;
    lot: string;
    lot_area: string;
    floor_area: string;
    dues: string;
    cellphone: string;
    email: string;
    status: number;
  }

const ITEMS_PER_PAGE = 30;

const MessageUser: React.FC = () =>{
    const token = getLocalStorageVariable<string>('token');
    const [dataUser, setUser] = useState<UserData[] | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const user_update = getLocalStorageVariable<string>('user_update');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = dataUser?.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    const totalPages = Math.ceil((dataUser?.length || 0) / ITEMS_PER_PAGE);

    const fetchUser = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}admin_get_user`, { headers, params: { search: searchTerm } });
            setUser(res.data.length > 0 ? res.data : null);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [searchTerm, token]);


    useEffect(() => {
        fetchUser();
    }, [searchTerm, fetchUser]);

    const handleView = async(values: any) => {
        const { id } = values;
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<UserData[]> = await axios.post(`${apiUrl.url}admin_update_user_select/`, { id: id }, { headers });
            setSessionVariable('account', res.data[0].account);
            setSessionVariable('fname', res.data[0].fname);
            setSessionVariable('mi', res.data[0].mi);
            setSessionVariable('lname', res.data[0].lname);
            setSessionVariable('phase', res.data[0].phase);
            setSessionVariable('block', res.data[0].block);
            setSessionVariable('lot', res.data[0].lot);
            setSessionVariable('lotarea', res.data[0].lot_area);
            setSessionVariable('floorarea', res.data[0].floor_area);
            setSessionVariable('dues', res.data[0].dues);
            setSessionVariable('cellphone', res.data[0].cellphone);
            setSessionVariable('email', res.data[0].email);
            setSessionVariable('status', res.data[0].status);
            
            navigate('/admin_update_user');
        } catch (error) {
            console.error('Error: ', error);
        }
      };

    const handleSearch = (values: { search: string }) => {
        setSearchTerm(values.search);
        setCurrentPage(1);
    };

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };

    return (
            <div className="container-fluid ">
                {user_update != '1'? <AuthAccessFailed />:""}

                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-primary mb-3" ><AiOutlineMessage size="30" /> Message User</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_user_menu')}>User Menu</Breadcrumb.Item>
                                <Breadcrumb.Item active>Message User</Breadcrumb.Item>
                            </Breadcrumb>
                        <hr/>
                        <div style = {{ width:"400px"}} />
                         <Formik
                            enableReinitialize={true}
                            initialValues={{ 
                                search: "",
                            }}
                            validationSchema={SearchSchema}
                            onSubmit={handleSearch}
                            >
                                <Form>
                                    <Row>
                                        <Col xs lg="6"  className="mt-1 bg-white rounded">
                                            <InputGroup className=" mb-3">
                                                {/* <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Search
                                                    </InputGroup.Text>
                                                </InputGroup> */}
                                                    <Field 
                                                        type="search" 
                                                        name="search"
                                                        placeholder="&#x1F50E; Search..."
                                                        maxLength="50"
                                                        className="w-50 form-control rounded-pill"
                                                        style={{border: "1px solid rgb(128, 128, 128)"}}
                                                    >
                                                    </Field>
                                                    <Button type="submit" variant="outline-success" style={{display:'none'}}><BiSearchAlt size="20" className='ms-2 me-2'/></Button>
                                                <ErrorMessage name="search">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </Formik>

                                <Table responsive striped bordered hover size="sm">
                                    <thead>
                                        <tr className="text-center">
                                            <th>#</th>
                                            <th>Account #</th>
                                            <th>Name</th>
                                            <th>Phase</th>
                                            <th>Block</th>
                                            <th>Lot</th>
                                            <th>Cellphone</th>
                                            <th>Email</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                        {currentItems ? (
                                            <tbody>
                                                {currentItems.map((r, i) => (
                                                <tr key={i} className="align-middle text-secondary">
                                                    <td className="text-center align-middle">{i + 1}</td>
                                                    <td className="text-center align-middle">{r.account}</td>
                                                    <td className="">{r.fname} {r.mi}. {r.lname}</td>
                                                    <td className="text-center align-middle">{r.phase}</td>
                                                    <td className="text-center align-middle">{r.block}</td>
                                                    <td className="text-center align-middle">{r.lot}</td>
                                                    <td className="text-center align-middle">{r.cellphone}</td>
                                                    <td className="">{r.email}</td>
                                                    <td className="text-center align-middle">
                                                        <Formik
                                                            enableReinitialize={true}
                                                            initialValues={{ 
                                                                id: r.id,
                                                            }}
                                                            validationSchema={ViewSchema}
                                                            onSubmit={handleView}
                                                                >
                                                            <Form>
                                                                <Field type="text" name={r.id} value={r.id} hidden></Field>
                                                                <Button type="submit" variant="outline-primary" size="sm" className='me-2 btn btn-block rounded-pill'><AiOutlineMessage /> Chat</Button>
                                                            </Form>
                                                        </Formik>
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                <tr><td colSpan={11} className='text-danger'>No user account found...</td></tr>
                                            </tbody>
                                        )}
                                </Table>

                            {dataUser?.length && dataUser.length > ITEMS_PER_PAGE && (
                                <Pagination>
                                    <Pagination.First onClick={() => paginate(1)} />
                                    <Pagination.Prev onClick={() => paginate(currentPage - 1)} />

                                    {Array.from({ length: totalPages }).map((_, index) => {
                                        // Show the current page and two pages before and after it
                                        if (index >= currentPage - 2 && index <= currentPage + 2) {
                                        return (
                                            <Pagination.Item
                                            key={index}
                                            active={index + 1 === currentPage}
                                            onClick={() => paginate(index + 1)}
                                            >
                                            {index + 1}
                                            </Pagination.Item>
                                        );
                                        }

                                        return null;
                                    })}

                                    {/* Show ellipsis between Pagination.Prev and Pagination.Next */}
                                    {currentPage + 2 < totalPages && (
                                        <Pagination.Ellipsis key={`ellipsis`} />
                                    )}

                                    <Pagination.Next onClick={() => paginate(currentPage + 1)} />
                                    <Pagination.Last onClick={() => paginate(totalPages)} />
                                </Pagination>
                            )}
                    </Col>
                </Row>
            </div>
    );
};

export default MessageUser;