import { Admin_User, Forgot_User } from './loginTypes';

export enum Admin_AuthActionTypes {
    ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST',
    ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS',
    ADMIN_LOGIN_FAILURE = 'ADMIN_LOGIN_FAILURE',
    ADMIN_LOGIN_REDIRECT = 'ADMIN_LOGIN_REDIRECT',
    // Forgot
    ADMIN_FORGOT_REQUEST = 'ADMIN_FORGOT_REQUEST',
    ADMIN_FORGOT_SUCCESS = 'ADMIN_FORGOT_SUCCESS',
    ADMIN_FORGOT_FAILURE = 'ADMIN_FORGOT_FAILURE',
    ADMIN_FORGOT_REDIRECT = 'ADMIN_FORGOT_REDIRECT',
  }

  export type Admin_AuthAction =
    | Admin_LoginRequestAction
    | Admin_LoginSuccessAction
    | Admin_LoginFailureAction
    | Admin_LoginRedirectAction
    | Admin_ForgotRequestAction
    | Admin_ForgotSuccessAction
    | Admin_ForgotFailureAction
    | Admin_ForgotRedirectAction;
  

  interface Admin_LoginRequestAction {
    type: Admin_AuthActionTypes.ADMIN_LOGIN_REQUEST;
    payload: { email: string; password: string; authCode: string };
  }
  
  interface Admin_LoginSuccessAction {
    type: Admin_AuthActionTypes.ADMIN_LOGIN_SUCCESS;
    payload: Admin_User;
  }
  
  interface Admin_LoginFailureAction {
    type: Admin_AuthActionTypes.ADMIN_LOGIN_FAILURE;
    payload: string;
  }

  interface Admin_LoginRedirectAction {
    type: Admin_AuthActionTypes.ADMIN_LOGIN_REDIRECT;
    payload: string;
  }
  
  export const admin_loginRequest = (email: string, password: string, authCode: string): Admin_LoginRequestAction => ({
    type: Admin_AuthActionTypes.ADMIN_LOGIN_REQUEST,
    payload: { email, password, authCode },
  });
  
  export const admin_loginSuccess = (user: Admin_User): Admin_LoginSuccessAction => ({
    type: Admin_AuthActionTypes.ADMIN_LOGIN_SUCCESS,
    payload: user,
  });
  
  export const admin_loginFailure = (error: string): Admin_LoginFailureAction => ({
    type: Admin_AuthActionTypes.ADMIN_LOGIN_FAILURE,
    payload: error,
  });

  export const admin_redirect = (path: string): Admin_LoginRedirectAction => ({
    type: Admin_AuthActionTypes.ADMIN_LOGIN_REDIRECT,
    payload: path,
  });


// Forgot ******************************

  interface Admin_ForgotRequestAction {
    type: Admin_AuthActionTypes.ADMIN_FORGOT_REQUEST;
    payload: Forgot_User;
  }
  
  interface Admin_ForgotSuccessAction {
    type: Admin_AuthActionTypes.ADMIN_FORGOT_SUCCESS;
    payload: Forgot_User;
  }
  
  interface Admin_ForgotFailureAction {
    type: Admin_AuthActionTypes.ADMIN_FORGOT_FAILURE;
    payload: string;
  }

  interface Admin_ForgotRedirectAction {
    type: Admin_AuthActionTypes.ADMIN_FORGOT_REDIRECT;
    payload: string;
  }
  

  export const admin_forgotRequest = (user: Forgot_User): Admin_ForgotRequestAction => ({
    type: Admin_AuthActionTypes.ADMIN_FORGOT_REQUEST,
    payload: user,
  });

  export const admin_forgotSuccess = (user: Forgot_User): Admin_ForgotSuccessAction => ({
    type: Admin_AuthActionTypes.ADMIN_FORGOT_SUCCESS,
    payload: user,
  });
  
  export const admin_forgotFailure = (error: string): Admin_ForgotFailureAction => ({
    type: Admin_AuthActionTypes.ADMIN_FORGOT_FAILURE,
    payload: error,
  });

  export const admin_forgotRedirect = (path: string): Admin_ForgotRedirectAction => ({
    type: Admin_AuthActionTypes.ADMIN_FORGOT_REDIRECT,
    payload: path,
  });