import { InfoState } from './adminHeadersTypes';
import {  AdminUpdateUserAction, ActionTypes } from './adminHeadersActions';

const initialState: InfoState = {
  info: null,
  loading: false,
  error: null,
  redirectPath: null,
};

const createReducer = (state = initialState, action: AdminUpdateUserAction): InfoState => {
  switch (action.type) {
  // ***** Create Admin User *****
    case ActionTypes.ADMIN_UPDATE_ADMIN_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case ActionTypes.ADMIN_UPDATE_ADMIN_USER_SUCCESS:
      return { ...state, info: action.payload, loading: false, error: null };
    case ActionTypes.ADMIN_UPDATE_ADMIN_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionTypes.ADMIN_UPDATE_ADMIN_USER_REDIRECT:
      return {...state, redirectPath: action.payload };
  // ***** Access Settings *****
    // case ActionTypes.ADMIN_CREATE_AUTH_REQUEST:
    //   return { ...state, loading: true, error: null };
    // case ActionTypes.ADMIN_CREATE_AUTH_SUCCESS:
    //   return { ...state, info: action.payload, loading: false, error: null };
    // case ActionTypes.ADMIN_CREATE_AUTH_FAILURE:
    //   return { ...state, loading: false, error: action.payload };
    // case ActionTypes.ADMIN_CREATE_AUTH_REDIRECT:
    //   return {...state, redirectPath: action.payload };
    default:
      return state;
  }
};

export default createReducer;