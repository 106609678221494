import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Row,
  InputGroup,
  Alert,
  Col
} from 'react-bootstrap';
import { FaQuestion } from "react-icons/fa6";
import { AiOutlineMail } from "react-icons/ai";
import { TbLetterCase } from "react-icons/tb";
import { AiOutlineSend } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { forgotRequest } from './redux/loginActions';
import logo from '../../../images/linao.png';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import { LoadingPage, ForgotResetFailed, ForgotResetSuccess } from './loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import map from '../../../images/casa1.jpg';

const LoginSchema = Yup.object().shape({
    email: Yup
        .string()
        .email('Invalid email')
        .required('Email is required!'),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
    security: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .required("Please select security question."),
    answer: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .required("Answer is required."),
});

const Forgot: React.FC = () => {
  const loading = useSelector((state: RootState) => state.LoginReducer.loading);
  const success = useSelector((state: RootState) => state.LoginReducer.user);
  const failed = useSelector((state: RootState) => state.LoginReducer.error);
  
  const [randomString, setRandomString] = useState<string>('');
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const generateRandomString = (length: number): string => {
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };
  useEffect(() => {
      const generatedString = generateRandomString(6);
      setRandomString(generatedString);
  }, []);


  useEffect(() => {
    const userId = getLocalStorageVariable<string>('userId');
    const userAuth = getLocalStorageVariable<string>('userAuth');
    if (userId && userAuth!='client') {
      navigate('/admin_dashboard'); 
    }
  }, [navigate, dispatch]);

  const handleSubmit = (values: any) => {
    const payload = {
        email: values.email,
        security: values.security,
        answer: values.answer,
        password: randomString,
      }
      dispatch(forgotRequest(payload));
    };

  return (
    <Formik
      initialValues={{ 
        email: '', 
        security: "", 
        answer: "",        
        confirm: false, 
      }}
      validationSchema={LoginSchema}
      onSubmit={handleSubmit}
    >
      <Container>
        {loading?<LoadingPage/>:""}
        {failed?<ForgotResetFailed/>:""}
        {success?<ForgotResetSuccess/>:""}
        
        <Row className="justify-content-md-center">
          <Col sm lg="5" className=" bg-white">
            <img
              src={map}
              alt="Subdivision Map"
              useMap="#housesMap"
              width="100%"
            />
          </Col>
          <Col sm lg="2" className=" bg-white">
            
          </Col>
          <Col sm lg="5" className="my-3 p-3 bg-white rounded shadow">
              <div className="text-info text-center mt-3"> <img src={`${logo}`} alt="" width="180" height="110"/></div>
              <hr/>
              
              <Alert variant="warning">
                  <Alert.Heading><RiLockPasswordLine /> Forgot Password</Alert.Heading>
                    <hr />
                    <p className="mb-0">
                        To reset your password enter your email address. Please check your email account for your temporary password.
                    </p>
              </Alert>
                <Form>
                 
                  <Row>
                      <Col sm>   
                          <InputGroup className="mt-3 mb-1">
                            <InputGroup className="w-25">
                              <InputGroup.Text className="w-100">
                                <div className="text-center w-100"><AiOutlineMail size="25"/></div>
                              </InputGroup.Text>
                            </InputGroup>
                              <Field 
                                type="email" 
                                name="email" 
                                placeholder="E-mail"
                                className="w-75 form-control"
                              />
                            <ErrorMessage name="email">
                              {msg => <div style={{color:'red',padding:'5px', margin:'3px'}}>{msg}</div>}
                            </ErrorMessage>
                          </InputGroup>
                      </Col>
                    </Row>

                    <Row>
                        <Col sm>   
                            <InputGroup className="mt-1 mb-1">
                                <InputGroup className="w-25">
                                    <InputGroup.Text className="w-100">
                                        <div className="text-center w-100"><FaQuestion size="25"/></div>
                                    </InputGroup.Text>
                                </InputGroup>
                                    <Field 
                                        as="select" 
                                        name="security" 
                                        maxLength="10"
                                        placeholder="Account Number"
                                        className="w-75 form-control"
                                    >
                                        <option value="">- Security Question -</option>
                                        <option value="city">In what city were you born?</option>
                                        <option value="pet">What is the name of your favorite pet?</option>
                                        <option value="maiden">What is your mother's maiden name?</option>
                                        <option value="high">What high school did you attend?</option>
                                        <option value="elementary">What was the name of your elementary school?</option>
                                        <option value="car">What was the make of your first car?</option>
                                        <option value="favorite">What was your favorite food as a child?</option>
                                        <option value="spouse">Where did you meet your spouse?</option>
                                        <option value="born">What year was your father or mother born?</option>
                                    </Field>
                                <ErrorMessage name="security">
                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                </ErrorMessage>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                      <Col sm>   
                          <InputGroup className="mt-1 mb-1">
                            <InputGroup className="w-25">
                              <InputGroup.Text className="w-100">
                                <div className="text-center w-100"><TbLetterCase size="25"/></div>
                              </InputGroup.Text>
                            </InputGroup>
                              <Field 
                                type="text" 
                                name="answer" 
                                placeholder="Answer"
                                className="w-75 form-control"
                              />
                            <ErrorMessage name="answer">
                              {msg => <div style={{color:'red',padding:'5px', margin:'3px'}}>{msg}</div>}
                            </ErrorMessage>
                          </InputGroup>
                      </Col>
                    </Row>
                    <br/>
                    <br/>
                        <Col sm> 
                            <label>
                                <Field type="checkbox" name="confirm" />
                                &nbsp;Please confirm to reset.
                            </label>
                            <ErrorMessage name="confirm">
                                {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                            </ErrorMessage>
                        </Col>
                    <br/>
                  <div className="d-grid gap-2">
                      <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-5" >
                          <AiOutlineSend size="20"/> Reset
                      </Button>
                  </div>
                </Form>
          </Col>
        </Row>
      </Container>
    </Formik>
  );
};

export default Forgot;
