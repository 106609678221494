import React from 'react';
import { 
    Row, 
    Col,
    Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineFilePpt, AiOutlineGroup, AiOutlineUser, AiOutlineUserDelete, AiOutlineForm, AiOutlineSolution } from "react-icons/ai";
import { AiOutlineDollar } from "react-icons/ai";
import { FaBalanceScale } from "react-icons/fa";
import { MdOutlineBalance } from "react-icons/md";
import { BsMegaphone } from "react-icons/bs";
import { PiFolderUser } from "react-icons/pi";
import { GrDocumentUser } from "react-icons/gr";
import { MdOutlineMenuBook } from "react-icons/md";
import { BsCreditCard2Back } from "react-icons/bs";
import { PiBookOpenText } from "react-icons/pi";
import { TbCalendarDue } from "react-icons/tb";
import { IoWaterOutline } from "react-icons/io5";
import { RiFolderUserLine } from "react-icons/ri";
import { MdOutlinePayments } from "react-icons/md";
import SubMenu from '../submenu';


const ReportMenu: React.FC = () =>{
    return (
        <div className="container-fluid">
            <Row className="justify-content-md-center">
                <Col sm={2}>
                    <SubMenu/>
                </Col>
                <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                    <h4 className="text-primary" ><AiOutlineFilePpt size="30" /> Report Menu</h4>
                    <hr/>
                    <div style = {{ width:"400px"}} ></div>
                    <Row xs={1} md={4} className="g-4">
                        <Col>
                            <Link to="/admin_create_user" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                    <Card.Title className='text-primary text-center'><PiFolderUser size="90" /><br/><br/>User Report</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_searchupdate" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                    <Card.Title className='text-primary text-center'><AiOutlineGroup  size="90" /><br/><br/>Association Bill</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_deleteuser" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                    <Card.Title className='text-primary text-center'><IoWaterOutline size="90" /><br/><br/>Water Bill</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_searchactivateuser" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                    <Card.Title className='text-primary text-center'><AiOutlineDollar size="90" /><br/><br/>Payment</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_searchactivateuser" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                    <Card.Title className='text-primary text-center'><MdOutlineBalance size="90" /><br/><br/>Balance</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_searchactivateuser" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                    <Card.Title className='text-primary text-center'><TbCalendarDue size="90" /><br/><br/>Over Dues</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_searchactivateuser" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                    <Card.Title className='text-primary text-center'><PiBookOpenText size="90" /><br/><br/>Ledger</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/bills_ad_userlist" style={{ textDecoration: 'none'}}>
                                <Card className="mb-3 rounded shadow-lg justify-content-between align-items-center">
                                    <Card.Body >
                                    <Card.Title className='text-primary text-center'><BsCreditCard2Back  size="90" /><br/><br/>System Charges</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default ReportMenu;