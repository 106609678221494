import React from 'react';  
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { AxiosResponse } from 'axios';
import {
    Button,
    Container,
    Row,
    InputGroup,
    Alert,
    Col
} from 'react-bootstrap';
import { AiOutlineSend } from "react-icons/ai";
import { BiSolidUserAccount } from "react-icons/bi";
import { BiSearchAlt } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import { activate_searchRequest, activate_updateRequest } from './redux/activateActions';
import { LoadingPage, ActivateFailed, ActivateSuccess, ActivateSearchFailed } from '../../../components/loader';
import { getSessionVariable } from '../../../components/sessionStorage';
import apiUrl from '../../../components/apiurl';

const SearchSchema = Yup.object().shape({
    account: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .required("Account # is required"),
  });

const CreateSchema = Yup.object().shape({
    fname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .required("Firstname is required"),
    mi: Yup.string()
        .trim()
        .max(1, 'Must be 1 character only')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only"),
    lname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .required("Lastname is required."),
    phase: Yup.string()
        .trim()
        .required("Phase is required."),
    block: Yup.string()
        .trim()
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Block number is required."),
    lot: Yup.string()
        .trim()
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Lot number is required."),
    security: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .required("Security question is required"),
    answer: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .required("Answer is required"),
    cellphone: Yup.string()
        .trim()
        .matches(/^[0-9]{11}$/, "Please input valid cellphone # ex: 09XXXXXXXX.")
        .required("Cellphone number is required."),
    email: Yup.string()
        .trim()
        .email("Enter a valid email.")
        .required("Email address is required.")
        .test('email-exists', 'Email already exists', async function (value) {
            try {
                const response: AxiosResponse = await axios.post(`${apiUrl.url}check_email/`, {email: value});
                    if (response.data.msg) {
                        return false; // Email is considered as already taken
                    }
                return !response.data.exists;
            } catch (error) {
              console.error('Error checking email duplication:', error);
              return false;
            }
          }),
    password: Yup.string()
        .required("Password is required.")
        .min(6, "Password length must be at least 6 characters."),
    confirm_password: Yup.string()
        .required("Confirm Password is required")
        .min(6, "Password length must be at least 6 characters.")
        .oneOf([Yup.ref("password")], "Passwords do not match."),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });


const Activation: React.FC = () =>{
    const loading = useSelector((state: RootState) => state.User_ActivateReducer.loading);
    const success = useSelector((state: RootState) => state.User_ActivateReducer.redirectPath);
    const failed = useSelector((state: RootState) => state.User_ActivateReducer.error);
    const searchfailed = useSelector((state: RootState) => state.User_ActivateReducer.searcherror);
    const dispatch = useDispatch();
    const accountSession = getSessionVariable<any>('account');
    const fname = getSessionVariable<string>('fname');
    const mi = getSessionVariable<string>('mi');
    const lname = getSessionVariable<string>('lname');
    const phase = getSessionVariable<string>('phase');
    const block = getSessionVariable<string>('block');
    const lot = getSessionVariable<string>('lot');

    const handleSearch = (values: any) => {
        const { account } = values;
        dispatch(activate_searchRequest(account));
      };

    const handleSubmit = (values: any) => {
        if(accountSession==''){
            console.log('Search for user account.')
        }else{
            const payload = {
                account: accountSession,
                security: values.security,
                answer: values.answer,
                email: values.email,
                password: values.password,
                cellphone: values.cellphone,
            }
            dispatch(activate_updateRequest(payload));
        }
      };

  return (
    <Container>
        {loading?<LoadingPage/>:""}
        {success?<ActivateSuccess/>:""}
        {failed?<ActivateFailed/>:""}
        {searchfailed?<ActivateSearchFailed/>:""}

        <Row className="justify-content-md-center">
            <Col className="my-3 p-3 bg-white rounded shadow-lg">
                <h4 className="text-info" ><BiSolidUserAccount size="30" /> Account Activation</h4>
                <hr/>
                <div style = {{ width:"400px"}} />
                To activate your account. Search account # then input required details below.
                <Formik
                    initialValues={{ 
                        account: accountSession || '',
                    }}
                    validationSchema={SearchSchema}
                    onSubmit={handleSearch}
                    >
                    <Form>
                        <Row>
                            <Col sm>   
                                <InputGroup className="mt-3 mb-1">
                                    <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Account # <strong className='text-danger'>&nbsp;*</strong>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Field 
                                        type="text" 
                                        name="account" 
                                        id="account" 
                                        maxLength="10"
                                        placeholder="Account #"
                                        className="w-50 form-control"
                                    />
                                    <Button type="submit" variant="outline-success"><BiSearchAlt size="20" className='ms-2 me-2'/></Button>
                                    <ErrorMessage name="account">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </InputGroup>
                            </Col>
                            <Col sm>
                            </Col>
                        </Row>
                    </Form>
                </Formik>


                <Formik
                    initialValues={{
                        fname: fname || '',
                        mi: mi || '',
                        lname: lname || '',
                        phase: phase || '',
                        block: block || '',
                        lot: lot || '',
                        security: "",
                        answer: "",
                        cellphone: "",
                        email: "",
                        password: "",
                        confirm_password: "",
                        confirm: false,
                    }}
                    validationSchema={CreateSchema}
                    onSubmit={handleSubmit}
                    >
                    <Form>
                        <Alert style={{border: accountSession?"1px solid rgb(0, 128, 0)":"1px solid rgb(128, 128, 128)", backgroundColor: '#e6ffe3'}} className='mt-2'>
                        <Row>
                            <Col sm>   
                                <InputGroup className="mt-1 mb-1">
                                    <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Firstname 
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Field 
                                        type="text" 
                                        name="fname"
                                        maxLength="30"
                                        className="w-75 form-control"
                                        disabled={true}
                                    />
                                    <ErrorMessage name="fname">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </InputGroup>
                            </Col>
                            <Col sm>   
                                <InputGroup className="mt-1 mb-1">
                                    <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            MI
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Field 
                                        type="text" 
                                        name="mi" 
                                        maxLength="1"
                                        className="w-75 form-control"
                                        disabled={true}
                                    />
                                    <ErrorMessage name="mi">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </InputGroup>
                            </Col>
                            <Col sm>   
                                <InputGroup className="mt-1 mb-1">
                                    <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Lastname 
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Field 
                                        type="text" 
                                        name="lname" 
                                        maxLength="30"
                                        className="w-75 form-control"
                                        disabled={true}
                                    />
                                    <ErrorMessage name="lname">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm>   
                                <InputGroup className="mt-1 mb-1">
                                    <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Phase
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Field 
                                        type="text" 
                                        name="phase" 
                                        maxLength="5"
                                        className="w-75 form-control"
                                        disabled={true}
                                    />
                                    <ErrorMessage name="phase">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </InputGroup>
                            </Col>
                            <Col sm>   
                                <InputGroup className="mt-1 mb-1">
                                    <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Block 
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Field 
                                        type="text" 
                                        name="block" 
                                        maxLength="5"
                                        className="w-75 form-control"
                                        disabled={true}
                                    />
                                    <ErrorMessage name="block">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </InputGroup>
                            </Col>
                            <Col sm>   
                                <InputGroup className="mt-1 mb-1">
                                    <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Lot 
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Field 
                                        type="text" 
                                        name="lot" 
                                        maxLength="5"
                                        className="w-75 form-control"
                                        disabled={true}
                                    />
                                    <ErrorMessage name="lot">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </InputGroup>
                            </Col>
                        </Row>
                        </Alert>

                        <Row>
                            <Col sm>   
                                <InputGroup className="mt-3 mb-1">
                                    <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Security <strong className='text-danger'>&nbsp;*</strong>
                                        </InputGroup.Text>
                                    </InputGroup>
                                        <Field 
                                            as="select" 
                                            name="security" 
                                            maxLength="10"
                                            placeholder="Account Number"
                                            className="w-75 form-control"
                                            // disabled={true}
                                        >
                                            <option value="">- Security Question -</option>
                                            <option value="city">In what city were you born?</option>
                                            <option value="pet">What is the name of your favorite pet?</option>
                                            <option value="maiden">What is your mother's maiden name?</option>
                                            <option value="high">What high school did you attend?</option>
                                            <option value="elementary">What was the name of your elementary school?</option>
                                            <option value="car">What was the make of your first car?</option>
                                            <option value="favorite">What was your favorite food as a child?</option>
                                            <option value="spouse">Where did you meet your spouse?</option>
                                            <option value="born">What year was your father or mother born?</option>
                                        </Field>
                                    <ErrorMessage name="security">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </InputGroup>
                            </Col>
                            <Col sm>   
                                <InputGroup className="mt-3 mb-1">
                                    <InputGroup className="w-25">
                                    <InputGroup.Text className="w-100">
                                        Answer <strong className='text-danger'>&nbsp;*</strong>
                                    </InputGroup.Text>
                                    </InputGroup>
                                    <Field 
                                        type="text" 
                                        name="answer" 
                                        placeholder="Answer"
                                        className="w-75 form-control"
                                    />
                                    <ErrorMessage name="answer">
                                    {msg => <div style={{color:'red',padding:'5px', margin:'3px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm>   
                                <InputGroup className="mt-3 mb-1">
                                    <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Cellphone <strong className='text-danger'>&nbsp;*</strong>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Field 
                                        type="text" 
                                        name="cellphone" 
                                        maxLength="11"
                                        placeholder="Cellphone #"
                                        className="w-75 form-control"
                                    />
                                    <ErrorMessage name="cellphone">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </InputGroup>
                            </Col>
                            <Col sm>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm>   
                                <InputGroup className="mt-3 mb-1">
                                    <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Email <strong className='text-danger'>&nbsp;*</strong>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Field 
                                        type="email" 
                                        name="email" 
                                        placeholder="Email"
                                        className="w-75 form-control"
                                    />
                                    <ErrorMessage name="email">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </InputGroup>
                            </Col>
                            <Col sm>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm>   
                                <InputGroup className="mt-3 mb-1">
                                    <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Password <strong className='text-danger'>&nbsp;*</strong>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Field 
                                        type="password" 
                                        name="password" 
                                        placeholder="Password"
                                        className="w-75 form-control"
                                    />
                                    <ErrorMessage name="password">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </InputGroup>
                            </Col>
                            <Col sm>   
                                <InputGroup className="mt-3 mb-1">
                                    <InputGroup className="w-25">
                                        <InputGroup.Text className="w-100">
                                            Confirm <strong className='text-danger'>&nbsp;*</strong>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Field 
                                        type="password" 
                                        name="confirm_password" 
                                        placeholder="Confirm Password"
                                        className="w-75 form-control"
                                    />
                                    <ErrorMessage name="confirm_password">
                                        {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                    </ErrorMessage>
                                </InputGroup>
                            </Col>
                        </Row>

                        <br/>
                        <br/>
                            <Col sm> 
                                <label>
                                    <Field type="checkbox" name="confirm" />
                                    &nbsp;Please confirm to activate your account.
                                </label>
                                <ErrorMessage name="confirm">
                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                </ErrorMessage>
                            </Col>
                        <br/>
                        <div className="d-grid gap-2">
                            <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-5" >
                                <AiOutlineSend size="20"/> Activate
                            </Button>
                        </div>
                    </Form>
                </Formik>
            </Col>
        </Row>
    </Container>
  );
}

export default Activation;