import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    InputGroup,
    Button,
    Alert,
    Breadcrumb,
} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AiOutlineSend } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
import { SlDoc } from "react-icons/sl";
import { IoWaterOutline } from "react-icons/io5";
import SubMenu from '../submenu';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSessionVariable, getSessionVariable } from '../../../components/sessionStorage';
import { AuthAccessFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import apiUrl from '../../../components/apiurl';
// import { associationRequest, associationSearchRequest } from './redux/adminAssociationActions';
import { RootState } from '../../../redux/reducers';
import { LoadingPage, CreateAssociationFailed, CreateAssociationSuccess, ActivateSearchFailed } from '../../../components/loader';

  
const SearchSchema = Yup.object().shape({
    search: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .required("Account # is required"),
    bill_no: Yup.string()
        .trim()
        .max(10, 'Must be 10 characters or less')
        .required("Memo # is required"),
  });

const CreateSchema = Yup.object().shape({
    fname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .required("Firstname is required"),
    mi: Yup.string()
        .trim()
        .max(1, 'Must be 1 character only')
        .matches(/^([a-z\s A-Z])+$/, "Valid characters from A-Z only"),
    lname: Yup.string()
        .trim()
        .max(30, 'Must be 30 characters or less')
        .required("Lastname is required."),
    phase: Yup.string()
        .trim()
        .required("Phase is required."),
    block: Yup.string()
        .trim()
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Block number is required."),
    lot: Yup.string()
        .trim()
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Lot number is required."),
        fdate: Yup.string()
        .required("From date is required")
        .test('isValidDate', 'Invalid date', function (value) {
            if (!value) {
              return true;
            }
            const parsedDate = new Date(value);
            return !isNaN(parsedDate.getTime());
          }),
    tdate: Yup.string()
        .required("To date is required")
        .test('isValidDate', 'Invalid date', function (value) {
            if (!value) {
              return true;
            }
            const parsedDate = new Date(value);
            return !isNaN(parsedDate.getTime());
          })
          .test('isAfterFDate', 'To date should be after From date', function (value) {
            if (!value || !this.parent.fdate) {
              return true;
            }
            return new Date(value) >= new Date(this.parent.fdate);
          }),
    cdate: Yup.string()
        .required("Bill date is required")
        .test('isValidDate', 'Invalid date', function (value) {
            if (!value) {
              return true;
            }
            const parsedDate = new Date(value);
            return !isNaN(parsedDate.getTime());
          }),
    accounting: Yup.string()
        .max(5, 'Must be 5 characters or less')
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Accounting is required."),
    manager: Yup.string()
        .max(5, 'Must be 5 characters or less')
        .matches(/^\d+$/, "Please input valid numbers.")
        .required("Manager is required."),
    total_amount: Yup.string()
        .trim()
        .max(20, 'Must be 20 characters or less')
        .test('is-not-negative', 'Amount cannot be negative value or invalid.', value => {
            if (typeof value === 'string') {
              const numericValue = parseFloat(value);
              return !isNaN(numericValue) && numericValue >= 0;
            }
            return false; 
          })
        .required("Amount to apply is required."),
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });

  interface SystemData {
    user_acct_no: number;
    charge_amount: number;
    association_no: number;
    memo_no: number;
  }

  interface AdminData {
    id: number;
    auth: string;
    fname: string;
    mi: string;
    lname: string;
  }

const Water_Single: React.FC = () =>{
    const loading = useSelector((state: RootState) => state.Admin_AssociationReducer.loading);
    const success = useSelector((state: RootState) => state.Admin_AssociationReducer.redirectPath);
    const failed = useSelector((state: RootState) => state.Admin_AssociationReducer.error);
    const searchfailed = useSelector((state: RootState) => state.Admin_AssociationReducer.searcherror);
    const token = getLocalStorageVariable<string>('token');
    const [systemData, setSystemData] = useState<SystemData[] | null>(null);
    const [adminData, setAdminData] = useState<AdminData[] | null>(null);
    const [cDate, setCDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');


    const user_id = getSessionVariable<any>('user_id');
    const accountSession = getSessionVariable<any>('account');
    const fname = getSessionVariable<string>('fname');
    const mi = getSessionVariable<string>('mi');
    const lname = getSessionVariable<string>('lname');
    const phase = getSessionVariable<string>('phase');
    const block = getSessionVariable<string>('block');
    const lot = getSessionVariable<string>('lot');
    const lot_area = getSessionVariable<number>('lot_area');
    const dues = getSessionVariable<number>('dues');
    const email = getSessionVariable<string>('email');
    const cellphone = getSessionVariable<string>('cellphone');
    const id = '1';
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const association_create = getLocalStorageVariable<string>('association_create');

    // ***** Fetch System Data *****
    const fetchSystemData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<SystemData[]> = await axios.post(`${apiUrl.url}admin_get_system_account/`, { id: id }, { headers });
            setSystemData(res.data);
        } catch (error) {
            console.error('Error: ', error);
            return false;
        }
    }, [id, token]);

    // ***** Admin User Data *****
    const fetchAdminUser = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<AdminData[]> = await axios.get(`${apiUrl.url}admin_get_admin_user`, { headers, params: { search: searchTerm } });
            setAdminData(res.data.length > 0 ? res.data : null);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [searchTerm, token]);

    useEffect(() => {
        fetchSystemData();
        fetchAdminUser();
    }, [ fetchSystemData, fetchAdminUser ]);


    // **** Set current date ******
    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = formatDate(currentDate);
        setCDate(formattedDate);
    }, []); 

    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    // **** Total Amount ******
    const total_amount = ((lot_area ? lot_area : 0) * (dues ? dues : 0)) + (systemData ? Number(systemData[0]?.charge_amount) : 0);

    const handleDateChange = (event: any) => {
        const newDate = event.target.value;
            setCDate(newDate);
      };

    const handleSearch = (values: any) => {
        const { search } = values;
        // dispatch(associationSearchRequest(search));
      };
    
    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };

    const handleSubmit = (values: any) => {
        const payload = {
            association_no: systemData ? Number(systemData[0]?.association_no + 1) : 0,
            user_id: user_id || '',
            account: accountSession || '',
            fname: fname || '',
            mi: mi || '',
            lname: lname || '',
            phase: phase || '',
            block: block || '',
            lot: lot || '',
            email: email || '',
            cellphone: cellphone || '',
            lot_area: lot_area || 0,
            dues: dues || 0,
            charge: systemData ? Number(systemData[0]?.charge_amount) : 0,
            date_from: values.fdate,
            date_to: values.tdate,
            cdate: values.cdate,
            total_amount: values.total_amount,
            accounting_id: values.accounting,
            manager_id: values.manager,
        };
        // dispatch(associationRequest(payload));
    };

    return (
        <div>
            {loading?<LoadingPage/>:""}
            {success?<CreateAssociationSuccess/>:""}
            {failed?<CreateAssociationFailed/>:""}
            {searchfailed?<ActivateSearchFailed/>:""}
            {association_create != '1'? <AuthAccessFailed />:""}

            <div className="container-fluid ">
                <Row className="justify-content-md-center">
                    <Col>
                        <SubMenu/>
                    </Col>
                    <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                        <h4 className="text-info" ><IoWaterOutline size="30" /> Water Bill Single Entry</h4>
                            <Breadcrumb>
                                <Breadcrumb.Item onClick={() => handleItemClick('/admin_water_menu')}>Water Menu</Breadcrumb.Item>
                                <Breadcrumb.Item active>Single Entry</Breadcrumb.Item>
                            </Breadcrumb>
                            <hr/>
                        <div style = {{ width:"400px"}} />
                        Encoding Association Bill one by one
                        <Alert style={{border: "1px solid rgb(128, 128, 128)", backgroundColor: '#d4fcfc'}} className='mt-2 shadow-lg'>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{ 
                                    search: accountSession || '',
                                    bill_no: systemData ? Number(systemData[0]?.association_no + 1) : "",
                                }}
                                validationSchema={SearchSchema}
                                onSubmit={handleSearch}
                                >
                                <Form>
                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Account # 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="search" 
                                                    maxLength="10"
                                                    placeholder="Account #"
                                                    className="w-50 form-control"
                                                />
                                                <Button type="submit" variant="outline-success"><BiSearchAlt size="20" className='ms-2 me-2'/></Button>
                                                <ErrorMessage name="search">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Bill #
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="bill_no" 
                                                    maxLength="10"
                                                    placeholder="Credit Memo"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="bill_no">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm> </Col>
                                    </Row>
                                </Form>
                            </Formik>
                            
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    fname: fname || '',
                                    mi: mi || '',
                                    lname: lname || '',
                                    phase: phase || '',
                                    block: block || '',
                                    lot: lot || '',
                                    cdate: cDate,
                                    fdate: "",
                                    tdate: "",
                                    accounting: "",
                                    manager: "",
                                    lot_area: (lot_area || '0').toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                                    dues: (dues || '0').toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                                    charge: (systemData ? Number(systemData[0]?.charge_amount) : 0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                                    total_amount: total_amount.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                                    confirm: false,
                                }}
                                validationSchema={CreateSchema}
                                onSubmit={handleSubmit}
                                >
                                <Form>
                                    <Alert style={{border: "1px solid rgb(128, 128, 128)"}} className='mt-2 bg-white'>
                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Firstname 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="fname"
                                                    maxLength="30"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="fname">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        MI
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="mi" 
                                                    maxLength="1"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="mi">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Lastname 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="lname" 
                                                    maxLength="30"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="lname">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Phase
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="phase" 
                                                    maxLength="5"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="phase">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Block 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="block" 
                                                    maxLength="5"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="block">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Lot 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="lot" 
                                                    maxLength="5"
                                                    className="w-75 form-control"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="lot">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                {/* </Alert>

                                <Alert style={{border: "1px solid rgb(128, 128, 128)"}} className='mt-2 bg-light'> */}
                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Lot Area
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="lot_area" 
                                                    maxLength="11"
                                                    placeholder="0.00"
                                                    className="w-75 form-control text-end"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="lot_area">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Dues
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="dues" 
                                                    maxLength="11"
                                                    placeholder="0.00"
                                                    className="w-75 form-control text-end"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="dues">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm>
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Charge
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="charge" 
                                                    maxLength="11"
                                                    placeholder="0.00"
                                                    className="w-75 form-control text-end"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="charge">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>

                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Total Amount 
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="text" 
                                                    name="total_amount" 
                                                    maxLength="11"
                                                    placeholder="0.00"
                                                    className="w-75 form-control text-end"
                                                    disabled={true}
                                                />
                                                <ErrorMessage name="total_amount">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Alert>

                                Coverage Date - {`[ Date of the Bill, From, and To ]`}
                                    <Row className="mt-1">
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Date <strong className='text-danger'>&nbsp;*</strong>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="date" 
                                                    name="cdate"
                                                    className="w-75 form-control text-center"
                                                    onChange={handleDateChange}
                                                />
                                                <ErrorMessage name="cdate">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        From <strong className='text-danger'>&nbsp;*</strong>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="date" 
                                                    name="fdate"
                                                    className="w-75 form-control text-center"
                                                />
                                                <ErrorMessage name="fdate">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        To <strong className='text-danger'>&nbsp;*</strong>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                <Field 
                                                    type="date" 
                                                    name="tdate" 
                                                    className="w-75 form-control text-center"
                                                />
                                                <ErrorMessage name="tdate">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Accounting <strong className='text-danger'>&nbsp;*</strong>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                    <Field 
                                                        as="select" 
                                                        name="accounting"
                                                        className="w-75 form-control"
                                                    >
                                                        <option value="">- Select Option -</option>
                                                        {adminData?.map((r, i) => (
                                                            r.auth === 'accnt' && (
                                                                <option key={i} value={r.id}>
                                                                    {r.fname} {r.mi} {r.lname}
                                                                </option>
                                                            )
                                                        ))}
                                                    </Field>
                                                <ErrorMessage name="accounting">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm>   
                                            <InputGroup className="mt-1 mb-1">
                                                <InputGroup className="w-25">
                                                    <InputGroup.Text className="w-100">
                                                        Manager <strong className='text-danger'>&nbsp;*</strong>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                    <Field 
                                                        as="select" 
                                                        name="manager"
                                                        className="w-75 form-control"
                                                    >
                                                        <option value="">- Select Option -</option>
                                                        {adminData?.map((r, i) => (
                                                            r.auth === 'manager' && (
                                                                <option key={i} value={r.id}>
                                                                    {r.fname} {r.mi} {r.lname}
                                                                </option>
                                                            )                                            
                                                        ))}
                                                    </Field>
                                                <ErrorMessage name="manager">
                                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                    <br/>
                                        <Col sm> 
                                            <label>
                                                <Field type="checkbox" name="confirm" />
                                                &nbsp;Please confirm to save association bill.
                                            </label>
                                            <ErrorMessage name="confirm">
                                                {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                            </ErrorMessage>
                                        </Col>
                                    <br/>
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-3" >
                                            <AiOutlineSend size="20"/> Save
                                        </Button>
                                    </div>
                                </Form>
                            </Formik>
                        </Alert>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Water_Single;