
import React, { useState, ChangeEvent, useCallback, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { 
    Row, 
    Col,
    Button,
    Alert,
    Breadcrumb,
    InputGroup,
    Table,
    Pagination,
} from 'react-bootstrap';
import { RootState } from '../../../redux/reducers';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import SubMenu from '../submenu';
import apiUrl from '../../../components/apiurl';
import { FiUploadCloud, FiDownloadCloud } from "react-icons/fi";
import { AiOutlineSend, AiOutlineUserAdd, AiOutlineBars } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
import { RiFileExcel2Line } from "react-icons/ri";
import { PiUsersThree } from "react-icons/pi";
import { LoadingPage, CreateUserSuccess, CreateUserFailed, AuthAccessFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import { setSessionVariable } from '../../../components/sessionStorage';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createRequest } from './redux/adminUserActions';
import * as XLSX from 'xlsx';

const SearchSchema = Yup.object().shape({
    search: Yup.string()
        .trim(),
  });

const CreateSchema = Yup.object().shape({
    confirm: Yup
        .bool()
        .oneOf([true], "Please confirm.")
        .required("Please confirm."),
  });

  interface SystemData {
    user_acct_no: number;
  }

  interface UserData {
    id: number;
    account: string;
    fname: string;
    mi: string;
    lname: string;
    phase: string;
    block: string;
    lot: string;
    status: number;
  }

const ITEMS_PER_PAGE = 50;

const Uplaod_Users: React.FC = () =>{
    const failed = useSelector((state: RootState) => state.Admin_CreateUserReducer.error);
    const loading = useSelector((state: RootState) => state.Admin_CreateUserReducer.loading);
    const success = useSelector((state: RootState) => state.Admin_CreateUserReducer.info);
    const token = getLocalStorageVariable<string>('token');
    // const [file, setFile] = useState<File | null>(null);
    // const [loading, setLoading] = useState('');
    // const [success, setSuccess] = useState('');
    // const [failed, setFailed] = useState('');
    const user_create = getLocalStorageVariable<string>('user_create');
    // const [data, setData] = useState<UserAccount[] | null>(null);
    const [dataUser, setUser] = useState<UserData[] | null>(null);
    const [systemData, setSystemData] = useState<SystemData[] | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const id = '1';

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentItems = dataUser?.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
    const totalPages = Math.ceil((dataUser?.length || 0) / ITEMS_PER_PAGE);

    // ***** get system data ******
    const fetchSystemData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };
        try {
            const res: AxiosResponse<SystemData[]> = await axios.post(`${apiUrl.url}admin_get_system_account/`, { id: id }, { headers });
            setSystemData(res.data);
        } catch (error) {
            console.error('Error: ', error);
            return false;
        }
    }, [id, token]);

    useEffect(() => {
        fetchSystemData();
    }, [ fetchSystemData ]);


    // ****** Download Template ******
    const downloadExcel = async () => {
        try {
          const response = await axios.get(`${apiUrl.url}admin_user_template_download`, {
            headers: {
                Authorization: `${token}`,
            },
            responseType: 'blob',
          });
    
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'User_template.xlsx';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading Excel file:', error);
        }
      };


    // ***** upload the excel template ******
    const [tableData, setTableData] = useState<any[][]>([]);

      const readExcelFile = (file: File): Promise<any[][]> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
    
          reader.onload = (event) => {
            try {
              const data = new Uint8Array(event.target?.result as ArrayBuffer);
              const workbook = XLSX.read(data, { type: 'array' });
              const sheetName = workbook.SheetNames[0];
              const sheet = workbook.Sheets[sheetName];
              const jsonData: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
              resolve(jsonData);
            } catch (error) {
              reject(error);
            }
          };
    
          reader.readAsArrayBuffer(file);
        });
      };

      const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
          try {
            const data = await readExcelFile(file);
            let hasDuplicates = false;
      
            try {
              const token = getLocalStorageVariable<string>('token');
              const headers = {
                Authorization: `${token}`,
              };
      
              // Use a for...of loop to enable the use of await inside an asynchronous function
              for (const row of data.slice(1)) {
                try {
                  const response = await axios.post(
                    `${apiUrl.url}admin_check_account_duplication/`,
                    { phase: row[4], block: row[5], lot: row[6] },
                    { headers }
                  );
      
                  if (response.data.msg) {
                    // Set tableData only if no duplicates are found
                    hasDuplicates = true;
                    break; // Exit the loop since a duplicate is found
                  }
      
                } catch (error) {
                  console.error('Error checking account duplication:', error);
                  return false;
                }
              }
      
            } catch (error) {
              console.error('Error getting token:', error);
            }
      
            // Set tableData only if no duplicates are found
            if (!hasDuplicates) {
              setTableData(data);
            }
      
          } catch (error) {
            console.error('Error reading Excel file:', error);
          }
        }
      };
      

    const handleItemClick = (path: string) => {
        setSessionVariable('setSelectedItem', path);
        navigate(path);
    };

    const handleSearch = (values: { search: string }) => {
        setSearchTerm(values.search);
        setCurrentPage(1);
    };

    

    // *********** handle Submit ************
    const handleSubmit = (values: any) => {
        if (systemData && tableData) {
            tableData.slice(1).forEach((row, rowIndex) => {
    
                const payload = {
                    account: systemData[0]?.user_acct_no + rowIndex,
                    fname: row[1],
                    mi: row[2],
                    lname: row[3],
                    phase: row[4],
                    block: row[5],
                    lot: row[6],
                    lotarea: row[7],
                    floorarea: row[8],
                    dues: row[9],
                };
                dispatch(createRequest(payload));
                // console.log(payload)
            });
        } else {
            console.error("systemData is null");
        }
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ 
                confirm: false,
            }}
            validationSchema={CreateSchema}
            onSubmit={handleSubmit}
            >
        <div className="container-fluid ">
            
            {loading?<LoadingPage/>:""}
            {failed?<CreateUserFailed/>:""}
            {success?<CreateUserSuccess/>:""}
            {user_create != '1'? <AuthAccessFailed />:""}

            <Row className="justify-content-md-center">
                <Col lg="2">
                    <SubMenu/>
                </Col>
                <Col xs lg="10"  className="p-3 bg-white rounded shadow-sm ">
                    <h4 className="text-primary" ><PiUsersThree size="30" /> User Account Excel Template Upload</h4>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => handleItemClick('/admin_user_menu')}>User Menu</Breadcrumb.Item>
                            <Breadcrumb.Item active>Upload Users</Breadcrumb.Item>
                        </Breadcrumb>
                        <hr/>
                    <div style = {{ width:"400px"}} />
                    Create user accounts by uploading the excel file template
                    <Alert style={{border: "1px solid rgb(128, 128, 128)", backgroundColor:'#c9dbff'}} className='mt-2 shadow-lg'>
                        <Alert style={{border: "1px solid rgb(128, 128, 128)"}} className='bg-white'>
                            Download User Template
                            <Row>
                                <Col sm={4} className='p-2 text-center'>
                                    <RiFileExcel2Line size='40' className='text-success' />
                                    &nbsp; User_Template.xlsx
                                </Col>
                                <Col sm={3} className='p-2'>
                                    <div className="d-grid gap-2">
                                        <Button onClick={downloadExcel} className="w-100 btn btn-warning btn-block rounded-pill"><FiDownloadCloud size="20"/> Download Template</Button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={7} className='p-2'>
                                    <input type="file" className="w-100 form-control" accept=".xlsx" onChange={handleFileChange} />
                                </Col>
                                <Col sm={5} className='p-3'> 
                                        Select user template to display in the table.
                                        {/* <Button onClick={handleUpload} className="w-100 btn btn-primary btn-block rounded-pill"><FiUploadCloud size="20"/> Upload Template</Button> */}
                                    
                                </Col>
                            </Row>
                        </Alert>
                        <Form>
                            <Col sm> 
                                <label>
                                    <Field type="checkbox" name="confirm" />
                                    &nbsp;Please confirm to save user account.
                                </label>
                                <ErrorMessage name="confirm">
                                    {msg => <div style={{color:'red',padding:'5px'}}>{msg}</div>}
                                </ErrorMessage>
                            </Col>
                        <br/>
                        <div className="d-grid gap-2">
                            <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-3" >
                                <AiOutlineSend size="20"/> Submit
                            </Button>
                        </div>
                        </Form>
                    </Alert>

                        <hr className="mt-3" />
                        <h4 className="text-primary mt-4" ><AiOutlineBars size="30" /> User account to be created</h4>


                        <Table responsive striped bordered hover size="sm">
                            <thead>
                                <tr className="text-center">
                                    <th>#</th>
                                    <th>Account #</th>
                                    <th>Firstname</th>
                                    <th>MI</th>
                                    <th>Lastname</th>
                                    <th>Phase</th>
                                    <th>Block</th>
                                    <th>Lot</th>
                                    <th>Lot Area</th>
                                    <th>Floor Area</th>
                                    <th>Dues</th>
                                </tr>
                            </thead>
                                <tbody>
                                    {tableData.slice(1).map((row, rowIndex) => (
                                        
                                        <tr key={rowIndex} className="align-middle text-secondary">
                                            <td className="text-center align-middle">{rowIndex + 1}</td>
                                            <td className="text-center align-middle">
                                                {systemData && systemData.length > 0 ? systemData[0]?.user_acct_no + rowIndex : "N/A"}
                                            </td>
                                            <td className="">{row[1]}</td>
                                            <td className="text-center align-middle">{row[2]}</td>
                                            <td className="">{row[3]}</td>
                                            <td className="text-center align-middle">{row[4]}</td>
                                            <td className="text-center align-middle">{row[5]}</td>
                                            <td className="text-center align-middle">{row[6]}</td>
                                            <td className="text-center align-middle">{row[7]}</td>
                                            <td className="text-center align-middle">{row[8]}</td>
                                            <td className="text-center align-middle">{row[9]}</td>
                                        </tr>
                                    
                                    ))}
                                </tbody>
                        </Table>

                        {dataUser?.length && dataUser.length > ITEMS_PER_PAGE && (
                                <Pagination>
                                    <Pagination.First onClick={() => paginate(1)} />
                                    <Pagination.Prev onClick={() => paginate(currentPage - 1)} />

                                    {Array.from({ length: totalPages }).map((_, index) => {
                                        // Show the current page and two pages before and after it
                                        if (index >= currentPage - 2 && index <= currentPage + 2) {
                                        return (
                                            <Pagination.Item
                                            key={index}
                                            active={index + 1 === currentPage}
                                            onClick={() => paginate(index + 1)}
                                            >
                                            {index + 1}
                                            </Pagination.Item>
                                        );
                                        }

                                        return null;
                                    })}

                                    {/* Show ellipsis between Pagination.Prev and Pagination.Next */}
                                    {currentPage + 2 < totalPages && (
                                        <Pagination.Ellipsis key={`ellipsis`} />
                                    )}

                                    <Pagination.Next onClick={() => paginate(currentPage + 1)} />
                                    <Pagination.Last onClick={() => paginate(totalPages)} />
                                </Pagination>
                            )}
                </Col>
            </Row>
        </div>
        </Formik>
    );
}

export default Uplaod_Users;