import React, { useState, useEffect, useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import apiUrl from '../../../components/apiurl';
import { getLocalStorageVariable } from '../../../components/localStorage';
import { 
    Row,
    Col,
    Alert,
    Button,
    Pagination,
} from 'react-bootstrap';
import SubMenu from '../submenu';
// import { AiOutlineDashboard, AiOutlineGroup, AiOutlineCalculator, AiOutlineDollar } from "react-icons/ai";
import { IoWaterOutline } from "react-icons/io5";
import { UserData } from './redux/waterTypes';
import Barcode from 'react-barcode';
// import { useNavigate } from 'react-router-dom';
// import { getLocalStorageVariable } from '../../../components/localStorage';


const Water: React.FC = () =>{
    const userId = getLocalStorageVariable<string>('userId');
    // const userAuth = getLocalStorageVariable<string>('userAuth');
    // const userAvatar = getLocalStorageVariable<string>('avatar');
    const token = getLocalStorageVariable<string>('token');
    const [data, setData] = useState<UserData[] | null>(null); 
    const [page, setPage] = useState(1);
    const [pageSize] = useState(12); // Adjust according to your needs

    // ****** Get the Associaiton Bill ****
    const fetchData = useCallback(async () => {
        const headers = {
            Authorization: `${token}`,
          };

        try {
            const res: AxiosResponse<UserData[]> = await axios.get(`${apiUrl.url}get_water`, { headers, params: { id: userId, page: page, pageSize: pageSize } });
            setData(res.data.length > 0 ? res.data : null);
        } catch (error) {
            console.error('Error: ', error);
        }
    }, [userId, token, page, pageSize]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // console.log(data);

    // const navigate = useNavigate();

    // useEffect(() => {
    //     const userId = getLocalStorageVariable<string>('userId');
    //     const userAuth = getLocalStorageVariable<string>('userAuth');

    //     if (!userId && userAuth!='client') {
    //         navigate('/admin_login'); 
    //     }
    // }, [navigate]);

    return (
        <div className="container-fluid "> 
            <Row className="justify-content-md-center">
                <Col sm={3}>
                    <SubMenu/>
                </Col>
                <Col xs lg="9" className="p-3 bg-white rounded">
                    <Row className="justify-content-md-center">
                        <Col >
                        <div style = {{ width:"400px"}} />
                        <h4 className="text-info" ><IoWaterOutline size="30"/> Water Bill</h4>
                        <hr/>
                            <Row >
                                <Col sm>
                                    {data ? (
                                        <div>
                                            {data.map((r, i) => (
                                                <Col key={i} className="my-3 mt-0 rounded shadow-lg" style={{border: "1px solid rgb(128, 128, 128)", backgroundColor: '#d4fcfc'}}>
                                                    <Row >
                                                        <Col lg={8}>
                                                            <Alert className='mb-0 bg-white' style={{border: "none"}}>
                                                                <Row>
                                                                    <Col sm={7}>
                                                                        <h5><span>Bill # : {r.water_no}</span></h5>
                                                                        <span>Account # : <strong>{r.account}</strong></span><br/>
                                                                        Name : <strong>{r.fname} {r.mi}. {r.lname}</strong><br/>
                                                                        <span >Address : Phase {r.phase} Block {r.block} Lot {r.lot}</span>
                                                                    </Col>
                                                                    <Col sm className='text-center' >
                                                                        <Barcode value={r.water_no.toString()} width={2} height={30} />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm>
                                                                        <div className="media text-muted pt-2">
                                                                            <div className="media-body  small lh-125">
                                                                                <span className="d-block">Date : {new Date(r.cdate).toLocaleDateString("en-US",{ year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                                                    <strong className="text-danger">Due Date : {new Date(new Date(r.cdate).getTime() + 15 * 24 * 60 * 60 * 1000).toLocaleDateString("en-PH")}</strong>
                                                                                </div>
                                                                                
                                                                                {/* <span className="d-block">Date To : {new Date(r.bill_dateto).toLocaleDateString("en-US",{ year: 'numeric', month: 'long', day: 'numeric' })}</span> */}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={5}>
                                                                    Date Coverage
                                                                        <div className="media text-muted">
                                                                            <div className="media-body  small lh-125">
                                                                                <span className="">From : {new Date(r.date_from).toLocaleDateString("en-US",{ year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                                                                <span className=""> - To : {new Date(r.date_to).toLocaleDateString("en-US",{ year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                                                                
                                                                                {/* <span className="d-block">Date To : {new Date(r.bill_dateto).toLocaleDateString("en-US",{ year: 'numeric', month: 'long', day: 'numeric' })}</span> */}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                
                                                            </Alert>
                                                        </Col>
                                                        <Col sm className="p-3 pe-4 ps-4 border-left border-gray justify-content-between text-end">
                                                            <h3>₱ {(r.total_amount).toLocaleString('en-US',{minimumFractionDigits: 2})}</h3>
                                                                <div className="media text-muted pt-1">
                                                                    <div className="media-body mb-2 small lh-125">
                                                                        <div className=" w-100">
                                                                        {r.balance<=0?
                                                                            <strong className="text-success size-5">
                                                                                Balance : ₱ {(r.balance).toLocaleString('en-US',{minimumFractionDigits: 2})}
                                                                            </strong>:
                                                                            <strong className="text-danger size-5">
                                                                                Balance : ₱ {(r.balance).toLocaleString('en-US',{minimumFractionDigits: 2})}
                                                                            </strong>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            <div className="d-grid gap-2">
                                                                {r.balance<=0?(<Button className="btn btn-success rounded-pill" disabled={true}>Paid</Button>):
                                                                (r.balance>=0?<Button  href={`./bills_us_payupload?id=${r.user_id}`} variant="btn btn-danger rounded-pill">Report Payment</Button>:
                                                                <Button  href={`./bills_us_payupload?id=${r.user_id}`} variant="btn btn-warning rounded-pill">For Verification</Button>)}
                                                            </div>
                                                            <div className="d-grid gap-2 mt-1">
                                                                <Button variant="btn btn-outline-primary rounded-pill"> View Details ...</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            ))}
                                        </div>
                                    ) : (
                                        <p>No more Association Due...</p>
                                    )}
                                </Col>
                            </Row>

                            <Pagination className='mt-3'>
                                <Pagination.Prev onClick={()=> setPage((prevPage) => prevPage - 1)} disabled={page === 1} />
                                <Pagination.Item>Page {page}</Pagination.Item>
                                <Pagination.Next onClick={() => setPage((prevPage) => prevPage + 1)} />
                            </Pagination>
                        </Col>
                        
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Water;