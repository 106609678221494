import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Row,
  InputGroup,
  Alert,
  Col
} from 'react-bootstrap';
import { AiOutlineLogin } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { AiOutlineKey } from "react-icons/ai";
import { AiOutlineSend } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { IoKeypadOutline } from "react-icons/io5";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { admin_loginRequest } from './redux/loginActions';
import logo from '../../../images/linao.png';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/reducers';
import { LoadingPage, LoginFailed } from '../../../components/loader';
import { getLocalStorageVariable } from '../../../components/localStorage';
import me from '../../../images/casa1.jpg';

const LoginSchema = Yup.object().shape({
  email: Yup
    .string()
    .email('Invalid email')
    .required('Email is required!'),
  password: Yup
    .string()
    .required('Password is required!'),
  authCode: Yup
    .string()
    .max(6, 'Must be 6 character only')
    .matches(/^[0-9]{6}$/, "Please input 6-digit code from Authenticator.")
    .required('Password is required!'),
});

const Admin_Login: React.FC = () => {
  const redirectPath = useSelector((state: RootState) => state.Admin_LoginReducer.redirectPath);
  const failed = useSelector((state: RootState) => state.Admin_LoginReducer.error);
  const loading = useSelector((state: RootState) => state.Admin_LoginReducer.loading);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const userId = getLocalStorageVariable<string>('userId');
    const userAuth = getLocalStorageVariable<string>('userAuth');
    if (userId && userAuth!='client') {
      navigate('/admin_dashboard'); 
    }

    if (redirectPath) {
      navigate(redirectPath);
      window.location.reload();
    }
  }, [redirectPath, navigate, dispatch]);

  const handleSubmit = (values: any) => {
    const { email, password, authCode } = values;
    dispatch(admin_loginRequest(email, password, authCode));
  };

  return (
    <Formik
      initialValues={{ email: '', password: '', authCode: '' }}
      validationSchema={LoginSchema}
      onSubmit={handleSubmit}
    >
      <Container>
        {loading?<LoadingPage/>:""}
        {failed?<LoginFailed/>:""}
        <Row className="justify-content-md-center">
          <Col sm lg="5" className=" bg-white">
            <img
              src={me}
              alt="Subdivision Map"
              useMap="#housesMap"
              width="100%"
            />
          </Col>
          <Col sm lg="2" className=" bg-white">
            
          </Col>
          <Col sm lg="4" className="my-3 p-3 bg-white rounded shadow">
              <div className="text-info text-center mt-3"> <img src={`${logo}`} alt="" width="180" height="110"/></div>
              <hr/>
              
              <Alert variant="danger">
                  <Alert.Heading><AiOutlineLogin size="25" /> Management Login</Alert.Heading>
              </Alert>
                <Form>
                 
                  <Row>
                      <Col sm>   
                          <InputGroup className="mt-3 mb-1">
                            <InputGroup className="w-25">
                              <InputGroup.Text className="w-100">
                                <div className="text-center w-100"><AiOutlineMail size="25"/></div>
                              </InputGroup.Text>
                            </InputGroup>
                              <Field 
                                type="email" 
                                name="email" 
                                placeholder="E-mail"
                                className="w-75 form-control"
                              />
                            <ErrorMessage name="email">
                              {msg => <div style={{color:'red',padding:'5px', margin:'3px'}}>{msg}</div>}
                            </ErrorMessage>
                          </InputGroup>
                      </Col>
                    </Row>

                    <Row>
                        <Col sm>   
                          <InputGroup className="mt-1 mb-1">
                              <InputGroup className="w-25">
                                <InputGroup.Text className="w-100">
                                    <div className="text-center w-100"><RiLockPasswordLine size="25"/></div>
                                </InputGroup.Text>
                              </InputGroup>
                                <Field 
                                  type="password" 
                                  name="password" 
                                  placeholder="Password"
                                  className="w-75 form-control"
                                />
                              <ErrorMessage name="password">
                                {msg => <div style={{color:'red',padding:'5px', margin:'3px'}}>{msg}</div>}
                              </ErrorMessage>
                          </InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm>   
                          <InputGroup className="mt-1 mb-1">
                              <InputGroup className="w-25">
                                <InputGroup.Text className="w-100">
                                    <div className="text-center w-100"><IoKeypadOutline size="25"/></div>
                                </InputGroup.Text>
                              </InputGroup>
                                <Field 
                                  type="text" 
                                  name="authCode" 
                                  maxLength="6"
                                  placeholder="6-digit Code"
                                  className="w-75 form-control"
                                />
                              <ErrorMessage name="authCode">
                                {msg => <div style={{color:'red',padding:'5px', margin:'3px'}}>{msg}</div>}
                              </ErrorMessage>
                          </InputGroup>
                        </Col>
                    </Row>

                    <br/>
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <a href="/admin_forgot" style={{ textDecoration: 'none' }}>Forgot password?</a>
                    </div>
                    {/* <ReCAPTCHA sitekey="6Le73ZQaAAAAAMiABk5UPq2s-nPc1RCVS0opsrp8" onChange={onChange} /> */}
                    <br/>
                  <div className="d-grid gap-2">
                      <Button variant="primary" type="submit" className="btn btn-primary btn-block rounded-pill mb-5" >
                          <AiOutlineSend size="20"/> Login
                      </Button>
                  </div>
                </Form>
          </Col>
        </Row>
      </Container>
    </Formik>
  );
};

export default Admin_Login;
